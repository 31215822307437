import { useContext, useEffect, useState } from "react";
import State from "../../../../context/user/State";
import { Button, Flex, Table } from "antd";

import StylesContainer from "./styles";

import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helper/general";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";
import BroadridgeLogo from "./images/broadridge_logo.png";
import MemberStar from "./images/MemberStar";

const getColumns = handleChannelChange => [
  {
    dataIndex: "company",
    key: "company",
    render: (text, restValues) => (
      <span
        className="cell-title pointer"
        onClick={() => handleChannelChange(restValues._id)}
      >
        {restValues.isMmiMember && (
          <span style={{ marginRight: 4 }}>
            <MemberStar />
          </span>
        )}
        {text}
      </span>
    ),
    title: "",
    width: 150,
  },
  {
    align: "right",
    dataIndex: "aumQuarterly",
    key: "aumQuarterly",
    render: value => (
      <b className="cell-value">{getCurrencyValueWithRoundSign(value)}</b>
    ),
    title: (
      <div>
        Jun ’24
        <br />
        AUM
      </div>
    ),
    width: 70,
  },
  {
    align: "right",
    dataIndex: "aumAnnually",
    key: "aumAnnually",
    render: value => (
      <b className="cell-value">{getCurrencyValueWithRoundSign(value)}</b>
    ),
    title: (
      <div>
        2023
        <br />
        AUM
      </div>
    ),
    width: 70,
  },
];

const TableLeague = ({ handleChannelChange }) => {
  const [state] = useContext(State);
  const [dataSource, setDataSource] = useState(state.leagues?.slice(0, 3));
  const [isViewAll, setIsViewAll] = useState(false);

  useEffect(() => {
    if (isViewAll) {
      setDataSource(
        state.leagues?.map((it, index) => ({
          ...it,
          key: index,
        }))
      );
    } else {
      setDataSource(
        state.leagues?.slice(3, 6)?.map((it, index) => ({
          ...it,
          key: index,
        }))
      );
    }
  }, [isViewAll, state]);

  const toggleViewAll = () => setIsViewAll(lastState => !lastState);

  return (
    <StylesContainer className={isViewAll && "view-all"} vertical>
      <div className="title">League Table</div>
      <Table
        columns={getColumns(handleChannelChange)}
        dataSource={dataSource}
        loading={state.loadingLeagues}
        pagination={false}
        size="small"
        style={{ marginBottom: 20 }}
      />
      {isViewAll ? (
        <>
          <Flex align="center" vertical>
            <span style={{ fontSize: 12, color: "#FFFFFF" }}>SPONSORED BY</span>
            <img
              alt="Broadridge logo"
              style={{ width: 178 }}
              src={BroadridgeLogo}
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <Button
              onClick={toggleViewAll}
              style={{
                fontSize: 16,
                color: "#4EABE9",
              }}
              type="text"
            >
              VIEW LESS
              <BlueArrow style={{ rotate: "180deg", marginLeft: 8 }} />
            </Button>
            <span className="mmi-member-disclaimer">
              <MemberStar /> MMI Member
            </span>
          </Flex>
        </>
      ) : (
        <Flex align="center" justify="space-between">
          <Button
            onClick={toggleViewAll}
            style={{
              fontSize: 16,
              color: "#4EABE9",
            }}
            type="text"
          >
            VIEW MORE
            <BlueArrow style={{ marginLeft: 8 }} />
          </Button>
          <span className="mmi-member-disclaimer">
            <MemberStar /> MMI Member
          </span>
        </Flex>
      )}
    </StylesContainer>
  );
};

export default TableLeague;
