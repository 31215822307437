import { Button, Flex } from "antd";

import StyledModal from "./styles";

import Close from "../../../../icon/Close";

const ModalReleaseLead = ({ loading, onCancel, onConfirm, open }) => (
  <StyledModal
    closeIcon={<Close />}
    footer={
      <Flex justify="space-between">
        <Button onClick={onCancel} shape="round" type="text">
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          shape="round"
          style={{ background: "#03494E", color: "#fff" }}
        >
          Yes, Release Lead
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
    title="Confirm release"
    width={561}
  >
    <h3 className="title">Do you wish to release this lead?</h3>
    <p className="description">
      The lead will have the opportunity to get their question answered by
      another advisor.
    </p>
  </StyledModal>
);

export default ModalReleaseLead;
