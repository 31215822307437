import styled from "styled-components";

const StylesContainer = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > .cell-proposed-aum,
  .ant-table-wrapper .ant-table-tbody > tr > .cell-proposed-aum {
    background: #6dc1c730;
  }
`;

export default StylesContainer;
