import { useContext } from "react";
import State from "../../../../context/admin/State";
import { Button, Flex } from "antd";
import ModalPrimary from "../ModalPrimary";

import { deleteEvent } from "../../../../requests/global";

const ModalDeleteEvent = ({ open }) => {
  const [state] = useContext(State);

  const handleClose = () => {
    state.closeModal("deleteEventModal");
  };

  const handleDelete = () => {
    state.activeEventId &&
      deleteEvent(state.activeEventId).then(() =>
        state.reFetch.events().then(() => {
          handleClose();
          state.showSuccess("Event deleted");
          state.setKeyValue("activeEventId", null);
        })
      );
  };

  return (
    <ModalPrimary
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round" size="large" type="text">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            shape="round"
            size="large"
            style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
          >
            Delete
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title="Delete Event"
      width={492}
    >
      <span>Are you sure you want to delete this event?</span>
    </ModalPrimary>
  );
};

export default ModalDeleteEvent;
