import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Form } from "antd";
import CreateGoalStepFillData from "./components/CreateGoalStepFillData";
import CreateGoalStepTypes from "./components/CreateGoalStepTypes";
import ModalMonthlyExpenses from "../ModalMonthlyExpenses";

import StyledModal from "./styles";

import content from "./content.json";
import { DEFAULT_RETIREMENT_AGE } from "../../../../utils/constant";

import { getLiveAssessment } from "../../../../utils/request/regularApp";

import Close from "../../../../icon/CloseOutlined";

const defaultGoalProps = {
  advisorFee: "0%",
  contributions: 2400,
  investmentAmount: 100000,
  investmentDuration: 20,
  organization: "goals",
  yearToStartWithdrawals: 1,
  withdrawalLevel: 1,
};

const ModalCreateGoal = ({ onCancel, open }) => {
  const [state] = useContext(State);
  const [currentStep, setCurrentStep] = useState("init");
  const [newGoalData, setNewGoalData] = useState(defaultGoalProps);

  const { title, description } = content[currentStep];

  const handleCancel = () => {
    if (currentStep === "init") {
      onCancel();
    } else {
      setCurrentStep("init");
    }
  };

  const handleGoalTypeSelect = goalType => {
    updateNewGoalData("goalType", goalType);
    updateNewGoalData(
      "investmentDuration",
      goalType === "emergencySavings" ? 0.5 : 20
    );
    setCurrentStep(goalType);
  };

  const handleNewGoalSubmit = () => {
    if (isNewGoalDataValid()) {
      state.setKeyValue("loading", true);

      const isIncomeTypeGoal = newGoalData.goalType === "income";

      const birthYear = newGoalData.birthYear?.$y;

      const currentAge = dayjs().format("YYYY") - birthYear;

      const getGoalName = () => {
        if (newGoalData.goalName) {
          return newGoalData.goalName;
        } else if (newGoalData.goalType === "savings") {
          return "Grow Retirement Savings";
        } else if (newGoalData.goalType === "income") {
          return "Retirement income";
        } else {
          return "Emergency Savings";
        }
      };

      const initialRiskTolerance =
        state.getPreferenceValue("initialRiskTolerance") ?? "Moderate";

      const investmentDuration = isIncomeTypeGoal
        ? currentAge >= 100
          ? 2
          : 100 - currentAge
        : newGoalData.investmentDuration;

      const investmentObjective = isIncomeTypeGoal ? "income" : "growth";

      let productId;

      if (newGoalData.goalType === "emergencySavings") {
        productId = 62;
      } else if (initialRiskTolerance === "Aggressive") {
        productId = 51;
      } else if (initialRiskTolerance === "Conservative") {
        productId = 53;
      } else {
        productId = 52;
      }

      const withdrawalLevel =
        newGoalData.withdrawalLevel && isIncomeTypeGoal
          ? newGoalData.withdrawalLevel + "%"
          : undefined;

      const yearToStartWithdrawals = isIncomeTypeGoal
        ? DEFAULT_RETIREMENT_AGE - currentAge <= 0
          ? 1
          : DEFAULT_RETIREMENT_AGE - currentAge
        : newGoalData.yearToStartWithdrawals;

      getLiveAssessment({
        objective: {
          ...newGoalData,
          birthYear,
          email: state._id,
          goalName: getGoalName(),
          investmentDuration,
          investmentObjective,
          productId,
          riskTolerance: initialRiskTolerance,
          withdrawalLevel,
          yearToStartWithdrawals,
          startDate: new Date().setHours(0, 0, 0, 0),
        },
        organization: "goals",
        productId,
        saveAction: "POST",
      })
        .then(() => {
          setCurrentStep("init");
          setNewGoalData(defaultGoalProps);
          onCancel();

          state.setUserData().then(() => state.setKeyValue("loading", false));
        })
        .catch(error => {
          console.log(error);
          state.setKeyValue("loading", false);
        });
    }
  };

  const isNewGoalDataValid = () => {
    if (
      newGoalData.goalType === "savings" ||
      newGoalData.goalType === "other"
    ) {
      if (!newGoalData.investmentDuration) {
        state.showWarning("Length of investment is required");
        return false;
      }
    }

    if (newGoalData.goalType === "income") {
      if (!newGoalData.birthYear) {
        state.showWarning("Birth year is required");
        return false;
      }
    }

    if (newGoalData.goalType === "savings") {
      if (!newGoalData.targetAmount) {
        state.showWarning("Target amount is required");
        return false;
      }
    }

    if (newGoalData.goalType === "other") {
      if (!newGoalData.goalName) {
        state.showWarning("Name of the goal is required");
        return false;
      }
    }
    return true;
  };

  const updateNewGoalData = (key, value) => {
    setNewGoalData(lastState => ({
      ...lastState,
      [key]: value,
    }));
  };

  const handleMonthlyExpensesModalSave = values => {
    const totalValue = Object.values(values)?.reduce((acc, it) => acc + it, 0);

    updateNewGoalData("monthlyExpenses", totalValue);
    updateNewGoalData("monthlyExpensesItems", values);
    state.closeModal("monthlyExpensesModal");
  };

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      title={<span style={{ fontSize: 22 }}>{title}</span>}
      footer={
        <Flex gap={20} vertical>
          {currentStep !== "init" && (
            <Button
              block
              disabled={!newGoalData.goalType}
              id="createGoalContinueButton"
              onClick={handleNewGoalSubmit}
              shape="round"
              size="large"
              type="primary"
            >
              Continue
            </Button>
          )}
          <Button
            block
            id="createGoalCancelButton"
            onClick={handleCancel}
            shape="round"
            size="large"
            type="text"
          >
            Cancel
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      width={365}
      wrapClassName="create-goal-modal"
    >
      <p>{description}</p>
      <Form layout="vertical">
        {currentStep === "init" ? (
          <CreateGoalStepTypes
            newGoalData={newGoalData}
            updateNewGoalData={updateNewGoalData}
            handleGoalTypeSelect={handleGoalTypeSelect}
          />
        ) : (
          <CreateGoalStepFillData
            newGoalData={newGoalData}
            state={state}
            updateNewGoalData={updateNewGoalData}
          />
        )}
      </Form>

      <ModalMonthlyExpenses
        onCancel={() => state.closeModal("monthlyExpensesModal")}
        onSave={handleMonthlyExpensesModalSave}
        open={state.monthlyExpensesModal}
        values={newGoalData?.monthlyExpensesItems}
      />
    </StyledModal>
  );
};

export default ModalCreateGoal;
