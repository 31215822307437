import { useEffect } from "react";
import { Button, Flex, Form, InputNumber } from "antd";

import StyledModal from "./styles";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../utils/helper/general";

import CloseOutlined from "../../../../icon/CloseOutlined";

const defaultValues = {
  debt: 0,
  foodGrocery: 0,
  housingCost: 0,
  other: 0,
  utilities: 0,
};

const ModalMonthlyExpenses = ({ onCancel, onSave, open, values }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue(values ?? defaultValues);
    }
  }, [open, values]);

  const handleSave = () => {
    const values = form.getFieldsValue();
    onSave(values);
    onCancel();
  };

  return (
    <StyledModal
      closeIcon={<CloseOutlined />}
      footer={
        <Flex gap={4} vertical>
          <Button
            onClick={handleSave}
            shape="round"
            style={{ background: "#E6F4EF" }}
            type="text"
          >
            Use these expenses
          </Button>
          <Button onClick={onCancel} shape="round" type="text">
            Cancel
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="Monthly Expenses"
      width={297}
    >
      <Form form={form} id="monthlyExpensesForm" layout="vertical">
        <Form.Item label="Housing Cost (rent or mortgage)" name="housingCost">
          <InputNumber
            formatter={getCurrencyValue}
            max={10000000}
            min={0}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            step={1000}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Food & Grocery Expenses" name="foodGrocery">
          <InputNumber
            formatter={getCurrencyValue}
            max={10000000}
            min={0}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            step={1000}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Utilities (power, internet, etc)" name="utilities">
          <InputNumber
            formatter={getCurrencyValue}
            max={10000000}
            min={0}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            step={1000}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Debt (Excluding mortgage or car payments)"
          name="debt"
        >
          <InputNumber
            formatter={getCurrencyValue}
            max={10000000}
            min={0}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            step={1000}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item label="Other (Car payments, insurance, etc)" name="other">
          <InputNumber
            formatter={getCurrencyValue}
            max={10000000}
            min={0}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            step={1000}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ModalMonthlyExpenses;
