import PageAdvisorDashboard from "../../manager/page/PageAdvisorDashboard";
import PageAdvisorProspects from "../../manager/page/PageAdvisorProspects";
import PageFinancialProducts from "../../manager/page/PageFinancialProducts";
import PageLevelAdvisorClients from "../../level/pages/PageLevelAdvisorClients";
import PageMarketing from "../../manager/page/PageMarketing";

import BoxOpen from "../../../icon/BoxOpen";
import DocumentPage from "../../../icon/DocumentPage";
import User from "../../../icon/User";
import Users from "../../../icon/Users";

export default [
  {
    path: "/oneascent-advisor-portal/dashboard",
    Component: PageAdvisorDashboard,
    Icon: User,
    text: "Dashboard",
  },
  {
    path: "/oneascent-advisor-portal/prospects",
    Component: PageAdvisorProspects,
    Icon: Users,
    text: "Prospects",
  },
  {
    path: "/oneascent-advisor-portal/clients",
    Component: PageLevelAdvisorClients,
    Icon: Users,
    text: "Clients",
  },
  {
    path: "/oneascent-advisor-portal/products",
    Component: PageFinancialProducts,
    Icon: BoxOpen,
    text: "Products",
  },
  {
    path: "/oneascent-advisor-portal/marketing",
    Component: PageMarketing,
    Icon: DocumentPage,
    text: "Marketing",
  },
];
