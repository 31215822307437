import { useEffect, useState } from "react";
import { Flex, Image, theme } from "antd";
import SectionChangeRisk from "./components/SectionChangeRisk";
import SectionCheckout from "./components/SectionCheckout";
import SectionInit from "./components/SectionInit";
import SectionPortfolioDescription from "./components/SectionPortfolioDescription";
import SectionRecommendedPortfolios from "./components/SectionRecommendedPortfolios";
import SectionSelectAllocation from "./components/SectionSelectAllocation";

import StylesContainer from "./styles";

import { DEFAULT_PROPOSAL_DATA, STEPS_COPY } from "./constants";

import logo from "../../images/logo_light.svg";

const QuestionnaireContentAstor = ({
  open,
  handleClose,
  handleCreateProposal,
}) => {
  const { token } = theme.useToken();
  const [currentStep, setCurrentStep] = useState("init");
  const [proposalData, setProposalData] = useState(DEFAULT_PROPOSAL_DATA);

  useEffect(() => {
    if (open) {
      handleStartOver();
    }
  }, [open]);

  const { description, subTitle, title } = STEPS_COPY[currentStep];

  const handleComplete = values => {
    handleCreateProposal({
      ...proposalData,
      riskTolerance: proposalData.portfolio,
      ...values,
    });
  };

  const handleChangePortfolioContinue = () => {
    let calculatedProductName = "";

    //set recommended product based on the portfolio type
    switch (proposalData.portfolio) {
      case "income":
      case "conservative":
        calculatedProductName = "Active Income";
        break;
      case "moderate":
        calculatedProductName = "Dynamic Allocation";
        break;
      default:
        calculatedProductName = "Sector Allocation";
    }

    updateProposalData("product", calculatedProductName);
    setCurrentStep("portfolioDescription");
  };

  const handleContinueWithPortfolio = () => {
    setCurrentStep("portfolioDescription");
  };

  const handleContinueWithProductOnly = () => {
    updateProposalData("portfolio", "custom");
    setCurrentStep("checkout");
  };

  const handleInitPortfolioClick = portfolio => {
    updateProposalData("portfolio", portfolio);
    setCurrentStep("changeRisk");
  };

  const handleProductSelect = product => {
    updateProposalData("product", product);
    setCurrentStep("recommendedPortfolios");
  };

  const handleStartOver = () => {
    setCurrentStep("init");
    setProposalData(DEFAULT_PROPOSAL_DATA);
  };

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  return (
    <StylesContainer token={token} vertical>
      <header>
        <Flex className="content" align="center" vertical>
          <Flex justify="start" style={{ width: "100%" }}>
            <Image preview={false} src={logo} />
          </Flex>
          {title && <h3 className="title">{title}</h3>}
          {subTitle && <h4 className="subtitle">{subTitle}</h4>}
          {description && <p className="description">{description}</p>}
        </Flex>
      </header>

      {currentStep === "init" && (
        <SectionInit
          handleGoBack={handleClose}
          handleGoForward={() => setCurrentStep("portfolioDescription")}
          handleInitPortfolioClick={handleInitPortfolioClick}
          handleProductSelect={handleProductSelect}
          handleStartOver={handleStartOver}
          proposalData={proposalData}
        />
      )}
      {currentStep === "recommendedPortfolios" && (
        <SectionRecommendedPortfolios
          handleContinueWithPortfolio={handleContinueWithPortfolio}
          handleContinueWithProductOnly={handleContinueWithProductOnly}
          handleGoBack={handleClose}
          handleStartOver={handleStartOver}
          proposalData={proposalData}
          updateProposalData={updateProposalData}
        />
      )}
      {currentStep === "portfolioDescription" && (
        <SectionPortfolioDescription
          handleChangeRisk={() => setCurrentStep("changeRisk")}
          handleGoBack={() => setCurrentStep("init")}
          handleGoForward={() => setCurrentStep("selectAllocation")}
          handleStartOver={handleStartOver}
          proposalData={proposalData}
        />
      )}
      {currentStep === "changeRisk" && (
        <SectionChangeRisk
          handleContinue={handleChangePortfolioContinue}
          proposalData={proposalData}
          updateProposalData={updateProposalData}
        />
      )}
      {currentStep === "selectAllocation" && (
        <SectionSelectAllocation
          handleContinue={() => setCurrentStep("checkout")}
          handleGoBack={() => setCurrentStep("changeRisk")}
          handleStartOver={handleStartOver}
          proposalData={proposalData}
          updateProposalData={updateProposalData}
        />
      )}
      {currentStep === "checkout" && (
        <SectionCheckout
          handleComplete={handleComplete}
          handleGoBack={() => setCurrentStep("selectAllocation")}
          handleStartOver={handleStartOver}
          proposalData={proposalData}
        />
      )}
    </StylesContainer>
  );
};

export default QuestionnaireContentAstor;
