import styled from "styled-components";

import background from "./image/background.svg";

const StylesContainer = styled.div`
  background: url(${background}) right no-repeat;
  background-size: contain;
  font-family: Inter, sans-serif;
  height: 1590px;
  padding-left: 70px;
  width: 1230px;

  header {
    padding-top: 144px;
    margin-bottom: 500px;
  }

  main {
    margin-bottom: 259px;

    h2 {
      color: #333;
      font-size: 60px;
      font-style: normal;
      font-weight: 800;
      margin: 0;
    }

    p {
      color: #2a2a2a;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 0;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding-bottom: 90px;

    h3 {
      color: #2a2a2a;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }

    p {
      color: #2a2a2a;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 0;
    }
  }
`;

export default StylesContainer;
