import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Flex, Image, theme } from "antd";
import QuestionnaireContentMovable from "../QuestionnaireContentMovable";
import StepFinal from "./components/StepFinal";
import StepInit from "./components/StepInit";

import StyledModal from "./styles";

import { PRIORITIES_DEFAULT_STATE } from "./constant";

import logo from "../../image/logo_small_screen_view.svg";
import { ReactComponent as ArrowRight } from "./images/arrow_right.svg";
import Close from "../../../../../../icon/Close";

const ModalPriorities = ({
  handleComplete,
  hideButtonCancel,
  hideButtonClose,
  initialState,
  onCancel,
  open,
  viewResultsMode,
}) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenSM });
  const [prioritiesProps, setPrioritiesProps] = useState();
  const [currentStep, setCurrentStep] = useState("init");

  useEffect(() => {
    if (open) {
      setCurrentStep(viewResultsMode ? "final" : "init");

      setPrioritiesProps(
        initialState?.prioritiesProps ?? PRIORITIES_DEFAULT_STATE
      );
    }
  }, [open]);

  const handleButtonBack = () => {
    switch (currentStep) {
      case "step1":
        setCurrentStep("init");
        break;
      case "step2":
        setCurrentStep("step1");
        break;
      case "step3":
        setCurrentStep("step2");
        break;
      case "step4":
        setCurrentStep("step3");
        break;
      case "final":
        setCurrentStep("step4");
        break;
    }
  };

  const handleButtonComplete = pickedValues => {
    handleComplete(pickedValues);
    onCancel && onCancel();
    setCurrentStep("init");
    setPrioritiesProps(PRIORITIES_DEFAULT_STATE);
  };

  const handleButtonContinue = () => {
    switch (currentStep) {
      case "step1":
        setCurrentStep("step2");
        break;
      case "step2":
        setCurrentStep("step3");
        break;
      case "step3":
        setCurrentStep("step4");
        break;
      case "step4":
        setCurrentStep("final");
        break;
    }
  };

  const reorderValue = ({ newIndex, value }) => {
    setPrioritiesProps(lastState => ({
      ...lastState,
      [currentStep]: {
        ...lastState[currentStep],
        customOrder: true,
        values: lastState[currentStep].values
          .filter(it => it.label !== value.label)
          .toSpliced(newIndex, 0, value),
      },
    }));
  };

  return (
    <StyledModal
      closable={!hideButtonClose && !isSmallScreen}
      closeIcon={<Close />}
      footer={false}
      maskClosable={false}
      onCancel={onCancel}
      open={open}
      title={
        <Flex vertical>
          {isSmallScreen && (
            <Flex className="mobile-logo-container" justify="center">
              <Image src={logo} preview={false} />
            </Flex>
          )}
          Priorities
        </Flex>
      }
      width={currentStep === "final" ? 743 : 661}
    >
      {currentStep === "init" ? (
        <StepInit
          Footer={() => (
            <Flex justify="space-between" style={{ width: "100%" }}>
              <div>
                {!hideButtonCancel && (
                  <Button onClick={onCancel} shape="round" size="large">
                    Cancel
                  </Button>
                )}
              </div>
              <Button
                onClick={() => setCurrentStep("step1")}
                shape="round"
                size="large"
                style={{ background: "#428916", color: "#fff" }}
              >
                <Flex align="center" gap={8}>
                  <span>Get Started</span>
                  <ArrowRight />
                </Flex>
              </Button>
            </Flex>
          )}
        />
      ) : currentStep === "final" ? (
        <StepFinal
          handleComplete={handleButtonComplete}
          initialState={initialState}
          prioritiesProps={prioritiesProps}
        />
      ) : (
        <QuestionnaireContentMovable
          Footer={() => (
            <Flex justify="space-between" style={{ width: "100%" }}>
              <Button onClick={handleButtonBack} shape="round" size="large">
                Back
              </Button>
              <Button
                onClick={handleButtonContinue}
                shape="round"
                size="large"
                style={{ background: "#428916", color: "#fff" }}
              >
                <Flex align="center" gap={8}>
                  <span>Continue</span>
                  <ArrowRight />
                </Flex>
              </Button>
            </Flex>
          )}
          props={prioritiesProps[currentStep]}
          reorderValue={reorderValue}
          totalSteps={prioritiesProps.totalSteps}
        />
      )}
    </StyledModal>
  );
};

export default ModalPriorities;
