import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Flex, theme } from "antd";

import { StyledMenu } from "./styles";

const MenuSidebar = ({ itemsLayoutVertical, routes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const getMenuItems = () =>
    routes?.map(({ children, Icon, path, text, disabled }) => ({
      children,
      disabled,
      key: path,
      label: itemsLayoutVertical ? (
        <Flex
          align="center"
          gap={4}
          justify="center"
          style={{ width: "100%" }}
          vertical
        >
          {Icon && <Icon />}
          {text}
        </Flex>
      ) : (
        <Flex align="center" gap={14} justify="start">
          {Icon && (
            <div style={{ textAlign: "center", width: 24 }}>
              <Icon />
            </div>
          )}
          {text}
        </Flex>
      ),
    })) ?? [];

  const handleItemClick = event => {
    navigate(event.key);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemActiveBg: token.sidebar_menu_item_hover_background,
            itemHoverBg: token.sidebar_menu_item_hover_background,
          },
        },
      }}
    >
      <StyledMenu
        items={getMenuItems()}
        mode="inline"
        onClick={handleItemClick}
        selectedKeys={[location.pathname]}
        token={token}
      />
    </ConfigProvider>
  );
};

export default MenuSidebar;
