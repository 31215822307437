import { Button, Flex } from "antd";
import CardChartPie from "../CardChartPie";

import StylesContainer from "./styles";

import { PORTFOLIO_LIST, PRODUCT_LIST } from "../../constants";

const SectionPortfolioDescription = ({
  handleChangeRisk,
  handleGoBack,
  handleGoForward,
  handleStartOver,
  proposalData,
}) => {
  const selectedPortfolioData = PORTFOLIO_LIST.find(
    it => it.value === proposalData.portfolio
  );

  return (
    <StylesContainer align="start" gap={184} justify="center">
      <Flex gap={21} vertical>
        <h3 className="subtitle">Selected Portfolio</h3>
        <Flex align="center" gap={24} vertical>
          <CardChartPie
            readonly
            series={selectedPortfolioData.series}
            title={selectedPortfolioData.label}
          />
          <Button
            onClick={handleChangeRisk}
            shape="round"
            style={{ background: "#1971B9", color: "#fff", width: 90 }}
            type="primary"
          >
            Change
          </Button>
        </Flex>
      </Flex>
      <Flex style={{ width: 560 }} vertical>
        <h3 className="subtitle" style={{ marginBottom: 21 }}>
          Recommended Astor Product
        </h3>
        <Flex gap={31} justify="center" wrap="wrap">
          {PRODUCT_LIST.map(it => (
            <CardChartPie
              active={proposalData.product === it.label}
              inactive={proposalData.product !== it.label}
              key={it.productId}
              readonly
              series={[{ color: it.color, y: 100 }]}
              title={it.label}
            />
          ))}
        </Flex>
        <h3 className="subtitle" style={{ margin: "34px 0 13px" }}>
          {proposalData.product}
        </h3>
        <b
          dangerouslySetInnerHTML={{
            __html: PRODUCT_LIST.find(it => it.label === proposalData.product)
              ?.description,
          }}
          style={{ color: "#1E1919", fontFamily: "Open Sans, sans-serif" }}
        />
        <Flex
          align="center"
          gap={12}
          style={{ marginTop: 24, width: 292 }}
          vertical
        >
          <Button
            block
            onClick={handleGoForward}
            shape="round"
            style={{ background: "#1971B9", color: "#fff" }}
            type="primary"
          >
            Next, choose allocation
          </Button>
          <Flex align="center" justify="center">
            <Button
              onClick={handleGoBack}
              shape="round"
              size="small"
              style={{ color: "#32A2DE" }}
              type="text"
            >
              Go back
            </Button>
            <div
              style={{
                background: "#32a2de",
                height: 20,
                width: 1,
              }}
            />
            <Button
              onClick={handleStartOver}
              shape="round"
              size="small"
              style={{ color: "#32A2DE" }}
              type="text"
            >
              Start over
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </StylesContainer>
  );
};

export default SectionPortfolioDescription;
