import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Input, Row, Space, theme } from "antd";
import FeaturedVideoModalToggler from "./components/FeaturedVideoModalToggler";
import IconContainer from "../../../../atom/IconContainer";
import MaterialsTableView from "./components/MaterialsTableView";
import MaterialsTilesView from "./components/MaterialsTilesView";
import ModalAddFolder from "./components/ModalAddFolder";
import ModalDeleteFile from "./components/ModalDeleteFile";
import ModalDeleteFolder from "./components/ModalDeleteFolder";
import ModalFeaturedContentOptions from "./components/ModalFeaturedContentOptions";
import ModalFeaturedVideo from "./components/ModalFeaturedVideo";
import ModalRenameFile from "./components/ModalRenameFile";
import ModalRenameFolder from "./components/ModalRenameFolder";
import ModalUploadFile from "./components/ModalUploadFile";
import SecurityGate from "../../../../provider/SecurityGate";

import StylesContainer from "./styles";

import {
  isOrganizationAstor,
  isPortalAdmin,
} from "../../../../utils/helper/specialized";
import {
  addFolder,
  addSubFolder,
  deleteFile,
  deleteFolder,
  renameFile,
  renameFolder,
  updateFeatureContent,
  updateOrgVideoLink,
  uploadFile,
} from "../../../../utils/request/manager";

import {
  FolderOpenOutlined,
  LeftOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableView from "../../../../icon/TableView";
import TilesView from "../../../../icon/TilesView";

const PageMarketing = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [viewType, setViewType] = useState("tiles");
  const [searchText, setSearchText] = useState("");

  const getMaterialsLastUpdateDate = () =>
    state.marketingMaterialFolders
      ?.map(it => [
        it.createDateTime.$date,
        it.marketingMaterials?.map(file => file.createDateTime.$date) ?? [],
      ])
      ?.flat(2)
      ?.sort((a, b) => dayjs(a) - dayjs(b))
      ?.at(-1);

  const handleAddFolder = ({ newFolderName }) => {
    state.setKeyValue("loading", true);
    state.closeModal("openModalAddFolder");

    if (state.parentFolderId) {
      addSubFolder({
        parentFolderId: state.parentFolderId,
        folderName: newFolderName,
      })
        .then(response => {
          state.showSuccess(response.data);

          state.setAdminData().then(() => {
            state.setKeyValue("parentFolderId", null);
          });
        })
        .catch(error => {
          console.log(error);
          state.setKeyValue("loading", false);
        });
    } else {
      addFolder(newFolderName)
        .then(response => {
          state.showSuccess(response.data);
          state.setAdminData();
        })
        .catch(error => {
          console.log(error);
          state.setKeyValue("loading", false);
        });
    }
  };

  const handleBack = () => {
    state.setKeyValue("activeFolderData", null);
    setSearchText("");
  };

  const handleDeleteFile = () => {
    state.setKeyValue("loading", true);
    state.closeModal("openModalDeleteFile");

    deleteFile({
      folderId: state.activeFileData.folderData?.id,
      id: state.activeFileData.id,
    })
      .then(response => {
        state.showSuccess(response.data);
        state.setAdminData();
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleDeleteFolder = () => {
    state.setKeyValue("loading", true);
    state.closeModal("openModalDeleteFolder");

    deleteFolder({ folderId: state.activeFolderData.id })
      .then(response => {
        state.showSuccess(response.data);
        state.setAdminData();
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleRenameFile = ({ newFileName }) => {
    if (newFileName.includes("±")) {
      state.showWarning(
        "“±” signs cannot be used when saving a marketing document"
      );
      return;
    }

    state.setKeyValue("loading", true);
    state.closeModal("openModalRenameFile");

    renameFile({
      folderId: state.activeFileData.folderData.id,
      id: state.activeFileData.id,
      newFileName,
    })
      .then(({ data }) => {
        state.showSuccess(data);
        state.setAdminData();
      })
      .catch(console.log);
  };

  const handleRenameFolder = ({ newFolderName }) => {
    state.setKeyValue("loading", true);
    state.closeModal("openModalRenameFolder");

    renameFolder({
      newFolderName,
      oldFolderId: state.activeFolderData.id,
    })
      .then(({ data }) => {
        state.showSuccess(data);
        state.setAdminData();
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleSearch = value => {
    setViewType("files");
    setSearchText(value);
  };

  const handleUpdateFeaturedContent = requestBody => {
    if (!requestBody.headline) {
      state.showWarning("Please, provide the Headline");
      return;
    } else if (!requestBody.description) {
      state.showWarning("Please, provide the Short Description");
      return;
    }

    state.setKeyValue("loading", true);

    updateFeatureContent(requestBody)
      .then(() => {
        state.closeModal("openModalFeaturedContentOptions");
        state.showSuccess("Featured Content Options updated");
        state.setAdminData();
      })
      .catch(data => console.log(data));
  };

  const handleUploadFile = fileData => {
    if (
      state.marketingMaterialFolders
        ?.find(it => it.name === fileData.folderName)
        ?.marketingMaterials?.find(it => it.name === fileData.fileName)
    ) {
      state.showWarning(
        "You cannot upload a file into a folder where the file already exists."
      );
      return;
    } else if (fileData.fileName.includes("±")) {
      state.showWarning(
        "“±” signs cannot be used when saving a marketing document"
      );
      return;
    }

    state.setKeyValue("loading", true);
    state.closeModal("openModalUploadFile");

    const formData = new FormData();
    formData.append("fileName", fileData.fileName);
    formData.append("folderId", fileData.folderId);
    formData.append("isVisibleToAdvisor", true);
    formData.append("file", fileData.file.fileList[0].originFileObj);

    uploadFile(formData)
      .then(({ data }) => {
        state.showSuccess(data);
        state.setAdminData();
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleVideoLinkUpdate = ({ link }) => {
    if (link.includes("https://www.") || link.includes("http://www.")) {
      state.setKeyValue("loading", true);

      updateOrgVideoLink(link)
        .then(() => {
          state.setAdminData().then(() => {
            state.showSuccess("Featured Video link updated.");
            state.closeModal("openModalFeaturedVideo");
          });
        })
        .catch(console.log);
    } else {
      state.showError("Please, add valid link on the video.");
    }
  };

  return (
    <SecurityGate state={state}>
      <StylesContainer>
        <Row
          align="bottom"
          justify="space-between"
          style={{ marginBottom: 40 }}
        >
          <Space direction="vertical">
            <h4 style={{ fontSize: 22, color: "#131523", margin: 0 }}>
              Marketing Materials
            </h4>
            <span>
              {isPortalAdmin()
                ? "Use this area to upload materials for your advisor to use with their clients."
                : `Information and Materials on ${state.organization?.name} Products.`}
            </span>
          </Space>
          {isPortalAdmin() && <FeaturedVideoModalToggler />}
        </Row>
        <Flex
          justify="space-between"
          style={{
            paddingBottom: 8,
            borderBottom: `1px solid ${token.color_grey_3}`,
            marginBottom: 24,
          }}
        >
          <Flex align="center" gap={13}>
            <Input
              value={searchText}
              placeholder="Search..."
              style={{
                height: 40,
                width: 228,
                borderRadius: 25,
              }}
              allowClear
              onChange={e => handleSearch(e.target.value)}
            />
            {((state.activeFolderData?.name && viewType === "tiles") ||
              viewType === "files" ||
              searchText) && (
              <>
                <Button
                  type="text"
                  size="small"
                  shape="round"
                  icon={<LeftOutlined />}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <FolderOpenOutlined style={{ fontSize: 26 }} />
              </>
            )}
          </Flex>
          <Space size={30}>
            {isPortalAdmin() && (
              <Space size={10}>
                <Button
                  type="primary"
                  size="small"
                  shape="round"
                  icon={<PlusOutlined />}
                  onClick={() => state.openModal("openModalAddFolder")}
                  disabled={state.loading}
                  style={{
                    background: token.color_tertiary_button,
                    color: token.color_tertiary_button_text,
                  }}
                >
                  New Folder
                </Button>
                <Button
                  type="secondary"
                  size="small"
                  shape="round"
                  icon={<UploadOutlined />}
                  onClick={() => state.openModal("openModalUploadFile")}
                  disabled={state.loading}
                  style={{
                    width: 118,
                    background: token.color_secondary_button,
                    color: token.color_secondary_button_text,
                  }}
                >
                  Upload
                </Button>
              </Space>
            )}
            {!isOrganizationAstor(state.organization?.name) && (
              <Space size={8}>
                <IconContainer
                  size={32}
                  onClick={() => setViewType("table")}
                  pointer
                >
                  <TableView
                    color={viewType === "table" ? "#354A5F" : "#8396A6"}
                  />
                </IconContainer>
                <IconContainer
                  size={32}
                  onClick={() => setViewType("tiles")}
                  pointer
                >
                  <TilesView
                    color={viewType !== "table" ? "#354A5F" : "#8396A6"}
                  />
                </IconContainer>
                <span style={{ fontSize: 12, color: token.color_grey_1 }}>
                  Last Updated:{" "}
                  {dayjs(getMaterialsLastUpdateDate()).format("MM/DD/YYYY")}
                </span>
              </Space>
            )}
          </Space>
        </Flex>
        {viewType === "table" ? (
          <MaterialsTableView />
        ) : (
          <MaterialsTilesView searchText={searchText} />
        )}

        <ModalAddFolder
          onCancel={() => state.closeModal("openModalAddFolder")}
          open={state.openModalAddFolder}
          onSuccess={handleAddFolder}
        />
        <ModalDeleteFile
          activeFileData={state.activeFileData}
          onCancel={() => state.closeModal("openModalDeleteFile")}
          open={state.openModalDeleteFile}
          onSuccess={handleDeleteFile}
        />
        <ModalDeleteFolder
          activeFolderName={state.activeFolderData?.name}
          onSuccess={handleDeleteFolder}
          onCancel={() => state.closeModal("openModalDeleteFolder")}
          open={state.openModalDeleteFolder}
        />
        <ModalFeaturedContentOptions
          activeFileData={
            state.marketingMaterialFolders
              ? {
                  ...state.activeFileData,
                  ...state.marketingMaterialFolders
                    .map(it => it.marketingMaterials)
                    .flat()
                    .find(it => it?.id === state.activeFileData?.id),
                }
              : state.activeFileData
          }
          foldersList={state.marketingMaterialFolders}
          handleUpdateFeaturedContent={handleUpdateFeaturedContent}
          loading={state.loading}
          onClose={() => state.closeModal("openModalFeaturedContentOptions")}
          open={state.openModalFeaturedContentOptions}
          organization={state.organization?.name}
        />
        <ModalFeaturedVideo
          link={state.video}
          loading={state.loading}
          onCancel={() => state.closeModal("openModalFeaturedVideo")}
          onSuccess={handleVideoLinkUpdate}
          open={state.openModalFeaturedVideo}
        />
        <ModalRenameFile
          activeFileData={state.activeFileData}
          onCancel={() => state.closeModal("openModalRenameFile")}
          onSuccess={handleRenameFile}
          open={state.openModalRenameFile}
        />
        <ModalRenameFolder
          activeFolderName={state.activeFolderData?.name}
          onCancel={() => state.closeModal("openModalRenameFolder")}
          onSuccess={handleRenameFolder}
          open={state.openModalRenameFolder}
        />
        <ModalUploadFile
          marketingMaterialFolders={state.marketingMaterialFolders}
          onCancel={() => state.closeModal("openModalUploadFile")}
          onSuccess={handleUploadFile}
          open={state.openModalUploadFile}
        />
      </StylesContainer>
    </SecurityGate>
  );
};

export default PageMarketing;
