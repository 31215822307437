import { useMediaQuery } from "react-responsive";
import { Form, InputNumber, Select, Space } from "antd";

import { StyledInput } from "./styles";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../../../../../utils/helper/specialized";

import ColumnGolden from "../RiskToleranceSection/images/column_golden.svg";
import ColumnGreen from "../RiskToleranceSection/images/column_green.svg";

const YourInvestmentSection = ({ proposalData, updateProposalData }) => {
  const isLargeHeight = useMediaQuery({ minHeight: 850 });
  const isLargeWidth = useMediaQuery({ minWidth: 1650 });

  return (
    <>
      <h3 className="title">Your Investment</h3>
      <p
        className="description"
        style={{
          position: "relative",
        }}
      >
        Certain personal information must be gathered in order to engage in
        meaningful financial planning conversations. The information below will
        serve as the foundation to evaluate your goals and design your financial
        plan. The exact details of your financial situation can easily be
        updated and modified to reflect the most current and accurate
        information as your personal situation changes. Every plan starts with a
        baseline understanding of your previous investment experience, risk
        tolerance, goals and objectives.
      </p>
      <Space direction="vertical" size={32} style={{ width: "100%" }}>
        <StyledInput label="Initial Investment" labelCol={{ span: 24 }}>
          <InputNumber
            min={0}
            step={1000}
            value={proposalData.investmentAmount}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            style={{ width: "100%" }}
            onChange={value => updateProposalData("investmentAmount", value)}
            onClick={selectValue}
          />
        </StyledInput>
        <StyledInput label="Monthly Contributions" labelCol={{ span: 24 }}>
          <InputNumber
            min={0}
            step={1000}
            value={proposalData.contributions}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            style={{ width: "100%" }}
            onChange={value => updateProposalData("contributions", value)}
            onClick={selectValue}
          />
        </StyledInput>
        <StyledInput label="Length of Investment" labelCol={{ span: 24 }}>
          <InputNumber
            addonAfter={
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  options={[
                    {
                      label: "Years",
                      value: "years",
                    },
                    {
                      disabled: true,
                      label: "Quarters",
                      value: "quarters",
                    },
                    {
                      disabled: true,
                      label: "Months",
                      value: "months",
                    },
                  ]}
                  style={{ width: 120 }}
                  value="years"
                />
              </Form.Item>
            }
            value={proposalData.investmentDuration}
            min={0}
            max={80}
            style={{ width: "100%" }}
            onChange={value => updateProposalData("investmentDuration", value)}
            onClick={selectValue}
          />
        </StyledInput>
        {!isGrowthTypeRiskTolerance(proposalData.investmentObjective) && (
          <StyledInput label={`Income in Retirement (annually)`}>
            <InputNumber
              formatter={value => `${value}%`}
              max={100}
              min={1}
              onChange={value => updateProposalData("withdrawalLevel", value)}
              onClick={selectValue}
              parser={value => value.replace("%", "")}
              style={{ width: "100%" }}
              value={proposalData.withdrawalLevel}
            />
          </StyledInput>
        )}
      </Space>
      {isLargeHeight && isLargeWidth && (
        <>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 270,
              height: "25vh",
              width: 395,
              background: `url(${ColumnGolden}) no-repeat`,
              transition: "all 500ms",
              zIndex: 1,
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 105,
              height: "60vh",
              width: 395,
              background: `url(${ColumnGreen}) no-repeat`,
              transition: "all 500ms",
              zIndex: 2,
            }}
          />
        </>
      )}
    </>
  );
};

export default YourInvestmentSection;
