import { useContext } from "react";
import State from "../../../../../../context";
import { Flex, Tabs } from "antd";
import TabBlindSpots from "./components/TabBlindSpots";
import TabCommonGround from "./components/TabCommonGround";
import TabFourUsesMoney from "../SectionPerspectiveIndividualResults/components/TabFourUsesMoney";
import TabOverview from "./components/TabOverview";
import TabUniqueTendencies from "./components/TabUniqueTendencies";

import StylesContainer from "./styles";

export const getCoupleCalculations = ({ perspectiveMy, perspectiveSpouse }) => {
  const getScore = points => Math.round((points / 25) * 100);

  const diff = (a, b) => (a > b ? a - b : b - a);

  return [
    {
      type: "spending",
      difference: diff(
        getScore(perspectiveMy?.calculation?.spendingPoints),
        getScore(perspectiveSpouse?.calculation?.spendingPoints)
      ),
      total:
        getScore(perspectiveMy?.calculation?.spendingPoints) +
        getScore(perspectiveSpouse?.calculation?.spendingPoints),
    },
    {
      type: "giving",
      difference: diff(
        getScore(perspectiveMy?.calculation?.givingPoints),
        getScore(perspectiveSpouse?.calculation?.givingPoints)
      ),
      total:
        getScore(perspectiveMy?.calculation?.givingPoints) +
        getScore(perspectiveSpouse?.calculation?.givingPoints),
    },
    {
      type: "saving",
      difference: diff(
        getScore(perspectiveMy?.calculation?.savingPoints),
        getScore(perspectiveSpouse?.calculation?.savingPoints)
      ),
      total:
        getScore(perspectiveMy?.calculation?.savingPoints) +
        getScore(perspectiveSpouse?.calculation?.savingPoints),
    },
    {
      type: "investing",
      difference: diff(
        getScore(perspectiveMy?.calculation?.investingPoints),
        getScore(perspectiveSpouse?.calculation?.investingPoints)
      ),
      total:
        getScore(perspectiveMy?.calculation?.investingPoints) +
        getScore(perspectiveSpouse?.calculation?.investingPoints),
    },
  ];
};

const SectionPerspectiveCoupleResults = () => {
  const [state] = useContext(State);

  const coupleCalculations = getCoupleCalculations({
    perspectiveMy: state.getPreferenceValue("perspectiveMy"),
    perspectiveSpouse: state.getPreferenceValue("perspectiveSpouse"),
  });

  const tabs = [
    {
      label: "The Four Uses of Money",
      key: "fourUsesMoney",
      children: <TabFourUsesMoney />,
    },
    {
      label: "Overview",
      key: "overview",
      children: <TabOverview />,
    },
    {
      label: "Common Ground",
      key: "commonGround",
      children: (
        <TabCommonGround
          coupleCalculations={coupleCalculations}
          partnerA={state.personalInfo?.firstName}
          partnerB={
            state.getPreferenceValue("guideInitialData")?.spouseFirstName
          }
        />
      ),
    },
    {
      label: "Blind Spots",
      key: "blindSpots",
      children: (
        <TabBlindSpots
          coupleCalculations={coupleCalculations}
          partnerA={state.personalInfo?.firstName}
          partnerB={
            state.getPreferenceValue("guideInitialData")?.spouseFirstName
          }
        />
      ),
    },
    {
      label: "Unique Tendencies",
      key: "uniqueTendencies",
      children: (
        <TabUniqueTendencies
          coupleCalculations={coupleCalculations}
          partnerA={state.personalInfo?.firstName}
          partnerB={
            state.getPreferenceValue("guideInitialData")?.spouseFirstName
          }
        />
      ),
    },
  ];

  return (
    <StylesContainer gap={16} vertical>
      <Flex className="header" vertical>
        <span className="label">Planning Perspective</span>
        <h3 className="title" style={{ textTransform: "capitalize" }}>
          {state.personalInfo?.firstName} &{" "}
          {state.getPreferenceValue("guideInitialData")?.spouseFirstName ??
            "--"}{" "}
          {state.personalInfo?.lastName}
        </h3>
      </Flex>

      <Tabs items={tabs} tabBarGutter={8} tabPosition="left" type="card" />
    </StylesContainer>
  );
};

export default SectionPerspectiveCoupleResults;
