import { useContext } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Row, Space, theme } from "antd";
import AnalyticValue from "../../../../atom/AnalyticValue";
import ButtonDemoMode from "../../../../atom/ButtonDemoMode";
import SecurityGate from "../../../../provider/SecurityGate";
import TableLevelClients from "./components/TableLevelClients";

import { DEFAULT_RETIREMENT_AGE } from "../../../../utils/constant";
import { tableItemTypes } from "../../components/ModalClientDetails/constants";

import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../utils/helper/general";
import { getManagedUsers } from "../../../../utils/helper/specialized";
import PlusOutlined from "../../../../icon/PlusOutlined";
import ModalClientDetails from "../../components/ModalClientDetails";
import {
  addManagedUser,
  addManagedUserExpanded,
  updateManagedUser,
} from "../../../../utils/request/manager";

const PageNextLevelClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const organization = state?.orgName ?? state?.organization?.name;

  const getTotalAum = () =>
    getManagedUsers(state).length
      ? getManagedUsers(state).reduce(
          (acc, it) =>
            it.valueMap?.productMap
              ? acc +
                Object.keys(it.valueMap.productMap)
                  .map(
                    key =>
                      it.valueMap.productMap[key][
                        it.valueMap.productMap[key].length - 1
                      ].investmentAmount
                  )
                  .reduce((acc, value) => acc + value, 0)
              : acc + (it.proposedAmount ?? 0),
          0
        )
      : 0;

  const onManagedUserFormSubmit = formValues => {
    if (formValues.planningType === "Cashflow") {
      if (
        formValues.yearBorn &&
        formValues.startDate &&
        formValues.yearBorn > dayjs(formValues.startDate.$d).format("YYYY")
      ) {
        state.showWarning("Birth Year could not be bigger, than Start Date.");
        return;
      } else if (
        formValues.planType === "basic" &&
        formValues.savingsPerMonth > formValues.annualIncome / 12
      ) {
        state.showWarning(
          `You need to increase Annual Income value to save ${getCurrencyValue(formValues.savingsPerMonth)} monthly`
        );
        return;
      }
    }

    state.setKeyValue("loading", true);

    if (formValues.oldEmail) {
      // update data of existed user
      updateManagedUser(formValues)
        .then(() => {
          state.setAdminData().then(() => {
            state.setKeyValue("openModalClientDetails", false);
            state.showSuccess("Client data updated");
          });
        })
        .catch(console.log);
    } else {
      // create new user
      if (!formValues.planType || formValues.planType === "basic") {
        const requestBody = {
          doNotNotifyManagedUser: true,
          maritalStatus: "single",
          retirementAge: DEFAULT_RETIREMENT_AGE,
          scenarioName: "Scenario 1",
          startDate: formValues.startDate?.$d ?? new Date(),
          planningType: "Goals",
          ...formValues,
        };

        addManagedUser(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            state.setKeyValue("openModalClientDetails", false);

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      } else {
        const requestBody = {
          doNotNotifyManagedUser: true,
          retirementAge: DEFAULT_RETIREMENT_AGE,
          scenarioName: "Scenario 1",
          ...formValues,
          startDate: formValues.startDate?.$d ?? new Date(),
          accounts: JSON.stringify(
            formValues.accounts.map(accountData => ({
              account_name: accountData.accountType,
              account_type: tableItemTypes.find(
                it => it.title === accountData.accountType
              ).type,
              starting_balance: accountData.startingBalance,
              growth: accountData.growth,
              start_age: accountData.startAge,
              end_age: accountData.endAge,
            }))
          ),
          incomes: JSON.stringify(
            formValues.incomes.map(incomeData => ({
              income_name: incomeData.incomeType,
              income_type: tableItemTypes.find(
                it => it.title === incomeData.incomeType
              ).type,
              annual_amount: incomeData.annualAmount,
              start_age: incomeData.startAge,
              end_age: incomeData.endAge,
            }))
          ),
          expenses: JSON.stringify(
            formValues.expenses.map(expenseData => ({
              expense_name: expenseData.expenseType,
              expense_type: tableItemTypes.find(
                it => it.title === expenseData.expenseType
              ).type,
              amount: expenseData.amount,
              frequency: expenseData.frequency,
              start_age: expenseData.startAge,
              end_age: expenseData.endAge,
            }))
          ),
        };

        addManagedUserExpanded(requestBody)
          .then(response => {
            state.showSuccess(response.data);
            state.setKeyValue("openModalClientDetails", false);

            state.setAdminData(() => {
              state.setKeyValue("loading", false);
            });
          })
          .catch(error => {
            state.showWarning(error.response?.data || error.message);
            state.setKeyValue("loading", false);
          });
      }
    }
  };

  return (
    <SecurityGate state={state}>
      <Row justify="space-between">
        <Space size={100} align="start" style={{ marginBottom: 20 }}>
          <AnalyticValue
            label="Total Clients"
            value={getManagedUsers(state).length}
          />
          <AnalyticValue
            label="Total Proposed AUM"
            value={getCurrencyValueWithRoundSign(getTotalAum())}
          />
        </Space>
        <Space size={14}>
          <ButtonDemoMode
            organization={organization}
            userManagerEmail={state._id}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              state.setKeyValue("activeEmail", null);
              state.setKeyValue("openModalClientDetails", true);
            }}
            shape="round"
            size="small"
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_add_user_background,
            }}
            type="primary"
          >
            Add New Client
          </Button>
        </Space>
      </Row>
      <TableLevelClients />

      <ModalClientDetails
        activeUserData={state.managedUsers?.find(
          it => it.email === state.activeEmail
        )}
        loading={state.loading}
        managerAccess={state.access}
        onCancel={() => state.setKeyValue("openModalClientDetails", false)}
        onFinish={onManagedUserFormSubmit}
        open={state.openModalClientDetails}
      />
    </SecurityGate>
  );
};

export default PageNextLevelClients;
