import { Button, Flex, Image } from "antd";
import CardChartPie from "../CardChartPie";

import StylesContainer from "./styles";

import { PORTFOLIO_LIST, PRODUCT_LIST } from "../../constants";

import risk_line from "../../images/risk_line.svg";

const SectionInit = ({
  handleGoBack,
  handleGoForward,
  handleInitPortfolioClick,
  handleProductSelect,
  handleStartOver,
  proposalData,
}) => (
  <StylesContainer align="center" vertical>
    <h3 className="title">Recommended for these portfolios</h3>
    <Flex gap={31} justify="center" wrap="wrap">
      {PORTFOLIO_LIST.map(it => {
        return (
          <CardChartPie
            active={
              proposalData.portfolio !== undefined &&
              proposalData.portfolio === it.value
            }
            inactive={
              proposalData.portfolio !== undefined &&
              proposalData.portfolio !== it.label
            }
            key={it.value}
            onClick={() => handleInitPortfolioClick(it.value)}
            series={it.series}
            title={it.label}
          />
        );
      })}
    </Flex>
    <div className="image-container">
      <Image preview={false} src={risk_line} />
    </div>
    <h3 className="title">Start with products</h3>
    <Flex gap={31} justify="center" wrap="wrap">
      {PRODUCT_LIST.map(it => (
        <CardChartPie
          active={proposalData.product === it.label}
          inactive={proposalData.product && proposalData.product !== it.label}
          key={it.productId}
          onClick={() => {
            handleProductSelect(it.label);
          }}
          series={[
            {
              color: it.color,
              y: 100,
            },
          ]}
          title={it.label}
        />
      ))}
    </Flex>
    {proposalData.product && (
      <Flex gap={12} style={{ marginTop: 40 }} vertical>
        <Button
          disabled={!proposalData.portfolio}
          onClick={handleGoForward}
          shape="round"
          style={{ background: "#1971B9", color: "#fff" }}
          type="primary"
        >
          Choose Allocation Amount
        </Button>
        <Flex align="center" justify="center">
          <Button
            onClick={handleGoBack}
            shape="round"
            size="small"
            style={{ color: "#32A2DE" }}
            type="text"
          >
            Go back
          </Button>
          <div
            style={{
              background: "#32a2de",
              height: 20,
              width: 1,
            }}
          />
          <Button
            onClick={handleStartOver}
            shape="round"
            size="small"
            style={{ color: "#32A2DE" }}
            type="text"
          >
            Start over
          </Button>
        </Flex>
      </Flex>
    )}
  </StylesContainer>
);

export default SectionInit;
