import { Button, Modal, Row, theme, Typography } from "antd";

import Close from "../../../../../../icon/Close";

const ModalDeleteFolder = ({ activeFolderName, onCancel, onSuccess, open }) => {
  const { token } = theme.useToken();

  return (
    <Modal
      title="Confirm"
      open={open}
      onCancel={onCancel}
      closeIcon={<Close />}
      footer={
        <Row justify="space-between">
          <Button type="text" shape="round" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={onSuccess}
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
          >
            Save
          </Button>
        </Row>
      }
    >
      <div style={{ textAlign: "center", padding: 40 }}>
        <Typography.Paragraph>
          Are you sure you want to delete the folder <b>“{activeFolderName}”</b>{" "}
          and the contents within?
        </Typography.Paragraph>
        <Typography.Paragraph>
          This action cannot be undone.
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ModalDeleteFolder;
