import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../context";
import { Flex, Image, Layout, theme } from "antd";
import MenuProfile from "../../molecule/MenuProfile";
import MenuSidebar from "../../molecule/MenuSidebar";
import ModalAddProspect from "../../ecosystem/manager/component/ModalAddProspect";
import ModalConversationDetails from "../../ecosystem/super_admin/components/ModalConversationDetails";
import ModalLevelClientDetails from "../../ecosystem/level/components/ModalLevelClientDetails";
import ModalManagerSettings from "../../ecosystem/manager/component/ModalManagerSettings";
import ModalProposalGuideManager from "../../ecosystem/manager/component/ModalProposalGuideManager";
import ProposalHeader from "../../ecosystem/proposal/component/ProposalHeader";
import SectionAskAdvice from "../../organism/SectionAskAdvice";

import { SIDEBAR_TABS } from "./constant";

import {
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRiggs,
  isOrganizationRivershares,
  isPortalAdmin,
  isPortalProposal,
  isPortalSuperadmin,
} from "../../utils/helper/specialized";

import LevelLogo from "../../ecosystem/next/images/LevelLogo";
import { ReactComponent as OntrajectoryLogo } from "../../image/ot_logo_light.svg";
import astor_logo from "../../ecosystem/astor/images/logo_circle.svg";
import bluepoint_logo from "../../ecosystem/bluepoint/images/logo.png";
import riggs_logo from "../../ecosystem/riggs/images/logo.png";

const { Content, Sider } = Layout;

const LayoutPrimary = ({ children }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const organization = state.orgName ?? state.organization?.name;

  const getLogo = () => {
    if (isOrganizationRiggs(organization)) {
      return <Image preview={false} src={riggs_logo} />;
    } else if (isOrganizationBluepoint(organization)) {
      return <Image preview={false} src={bluepoint_logo} />;
    } else if (isOrganizationAstor(organization)) {
      return <Image preview={false} src={astor_logo} />;
    } else if (state.access?.cashflow) {
      return <OntrajectoryLogo style={{ width: "100%" }} />;
    } else if (isOrganizationLevel(organization)) {
      return <LevelLogo />;
    }
  };

  const getMenuItems = () => {
    // super admin app navigation
    if (isPortalSuperadmin()) {
      // same navigation for all tenants
      return SIDEBAR_TABS.level.superadmin;
    }

    // proposal app user navigation
    if (
      state.subscriptionType === "AdviserClient" ||
      state.subscriptionType === "AdviserLead" ||
      isPortalProposal()
    ) {
      if (isOrganizationLevel(organization)) {
        return SIDEBAR_TABS.level.proposal;
      } else if (isOrganizationOneascent(organization)) {
        return SIDEBAR_TABS.oneascent.proposal;
      } else if (isOrganizationAstor(organization)) {
        return SIDEBAR_TABS.astor.proposal;
      } else if (isOrganizationRivershares(organization)) {
        return SIDEBAR_TABS.rivershares.proposal;
      }
    }

    // manager navigation based on organization name
    if (isOrganizationAstor(organization)) {
      return isPortalAdmin()
        ? SIDEBAR_TABS.astor.admin
        : SIDEBAR_TABS.astor.advisor;
    }

    // manager navigation based on the accesses, provided by super admin
    if (state.access) {
      const { next, level, affiliate, proportal } = state.access;

      if (!affiliate && !level && next && !proportal) {
        // next only
        return SIDEBAR_TABS.access.next;
      } else if (!affiliate && level && !next && !proportal) {
        // level only
        return SIDEBAR_TABS.access.level;
      } else if (!affiliate && !level && !next && proportal) {
        // proportal only
        return SIDEBAR_TABS.access.proportal;
      } else if (affiliate && !level && !next && !proportal) {
        // affiliate only
        return SIDEBAR_TABS.access.affiliate;
      } else if (affiliate && next && proportal) {
        return SIDEBAR_TABS.access.affiliateNextProportal;
      } else if (level && next) {
        return SIDEBAR_TABS.access.levelNext;
      } else if (affiliate && next) {
        return SIDEBAR_TABS.access.affiliateNext;
      } else if (affiliate && proportal) {
        return SIDEBAR_TABS.access.affiliateProportal;
      } else if (next && proportal) {
        return SIDEBAR_TABS.access.affiliateProportal;
      } else {
        return SIDEBAR_TABS.disabled;
      }
    }
  };

  const handleButtonBackToDashboard = () => {
    state.viewMilestonesQuestionnaire &&
      state.setKeyValue("viewMilestonesQuestionnaire", false);
    state.viewPerspectiveIndividualResults &&
      state.setKeyValue("viewPerspectiveIndividualResults", false);
    state.viewPerspectiveCoupleResults &&
      state.setKeyValue("viewPerspectiveCoupleResults", false);
    state.viewPrioritiesCoupleResults &&
      state.setKeyValue("viewPrioritiesCoupleResults", false);

    state.setKeyValue("showButtonBackToDashboard", false);
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        style={{ background: token.sidebar_background }}
        width={token.sidebar_width}
      >
        <Flex
          align="center"
          justify="space-between"
          style={{
            height: "100%",
            padding: "26px 0 64px",
            width: "100%",
          }}
          vertical
        >
          <Flex gap={30} style={{ width: "100%" }} vertical>
            <Flex
              className="pointer"
              id="headerLogo"
              justify="center"
              onClick={() => navigate(getMenuItems()[0].path)}
            >
              {token.sidebar_logo ? (
                <Image preview={false} src={token.sidebar_logo} />
              ) : (
                getLogo()
              )}
            </Flex>
            <MenuSidebar
              itemsLayoutVertical={token.sidebar_icon_layout_vertical}
              routes={getMenuItems()?.filter(it => !it.hidden)}
            />
          </Flex>
          <MenuProfile logoutLink="/login" />
        </Flex>
      </Sider>
      <Layout>
        {isPortalProposal() && (
          <ProposalHeader
            handleButtonBackToDashboard={handleButtonBackToDashboard}
            openModal={state.openModal}
            organization={state.organization?.name ?? state.orgName}
            showButtonBackToDashboard={state.showButtonBackToDashboard}
          />
        )}

        <Content
          style={{
            background: "#ffffff",
            overflow: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>

      <ModalAddProspect
        handleClose={() => state.closeModal("addProspectModal")}
        open={state.addProspectModal}
      />
      <ModalLevelClientDetails
        handleClose={() =>
          state.setKeyValue("openModalLevelClientDetails", false)
        }
        open={state.openModalLevelClientDetails}
      />
      <ModalConversationDetails
        onCancel={() => state.closeModal("conversationModal")}
        open={state.conversationModal}
      />
      <ModalManagerSettings
        onCancel={() => state.setKeyValue("openModalManagerSettings", false)}
        open={state.openModalManagerSettings}
      />
      <ModalProposalGuideManager
        handleClose={() => state.closeModal("openModalProposalGuide")}
        open={state.openModalProposalGuide}
      />
      <SectionAskAdvice state={state} setState={setState} />
    </Layout>
  );
};

export default LayoutPrimary;
