import { Button, Flex, Modal } from "antd";

import Close from "../../../../icon/Close";

const ModalDeleteGoal = ({ open, handleCancel, handleOk }) => (
  <Modal
    closeIcon={<Close />}
    footer={
      <Flex justify="space-between">
        <Button onClick={handleCancel} shape="round" type="text">
          Cancel
        </Button>
        <Button onClick={handleOk} shape="round" type="primary">
          Yes
        </Button>
      </Flex>
    }
    onCancel={handleCancel}
    onOk={handleOk}
    open={open}
    title="Delete Goal"
    width={360}
  >
    <p>Are you sure you would like to delete this goal?</p>
  </Modal>
);

export default ModalDeleteGoal;
