import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context/admin/State";
import { Button, DatePicker, Flex, Input, InputNumber, Upload } from "antd";
import ModalPrimary from "../ModalPrimary";
import InputContainer from "../../../../../../atom/InputContainer";

import { StyledSwitch } from "./styles";

import { MAX_FEATURED_EVENTS_AMOUNT } from "../../../../constants";

import { addEvent, updateEvent, uploadFile } from "../../../../requests/global";

import { ReactComponent as UploadIcon } from "../../../../images/upload.svg";

const defaultEventData = {
  featured: false,
};

const ModalEvent = ({ open }) => {
  const [state] = useContext(State);
  const [eventData, setEventData] = useState({});
  const [loading, setLoading] = useState(false);
  const isEditEventView = !!state.activeEventId;

  useEffect(() => {
    if (open) {
      if (state.activeEventId) {
        setEventData(state.events.find(it => it._id === state.activeEventId));
      } else {
        setEventData(defaultEventData);
      }
    }
  }, [open]);

  const handleClose = () => {
    state.closeModal("eventModal");
    setEventData(defaultEventData);
    state.setKeyValue("activeEventId", null);
  };

  const handleSave = () => {
    const isFeaturedBefore = state.events.find(
      it => it._id === eventData._id
    )?.featured;
    const featuredEventsAmount = state.events.filter(it => it.featured)?.length;

    if (eventData.featured) {
      if (
        !isFeaturedBefore &&
        featuredEventsAmount >= MAX_FEATURED_EVENTS_AMOUNT
      ) {
        state.showWarning(
          `A maximum of ${MAX_FEATURED_EVENTS_AMOUNT} events can be featured at once. Please disable an event.`
        );
        return;
      }
    }

    setLoading(true);

    if (isEditEventView) {
      if (eventData.file) {
        // added new file to the form
        uploadFile({
          file: eventData.file,
          folderName: "events",
        })
          .then(uploadedFile => {
            updateEvent({
              eventId: state.activeEventId,
              payloadData: {
                ...eventData,
                graphicFile: {
                  filename: uploadedFile.filename,
                  key: uploadedFile.key,
                  url: uploadedFile.url,
                },
              },
            }).then(() => {
              state.reFetch.events().then(() => {
                state.showSuccess("Event data updated.");
                handleClose();
              });
            });
          })
          .finally(() => setLoading(false));
      } else {
        updateEvent({
          eventId: state.activeEventId,
          payloadData: eventData,
        })
          .then(() => {
            state.reFetch.events().then(() => {
              state.showSuccess("Event data updated.");
              handleClose();
            });
          })
          .catch(error => {
            state.showWarning((error?.response?.data?.error ?? [])[0].message);
          })
          .finally(() => setLoading(false));
      }
    } else {
      if (eventData.file) {
        uploadFile({
          file: eventData.file,
          folderName: "events",
        })
          .then(uploadedFile => {
            addEvent({
              ...eventData,
              graphicFile: {
                filename: uploadedFile.filename,
                key: uploadedFile.key,
                url: uploadedFile.url,
              },
            })
              .then(() => {
                state.showSuccess("Event added.");
                handleClose();
                state.reFetch.events();
                setEventData(defaultEventData);
              })
              .catch(error => {
                state.showWarning(
                  (error?.response?.data?.error ?? [])[0].message
                );
              });
          })
          .catch(({ message }) => state.showWarning(message))
          .finally(() => setLoading(false));
      } else {
        addEvent(eventData)
          .then(() => {
            state.showSuccess("Event added.");
            handleClose();
            state.reFetch.events();
            setEventData(defaultEventData);
          })
          .catch(error => {
            state.showWarning((error?.response?.data?.error ?? [])[0].message);
          })
          .finally(() => setLoading(false));
      }
    }
  };

  const updateEventData = (key, value) =>
    setEventData(lastState => ({
      ...lastState,
      [key]: value,
    }));

  return (
    <ModalPrimary
      destroyOnClose={true}
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round" size="large" type="text">
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleSave}
            shape="round"
            size="large"
            style={{ width: 134, background: "#001141", color: "#FFFFFF" }}
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={isEditEventView ? "Edit Event" : "Add New Event"}
      width={492}
    >
      <Flex gap={16} vertical>
        <Flex gap={16}>
          <InputContainer labelColor="#8396A6" labelText="Event Name">
            <Input
              onChange={e => updateEventData("name", e.target.value)}
              value={eventData.name}
            />
          </InputContainer>
          <InputContainer labelColor="#8396A6" labelText="Category">
            <Input
              onChange={e => updateEventData("category", e.target.value)}
              value={eventData.category}
            />
          </InputContainer>
        </Flex>
        <Flex gap={16}>
          <InputContainer labelColor="#8396A6" labelText="Dates">
            <DatePicker
              format="DD-MM-YYYY"
              onChange={e => updateEventData("date", e?.$d)}
              style={{
                width: "100%",
                background: "#EBF1F3",
                borderColor: "transparent",
              }}
              value={eventData.date && dayjs(eventData.date)}
            />
          </InputContainer>
          <InputContainer labelColor="#8396A6" labelText="Location">
            <Input
              onChange={e => updateEventData("location", e.target.value)}
              value={eventData.location}
            />
          </InputContainer>
        </Flex>
        <Flex gap={16}>
          <InputContainer labelColor="#8396A6" labelText="Link URL">
            <Input
              onChange={e => updateEventData("linkUrl", e.target.value)}
              value={eventData.linkUrl}
            />
          </InputContainer>
          <InputContainer labelColor="#8396A6" labelText="Order (max 3)">
            <InputNumber
              min={1}
              max={3}
              onChange={value => updateEventData("order", value)}
              style={{ width: 86 }}
              value={eventData.order}
            />
          </InputContainer>
        </Flex>
        <InputContainer labelColor="#8396A6" labelText="Featured">
          <StyledSwitch
            checked={eventData.featured}
            onChange={checked => updateEventData("featured", checked)}
            size="small"
          />
        </InputContainer>
        <InputContainer
          labelColor="#8396A6"
          labelText="Upload Event Graphic - Dimensions 1:1 (square)"
        >
          <Upload.Dragger
            name="file"
            maxCount={1}
            beforeUpload={() => false}
            onChange={file => updateEventData("file", file)}
            onRemove={() => updateEventData("file", null)}
            style={{ paddingLeft: 30 }}
          >
            <Flex
              align="center"
              justify="center"
              gap={15}
              style={{ height: 70 }}
            >
              <span className="ant-upload-drag-icon">
                <UploadIcon />
              </span>
              <span className="ant-upload-text" style={{ color: "#105DAE" }}>
                Drag here or <u>Browse</u>
              </span>
            </Flex>
          </Upload.Dragger>
        </InputContainer>
      </Flex>
    </ModalPrimary>
  );
};

export default ModalEvent;
