import dayjs from "dayjs";
import { Flex, Form, Input, InputNumber, theme } from "antd";

import {
  cleanPhoneMask,
  getCurrencyValue,
  selectValue,
  setPhoneMask,
} from "../../../../../../utils/helper/general";

const GoalsUserTab = () => {
  const { token } = theme.useToken();

  return (
    <div>
      <div style={{ marginBottom: 24 }}>
        <span style={{ color: "#6C6C6C" }}>Planning Type: </span>
        <span style={{ color: token.color_black }}>Goals</span>
      </div>
      <Flex gap={16}>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              message: "Enter new user First name, please.",
              required: true,
            },
          ]}
          style={{ width: "100%" }}
        >
          <Input
            id="firstNameInput"
            onClick={selectValue}
            placeholder="First Name"
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              message: "Enter new user Last name, please.",
              required: true,
            },
          ]}
          style={{ width: "100%" }}
        >
          <Input
            id="lastNameInput"
            onClick={selectValue}
            placeholder="Last Name"
          />
        </Form.Item>
      </Flex>
      <Flex gap={16}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              message: "Invalid email address, please re-enter.",
              required: true,
              type: "email",
            },
          ]}
          style={{ width: "100%" }}
        >
          <Input onClick={selectValue} type="email" />
        </Form.Item>
        <Form.Item label="Phone" name="phoneNumber" style={{ width: "100%" }}>
          <InputNumber
            controls={false}
            formatter={setPhoneMask}
            onClick={selectValue}
            parser={cleanPhoneMask}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Flex>
      <Flex gap={16}>
        <Form.Item
          label="Birth Year"
          name="yearBorn"
          style={{ width: "100%" }}
          rules={[
            {
              type: "number",
              min: 1940,
              max: dayjs().format("YYYY") - 5,
            },
          ]}
        >
          <InputNumber
            id="yearBornInput"
            inputMode="numeric"
            onClick={selectValue}
            pattern="[0-9]*"
            placeholder="YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Assets"
          name="currentAssets"
          rules={[
            {
              type: "number",
              min: 0,
              max: 100000000,
            },
          ]}
          style={{ width: "100%" }}
        >
          <InputNumber
            id="assetsInput"
            formatter={getCurrencyValue}
            onClick={selectValue}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="oldEmail" hidden />
      </Flex>
    </div>
  );
};

export default GoalsUserTab;
