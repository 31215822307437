import { useContext } from "react";
import State from "../../../../../../context";
import { Button, Modal, Row, theme, Typography } from "antd";

import { markAsProspect } from "../../../../../../utils/request/manager";
import { isAdminView } from "../../../../../../utils/helper/specialized";

import Close from "../../../../../../icon/Close";

const ModalConvertClient = ({ email, open, handleClose }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const handleConfirm = () => {
    state.setKeyValue("loading", true);

    markAsProspect(email)
      .then(() => {
        handleClose();
        state.showSuccess("Client converted into Prospect!");
        state.setAdminData();
      })
      .catch(error => {
        console.log(error);
        state.setKeyValue("loading", false);
      });
  };

  return (
    <Modal
      title="Mark as Prospect"
      open={open}
      onCancel={handleClose}
      closeIcon={<Close />}
      footer={
        <Row justify="space-between">
          <Button type="text" shape="round" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={handleConfirm}
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
            disabled={state.loading || isAdminView()}
          >
            Confirm
          </Button>
        </Row>
      }
    >
      <div style={{ textAlign: "center", padding: 40 }}>
        <Typography.Paragraph>
          This will convert &quot;<b>{email}</b>&quot; to a prospect.
        </Typography.Paragraph>
        <Typography.Paragraph>
          To see all prospects, click on the Prospects tab.
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ModalConvertClient;
