import { Flex, Input, InputNumber, Radio, Typography } from "antd";

import {
  cleanPhoneMask,
  selectValue,
  setPhoneMask,
} from "../../../../../../../../../../utils/helper/general";
import { isOrganizationOneascent } from "../../../../../../../../../../utils/helper/specialized";

const { Text } = Typography;

const AddAdvisorInformationTab = ({
  newAdvisorData,
  handleValueChange,
  organization,
}) => (
  <Flex gap={20} vertical>
    <Flex vertical>
      <Text>Name</Text>
      <Flex gap={6}>
        <Input
          className="input-custom"
          placeholder="First Name"
          value={newAdvisorData.firstName}
          onChange={event => handleValueChange("firstName", event.target.value)}
        />
        <Input
          className="input-custom"
          placeholder="Last Name"
          value={newAdvisorData.lastName}
          onChange={event => handleValueChange("lastName", event.target.value)}
        />
      </Flex>
    </Flex>
    <Flex vertical>
      <Text>Email</Text>
      <Input
        className="input-custom"
        placeholder="example@provider.com"
        value={newAdvisorData.email}
        onChange={event => handleValueChange("email", event.target.value)}
      />
    </Flex>
    <Flex vertical>
      <Text>Phone Number (optional)</Text>
      <InputNumber
        className="input-custom"
        controls={false}
        formatter={setPhoneMask}
        onChange={value => handleValueChange("phoneNumber", value)}
        onClick={selectValue}
        parser={cleanPhoneMask}
        placeholder="Enter phone number"
        style={{ width: "100%" }}
        value={newAdvisorData.phoneNumber}
      />
    </Flex>
    {isOrganizationOneascent(organization) && (
      <Flex vertical>
        <Text>Choose Onboarding Questionnaire</Text>
        <Radio.Group
          value={newAdvisorData.onboardingQuestionnaireType}
          onChange={event =>
            handleValueChange("onboardingQuestionnaireType", event.target.value)
          }
        >
          <Radio value="wealthManagement">Wealth Management</Radio>
          <Radio value="IPQ">IPQ (Investor Profile Questionnaire)</Radio>
        </Radio.Group>
      </Flex>
    )}
    <Flex vertical>
      <Text>Type</Text>
      <Radio.Group
        onChange={event =>
          handleValueChange("independent", event.target.value === "independent")
        }
        value={newAdvisorData.independent ? "independent" : "brokerDealer"}
      >
        <Radio value="brokerDealer">Broker/Dealer</Radio>
        <Radio value="independent">Independent</Radio>
      </Radio.Group>
    </Flex>
    {newAdvisorData.independent ? (
      <Flex vertical>
        <Text>Firm Name (optional)</Text>
        <Input
          className="input-custom"
          placeholder="Agency Name"
          value={newAdvisorData.agencyName}
          onChange={event =>
            handleValueChange("agencyName", event.target.value)
          }
        />
      </Flex>
    ) : (
      <Flex vertical>
        <Text>Broker/Dealer Name (optional)</Text>
        <Input
          className="input-custom"
          placeholder="Agency Name"
          value={newAdvisorData.brokerDealerName}
          onChange={event =>
            handleValueChange("brokerDealerName", event.target.value)
          }
        />
      </Flex>
    )}
  </Flex>
);

export default AddAdvisorInformationTab;
