export default {
  advisor_page_dashboard_active_tab_color: "#DBE247",
  advisor_page_dashboard_right_side_section_background: "#749b9e",
  advisor_page_dashboard_right_side_section_title_color: "#ffffff",
  advisor_page_dashboard_right_side_section_title_copy: "Market News & Updates",
  colorPrimary: "#215054",
  radio_button_color: "#4F8083",
  quarterly_goal_aum_color: "#DBE247",
  quarterly_goal_client_color: "#4F8083",
  quarterly_goal_proposal_color: "#749B9E",
  sidebar_avatar_background: "#6DC1C7",
  sidebar_avatar_color: "#FFFFFF",
  sidebar_background: "#03494E",
  sidebar_icon_color: "#9AB6B8",
  sidebar_icon_color_active: "#FEFEFE",
  sidebar_menu_item_hover_background: "#052D2C",
  top_products_pie_chart_pallet: ["#296469", "#DBE247", "#03494E", "#749B9E"],
};
