import { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Button,
  Col,
  Flex,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import InputContainer from "../../../../atom/InputContainer";
import ChartSkeleton from "../../../../atom/ChartSkeleton";

import { selectValue } from "../../../../utils/helper/general";
import { getLiveStressor } from "../../../../utils/request/regularApp";
import { getGrowthChartConfig } from "../../../../utils/chart";
import {
  DEFAULT_BIRTH_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../utils/constant";

const { Text, Title, Paragraph } = Typography;

const StyledSelect = styled(Select)`
  width: 105px;

  &.ant-select .ant-select-arrow {
    color: #354a5f;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

const defaultValues = {
  bearMarketDuration: 5,
  bearMarketIsOn: false,
  bearMarketPerformance: -20,
  bearMarketStartYear: +dayjs().format("YYYY"),
  bullMarketDuration: 5,
  bullMarketIsOn: false,
  bullMarketPerformance: 20,
  bullMarketStartYear: +dayjs().format("YYYY"),
};

const SectionStressTest = ({
  investmentAssumptions,
  liveAssessment,
  organization,
  product,
}) => {
  const [loading, setLoading] = useState(false);
  const [stressorCalculations, setStressorCalculations] = useState();
  const [forceFetchCalculations, setForceFetchCalculations] = useState(false);
  const [customValues, setCustomValues] = useState(defaultValues);

  useEffect(() => {
    fetchStressorCalculations();
  }, [product]);

  useEffect(() => {
    forceFetchCalculations && fetchStressorCalculations();
  }, [forceFetchCalculations]);

  const handleValueChange = (key, value, forceFetchCalculations) => {
    setCustomValues(lastState => ({
      ...lastState,
      [key]: value,
    }));
    setForceFetchCalculations(forceFetchCalculations);
  };

  const getSeries = (series = []) => {
    liveAssessment?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        lineWidth: 3,
        color: "#243A46",
        zoneAxis: "x",
        marker: {
          enabled: false,
        },
        data: liveAssessment.grandTotalColumn,
      });

    if (stressorCalculations?.stressor?.length) {
      if (stressorCalculations.stressor.find(it => it.name === "Bear Market")) {
        series.push({
          name: "Bear Market",
          lineWidth: 3,
          color: "#AD5748",
          zoneAxis: "x",
          marker: {
            enabled: false,
          },
          data: stressorCalculations.stressor.find(
            it => it.name === "Bear Market"
          ).data,
        });
      }

      if (stressorCalculations.stressor.find(it => it.name === "Bull Market")) {
        series.push({
          name: "Bull Market",
          lineWidth: 3,
          color: "#888E48",
          zoneAxis: "x",
          marker: {
            enabled: false,
          },
          data: stressorCalculations.stressor.find(
            it => it.name === "Bull Market"
          ).data,
        });
      }
    }

    return series;
  };

  const fetchStressorCalculations = () => {
    setLoading(true);

    if (customValues.bearMarketIsOn || customValues.bullMarketIsOn) {
      const requestBody = {
        marketEvent: [
          {
            active: customValues.bearMarketIsOn,
            durationYears: customValues.bearMarketDuration,
            name: "Bear Market",
            percent: customValues.bearMarketPerformance,
            startYear: customValues.bearMarketStartYear,
          },
          {
            active: customValues.bullMarketIsOn,
            durationYears: customValues.bullMarketDuration,
            name: "Bull Market",
            percent: customValues.bullMarketPerformance,
            startYear: customValues.bullMarketStartYear,
          },
        ],
        objective: {
          ...investmentAssumptions,
          ...product,
          birthYear: product.currentAge
            ? undefined
            : (product.birthYear ?? DEFAULT_BIRTH_YEAR),
          investmentDuration:
            product.investmentDuration ??
            +investmentAssumptions.investmentDuration,
          withdrawalLevel: product.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL,
        },
        organization:
          organization?.toLowerCase() === "level"
            ? "level2"
            : organization?.toLowerCase(),
        productId: product._id,
      };

      getLiveStressor(requestBody).then(data => {
        setStressorCalculations(data);
        setForceFetchCalculations(false);
        setLoading(false);
      });
    } else {
      setStressorCalculations(null);
      setForceFetchCalculations(false);
      setLoading(false);
    }
  };

  return (
    <Row gutter={[58, 32]}>
      <Col span={24}>
        <ChartSkeleton loading={loading} height={332}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...getGrowthChartConfig({
                birthYear: product.currentAge
                  ? dayjs().format("YYYY") - product.currentAge
                  : product.birthYear,
              }),
              series: getSeries(),
            }}
          />
        </ChartSkeleton>
      </Col>
      <Col xs={24} sm={12} md={9} lg={9}>
        <Flex vertical gap={16}>
          <Flex align="center" justify="space-between">
            <Flex vertical>
              <Tooltip
                title={
                  "A bear market refers to a period of time in the financial markets when prices of securities, such as stocks, bonds, or commodities, are falling or expected to fall. It is characterized by a pessimistic sentiment among investors, resulting in a downward trend in market prices."
                }
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: "#2A2828",
                  }}
                  strong
                >
                  Bear Market
                </Text>
              </Tooltip>
              <Text>Market Event</Text>
            </Flex>
            <Switch
              checked={customValues.bearMarketIsOn}
              disabled={forceFetchCalculations}
              onChange={checked =>
                handleValueChange("bearMarketIsOn", checked, true)
              }
            />
          </Flex>
          <InputContainer labelText="Market Performance">
            <InputNumber
              className="input-custom"
              formatter={value => `${value}%`}
              max={100}
              min={-100}
              onChange={value =>
                handleValueChange("bearMarketPerformance", value)
              }
              onClick={selectValue}
              parser={value => value.replace("%", "")}
              style={{ maxWidth: "100%" }}
              value={customValues.bearMarketPerformance}
            />
          </InputContainer>
          <Flex vertical gap={16}>
            <Flex gap={18}>
              <InputContainer labelText="Start Year">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("bearMarketStartYear", value)
                  }
                  options={new Array(50).fill(null).map((it, index) => ({
                    value: +dayjs().format("YYYY") + index,
                    label: +dayjs().format("YYYY") + index,
                  }))}
                  value={customValues.bearMarketStartYear}
                />
              </InputContainer>
              <InputContainer labelText="Duration">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("bearMarketDuration", value)
                  }
                  options={new Array(60).fill(null).map((it, index) => ({
                    value: index + 1,
                    label: `${index + 1} Years`,
                  }))}
                  value={customValues.bearMarketDuration}
                />
              </InputContainer>
            </Flex>
            <Button
              type="primary"
              size="small"
              shape="round"
              style={{ width: 77 }}
              onClick={fetchStressorCalculations}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Col>
      <Col xs={24} sm={12} md={9} lg={9}>
        <Flex vertical gap={16}>
          <Flex align="center" justify="space-between">
            <Flex vertical>
              <Tooltip
                title={
                  "A bull market refers to a financial market characterized by rising prices and optimism among investors. In a bull market, the overall trend of the market is upward, with stocks, bonds, or other assets experiencing sustained price increases."
                }
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: "#2A2828",
                  }}
                  strong
                >
                  Bull Market
                </Text>
              </Tooltip>
              <Text>Market Event</Text>
            </Flex>
            <Switch
              checked={customValues.bullMarketIsOn}
              disabled={forceFetchCalculations}
              onChange={checked =>
                handleValueChange("bullMarketIsOn", checked, true)
              }
            />
          </Flex>
          <InputContainer labelText="Market Performance">
            <InputNumber
              className="input-custom"
              formatter={value => `${value}%`}
              max={100}
              min={-100}
              onChange={value =>
                handleValueChange("bullMarketPerformance", value)
              }
              onClick={selectValue}
              parser={value => value.replace("%", "")}
              style={{ maxWidth: "100%" }}
              value={customValues.bullMarketPerformance}
            />
          </InputContainer>
          <Flex vertical gap={16}>
            <Flex gap={18}>
              <InputContainer labelText="Start Year">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("bullMarketStartYear", value)
                  }
                  options={new Array(50).fill(null).map((it, index) => ({
                    value: +dayjs().format("YYYY") + index,
                    label: +dayjs().format("YYYY") + index,
                  }))}
                  value={customValues.bullMarketStartYear}
                />
              </InputContainer>
              <InputContainer labelText="Duration">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("bullMarketDuration", value)
                  }
                  options={new Array(60).fill(null).map((it, index) => ({
                    value: index + 1,
                    label: `${index + 1} Years`,
                  }))}
                  value={customValues.bullMarketDuration}
                />
              </InputContainer>
            </Flex>
            <Button
              type="primary"
              size="small"
              shape="round"
              style={{ width: 77 }}
              onClick={fetchStressorCalculations}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Col>
      <Col span={24}>
        <Title style={{ fontSize: 18, fontWeight: 400, color: "#2A2828" }}>
          About Stress Test
        </Title>
        <Paragraph>
          Stress tests results (%) represent the potential impact of discrete
          market events on the selected portfolios proxies and their respective
          asset classes.
        </Paragraph>
        <Paragraph>
          The analysis provided is illustrative only. This simulation cannot
          predict a portfolio&apos;s risk of loss due to, among other things,
          changing market conditions or other unanticipated circumstances.
        </Paragraph>
      </Col>
    </Row>
  );
};

export default SectionStressTest;
