import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Flex } from "antd";
import SortableCard, { priorityValuesList } from "../SortableCard";

import { isTouchDevice } from "../../../../../../utils/helper/general";

const SortableItem = ({
  id,
  index,
  itemStyle,
  handleArrowUp,
  handleArrowDown,
  handleArrowRight,
  showIndex,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    pointerEvents: id?.includes("[disabled]") ? "none" : "",
    transform: CSS.Transform.toString(transform),
    transition,
    ...itemStyle,
  };

  return isTouchDevice() ? (
    <div style={style}>
      <SortableCard
        label={id}
        index={index}
        handleArrowUp={handleArrowUp}
        handleArrowDown={handleArrowDown}
        handleArrowRight={handleArrowRight}
        showIndex={showIndex}
      />
    </div>
  ) : (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SortableCard label={id} index={index} showIndex={showIndex} />
    </div>
  );
};

const SortableColumn = ({
  containerStyle,
  deckView,
  disabled,
  id,
  items,
  handleArrowUp,
  handleArrowDown,
  handleArrowRight,
  showIndex,
}) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  const getListByType = type =>
    items.filter(
      label => priorityValuesList.find(it => it.label === label)?.type === type
    );

  const valuesByType = {
    Spending: getListByType("Spending"),
    Giving: getListByType("Giving"),
    Investing: getListByType("Investing"),
    Saving: getListByType("Saving"),
  };

  return (
    <SortableContext
      disabled={disabled}
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <Flex
        className="sortable-column"
        ref={setNodeRef}
        style={containerStyle}
        vertical
      >
        {items.map((id, index) => {
          if (deckView) {
            //combine cards of the same type into decks
            const valueType = priorityValuesList.find(
              it => it.label === id
            )?.type;

            return (
              <SortableItem
                itemStyle={
                  valuesByType[valueType] && valuesByType[valueType][0] === id // is first items of the deck
                    ? {
                        zIndex: ++items.length,
                      }
                    : {
                        // not first item of the deck
                        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.35)",
                        borderRadius: 8,
                        marginLeft: "2%",
                        marginTop: "-49px",
                        overflow: "hidden",
                        pointerEvents: "none",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "96%",
                        zIndex: items.length - index,
                      }
                }
                id={id}
                index={++index}
                key={id}
                handleArrowUp={handleArrowUp}
                handleArrowDown={handleArrowDown}
                handleArrowRight={handleArrowRight}
                showIndex={showIndex}
              />
            );
          } else {
            return (
              <SortableItem
                id={id}
                index={++index}
                key={id}
                handleArrowUp={handleArrowUp}
                handleArrowDown={handleArrowDown}
                handleArrowRight={handleArrowRight}
                showIndex={showIndex}
              />
            );
          }
        })}
      </Flex>
    </SortableContext>
  );
};

export default SortableColumn;
