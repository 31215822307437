import axios from "axios";

import {
  genDataForPDF,
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../helper/specialized";
import { getDataForAstorProposalPdf } from "../../ecosystem/astor/utils/specialized";

import { DEFAULT_BIRTH_YEAR } from "../constant";

export const addGoalUser = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/addGoalsUser`,
    requestBody,
    {
      withCredentials: true,
    }
  );

export const areLoginsValid = ({ email, password }) =>
  axios.get(
    `${
      process.env.REACT_APP_HOME_API_DOMAIN
    }/areLoginsValid?email=${encodeURIComponent(
      email
    )}&pwd=${encodeURIComponent(password)}&initiateMfa=true`
  );

export const createGoal = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/upsertGoal`,
    requestBody,
    { withCredentials: true }
  );

export const changeEmail = newEmail =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/changeEmail`,
    { newEmail },
    { withCredentials: true }
  );

export const changeUserPassword = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/changePassword`,
    requestBody,
    { withCredentials: true }
  );

export const createCompletion = params =>
  axios.get(`${process.env.REACT_APP_PENNY_MAIN_API}/completion`, {
    params,
  });

export const deleteGoal = scenarioID =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/deleteGoals2Scenario`,
    {
      scenarioID,
    },
    { withCredentials: true }
  );

export const downloadProposal = config => {
  const organization = config?.userManagerData?.organization?.name;

  if (isOrganizationOneascent(organization)) {
    genDataForPDF(config);
    window.open("/assets/pdf/pdfOneAscent/pdfGeneratorOA2.html", "_blank");
  } else if (isOrganizationRivershares(organization)) {
    genDataForPDF(config);
    window.open("/assets/pdf/rivershares/pdfGenerator.html", "_blank");
  } else if (isOrganizationAstor(organization)) {
    getDataForAstorProposalPdf(config);
    window.open("/assets/pdf/astor/pdfGenerator2.html", "_blank");
  }
};

export const getCalculations = ({
  currentScenario,
  incomeBarchart = false,
  client,
}) =>
  axios
    .post(
      `${process.env.REACT_APP_CALCS_API}/api/v1/update-everything`,
      {
        client,
        currentScenario,
        incomeBarchart,
      },
      { withCredentials: true }
    )
    .then(response => response.data);

export const getLiveAssessment = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_CALCS_API}/api/v1/liveAssessment`,
      {
        ...requestBody,
        objective: {
          ...requestBody.objective,
          birthYear: requestBody.objective?.currentAge
            ? null
            : (requestBody.objective?.birthYear ?? DEFAULT_BIRTH_YEAR),
          proposalSavedDate: undefined,
        },
        organization:
          requestBody.organization?.toLowerCase() === "level"
            ? "level2"
            : (requestBody.organization ?? "level2"),
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const getLiveSimulation = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_CALCS_API}/api/v1/liveSimulation`,
      {
        ...requestBody,
        objective: {
          ...requestBody.objective,
          birthYear: requestBody.objective?.currentAge
            ? undefined
            : (requestBody.objective?.birthYear ?? DEFAULT_BIRTH_YEAR),
        },
        organization:
          requestBody.organization?.toLowerCase() === "level"
            ? "level2"
            : (requestBody.organization ?? "level2"),
      },
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const getLiveStressor = requestBody =>
  axios
    .post(
      `${process.env.REACT_APP_CALCS_API}/api/v1/liveStressor`,

      requestBody,
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);

export const getFinancialProducts = (organization = "goals") => {
  let requestOrganizationName = organization?.toLowerCase();

  if (isOrganizationLevel(organization)) {
    requestOrganizationName = "level2";
  } else if (isOrganizationBluepoint(organization)) {
    // Bluepoint used Goals financial products list
    requestOrganizationName = "goals";
  }

  return axios
    .get(
      `${
        process.env.REACT_APP_HOME_API_DOMAIN
      }/financialProducts?organization=${requestOrganizationName}`,
      {
        withCredentials: true,
      }
    )
    .then(response => response.data);
};

export const getSimulations = (scenarioName, montecarlo) =>
  axios
    .post(
      `${process.env.REACT_APP_CALCS_API}/api/v1/simulations`,
      {
        client: "goals",
        montecarlo,
        currentScenario: scenarioName,
      },
      { withCredentials: true }
    )
    .then(response => response.data);

export const getSubscriptionType = email =>
  axios
    .get(
      `${
        process.env.REACT_APP_HOME_API_DOMAIN
      }/subscriptionType?email=${encodeURIComponent(email)}`,
      { withCredentials: true }
    )
    .then(({ data }) => {
      data?.userType && localStorage.setItem("userType", data.userType);
      data?.collection && localStorage.setItem("collection", data.collection);

      return data;
    });

export const getUserData = () =>
  axios
    .get(`${process.env.REACT_APP_HOME_API_DOMAIN}/getUserData`, {
      withCredentials: true,
    })
    .then(response => response.data);

export const loginUser = ({ email, password }) =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/authenticate`,
    {
      email,
      password,
    },
    { withCredentials: true }
  );

export const logout = () =>
  axios.get(`${process.env.REACT_APP_CALCS_API}/api/v1/logOut`, {
    withCredentials: true,
  });

export const oAuthAddGoalGoogleUser = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/oAuthAddGoalsGoogleUser`,
    { goalObjective: requestBody.goalObjective },
    {
      headers: {
        Authorization: `Bearer ${requestBody.token}`,
        "Content-Type": "application/json",
      },
    }
  );

export const oAuthAddGoalsAppleUser = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/oAuthAddGoalsAppleUser`,
    { goalObjective: requestBody.goalObjective },
    {
      headers: {
        Authorization: `Bearer ${requestBody.token}`,
        "Content-Type": "application/json",
      },
    }
  );

export const oAuthLoginUserGoogle = token =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/oAuthLoginGoogle`,
    {},
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

export const oAuthLoginUserApple = token =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/oAuthLoginApple`,
    {},
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

export const resetPassword = email =>
  axios.get(
    `${
      process.env.REACT_APP_HOME_API_DOMAIN
    }/resetPasswordAjax?resetEmail=${encodeURIComponent(email)}`
  );

export const saveUserPersonalInfo = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/saveUserPersonalInfo`,
    requestBody,
    { withCredentials: true }
  );

export const saveUserPreferences = preferences =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/saveUserPreferences`,
    preferences,
    { withCredentials: true }
  );

export const sendMFA = (email, mfaToken) =>
  axios.get(
    `${
      process.env.REACT_APP_HOME_API_DOMAIN
    }/isMfaValid?email=${encodeURIComponent(email)}&mfaToken=${mfaToken}`
  );

export const sendQuestion = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/sendFeedback`,
    {
      feedback: requestBody,
    },
    { withCredentials: true }
  );

export const setMFAEnabled = isMfaEnabled =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/saveMfaEnabled`,
    {
      isMfaEnabled,
    },
    { withCredentials: true }
  );

export const submitHubspotForm = ({ fields }) => {
  const portalId = "22416949";
  const formGuid = "17edcde1-cd7c-454e-972c-21f5c693c917";

  return axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields,
      context: {
        pageUri: "goals.ontrajectory.com/login",
        pageName: "Login Goals",
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateGoal2progress = (goalId, progress) =>
  axios
    .post(
      `${process.env.REACT_APP_HOME_API_DOMAIN}/updateGoal2progress`,
      {
        scenarioID: goalId,
        progress,
      },
      {
        withCredentials: true,
      }
    )
    .then(({ data }) => data);

export const updatePersonalInfo = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/updatePersonalInfo`,
    requestBody,
    { withCredentials: true }
  );

export const updateProductObjective = requestBody =>
  axios.post(
    `${process.env.REACT_APP_HOME_API_DOMAIN}/updateProductObjective`,
    requestBody,
    { withCredentials: true }
  );

export const verifyPassword = password =>
  axios.get(
    `${
      process.env.REACT_APP_HOME_API_DOMAIN
    }/verifyPassword?pwd=${encodeURIComponent(password)}`,
    { withCredentials: true }
  );
