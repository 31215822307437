import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ViewAdvisor from "./components/ViewAdvisor";
import ViewAdvisors from "./components/ViewAdvisors";

const PageAdminAdvisors = () => {
  const location = useLocation();
  const [activeAdvisorEmail, setActiveAdvisorEmail] = useState();

  useEffect(() => {
    setActiveAdvisorEmail(null);
  }, [location]);

  return activeAdvisorEmail ? (
    <ViewAdvisor
      advisorEmail={activeAdvisorEmail}
      setActiveAdvisorEmail={setActiveAdvisorEmail}
    />
  ) : (
    <ViewAdvisors setActiveAdvisorEmail={setActiveAdvisorEmail} />
  );
};

export default PageAdminAdvisors;
