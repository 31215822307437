import dayjs from "dayjs";
import {
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";

import { MIN_BIRTH_YEAR } from "../../../../../../utils/constant";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";

const CreateGoalStepFillData = ({ newGoalData, state, updateNewGoalData }) => {
  const goalType = newGoalData.goalType;

  return (
    <Flex className="data-container" gap={22} vertical>
      {goalType === "other" && (
        <Form.Item label="Name your goal">
          <Input
            id="goalNameInput"
            className="input-custom"
            value={newGoalData.goalName}
            placeholder="Placeholder..."
            onChange={event =>
              updateNewGoalData("goalName", event.target.value)
            }
            onClick={selectValue}
          />
        </Form.Item>
      )}
      {goalType !== "emergencySavings" && (
        <>
          <Form.Item label="Total Savings">
            <InputNumber
              id="investmentAmountInput"
              className="input-custom"
              min={0}
              max={100000000}
              step={1000}
              value={newGoalData.investmentAmount}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("investmentAmount", value)}
              onClick={selectValue}
            />
          </Form.Item>
          <Form.Item label="Monthly Contributions">
            <InputNumber
              id="contributionsInput"
              className="input-custom"
              min={0}
              max={100000}
              step={100}
              value={newGoalData.contributions / 12}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("contributions", value * 12)}
              onClick={selectValue}
            />
          </Form.Item>
        </>
      )}
      {goalType === "savings" && (
        <Form.Item label="Target Amount">
          <InputNumber
            id="targetAmountInput"
            className="input-custom"
            value={newGoalData.targetAmount}
            min={0}
            max={100000000}
            step={1000}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onChange={value => updateNewGoalData("targetAmount", value)}
            onClick={selectValue}
          />
        </Form.Item>
      )}
      {(goalType === "savings" || goalType === "other") && (
        <Form.Item label="Length of Goal">
          <InputNumber
            addonAfter={
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  options={[
                    {
                      label: "Years",
                      value: "years",
                    },
                    {
                      disabled: true,
                      label: "Quarters",
                      value: "quarters",
                    },
                    {
                      disabled: true,
                      label: "Months",
                      value: "months",
                    },
                  ]}
                  style={{ width: 120 }}
                  value="years"
                />
              </Form.Item>
            }
            id="investmentDurationInput"
            className="input-custom"
            value={newGoalData.investmentDuration}
            min={1}
            max={80}
            onChange={value => updateNewGoalData("investmentDuration", value)}
            onClick={selectValue}
          />
        </Form.Item>
      )}
      {goalType === "income" && (
        <Form.Item label="Birth Year">
          <DatePicker
            id="birthYearInput"
            className="input-custom"
            value={newGoalData.birthYear}
            picker="year"
            format="YYYY"
            placeholder="YYYY"
            disabledDate={current =>
              dayjs(current).isAfter(dayjs().format("YYYY")) ||
              dayjs(current).format("YYYY") < MIN_BIRTH_YEAR
            }
            onChange={date => updateNewGoalData("birthYear", date)}
          />
        </Form.Item>
      )}
      {goalType === "income" && (
        <Form.Item label="Annual Retirement Withdrawal %">
          <InputNumber
            id="withdrawalLevelInput"
            className="input-custom"
            defaultValue={1}
            min={1}
            max={100}
            value={newGoalData.withdrawalLevel}
            formatter={value => `${value}%`}
            parser={value => value.replace("%", "")}
            onChange={value => updateNewGoalData("withdrawalLevel", value)}
            onClick={selectValue}
            style={{ width: 127 }}
          />
        </Form.Item>
      )}
      {goalType === "emergencySavings" && (
        <>
          <Form.Item label="Total Monthly Expenses">
            <InputNumber
              id="monthlyExpensesInput"
              className="input-custom"
              inputMode="numeric"
              pattern="[0-9]*"
              max={10000000}
              min={0}
              step={1000}
              value={newGoalData.monthlyExpenses}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("monthlyExpenses", value)}
              onClick={selectValue}
            />
          </Form.Item>
          <u
            className="pointer"
            onClick={() => state.openModal("monthlyExpensesModal")}
            style={{
              fontWeight: 500,
            }}
          >
            Help me calculate
          </u>
          <Form.Item label="How long do you want the savings to last?">
            <Radio.Group
              onChange={e =>
                updateNewGoalData("investmentDuration", e.target.value)
              }
              optionType="button"
              value={newGoalData.investmentDuration}
            >
              <Radio value={0.25}>3 month</Radio>
              <Radio value={0.5}>6 month</Radio>
              <Radio value={1}>12 month</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Saved to date">
            <InputNumber
              id="savedToDateInput"
              className="input-custom"
              inputMode="numeric"
              pattern="[0-9]*"
              max={10000000}
              min={0}
              step={1000}
              value={newGoalData.investmentAmount}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("investmentAmount", value)}
              onClick={selectValue}
            />
          </Form.Item>
          <Form.Item label="Savings each month until goal is reached">
            <InputNumber
              className="input-custom"
              id="contributionsInput"
              inputMode="numeric"
              pattern="[0-9]*"
              max={10000000}
              min={0}
              step={1000}
              value={newGoalData.contributions / 12}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onChange={value => updateNewGoalData("contributions", value * 12)}
              onClick={selectValue}
            />
          </Form.Item>
        </>
      )}
    </Flex>
  );
};

export default CreateGoalStepFillData;
