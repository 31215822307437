import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  .ant-form-item {
    margin: 0;
  }

  p {
    line-height: 19px;
    color: #1b5568;
    margin-top: 22px;
  }

  .data-container {
    box-sizing: border-box;
    min-height: 322px;
    overflow: auto;
    padding: 2px 2px 0 40px;
  }

  .ant-radio-button-wrapper {
    height: 100%;
    background: #ffffff;
    color: #292828;
    padding: 3px 16px;
    border-color: transparent;
    border-radius: 25px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #292828;
    color: #ffffff;
    border-color: transparent;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;

export default StyledModal;
