import { Image } from "antd";

import StylesContainer from "./styles";

import step_inti from "../../images/step_init.png";

const StepInit = () => (
  <StylesContainer vertical>
    <Image preview={false} src={step_inti} />
    <div className="step-init-description">
      <p>
        Welcome to the <b>Perspectives</b> assessment, where we discuss how we
        think and feel about money. There are five (5) short prompts, each with
        four (4) options. Please order each of these options from your{" "}
        <u>
          <i>
            <b>most likely answer</b>
          </i>
        </u>{" "}
        at the top, to your{" "}
        <u>
          <i>
            <b>least likely answer</b>
          </i>
        </u>{" "}
        at the bottom. Here are the ground rules:
      </p>
      <ol>
        <li>
          There are{" "}
          <u>
            <i>
              <b>no right or wrong answers!</b>
            </i>
          </u>{" "}
          With that said, feel free! You’re free to answer truthfully, not how
          you think or feel you ought to answer.
        </li>
        <li>
          Answer with what seems most natural to you today! Consider your{" "}
          <u>
            <i>
              <b>current tendencies</b>
            </i>
          </u>
          , rather than where you once were or wish to be one day.
        </li>
        <li>
          After the quiz, you will be presented with options to{" "}
          <u>
            <i>
              <b>select your Perspectives Profile</b>
            </i>
          </u>
          .
        </li>
      </ol>
    </div>
  </StylesContainer>
);

export default StepInit;
