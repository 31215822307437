import styled from "styled-components";

const StylesContainer = styled.div`
  background: #f5f5f5;
  height: 100vh;
  min-width: 1140px;
  overflow: auto;

  header {
    align-items: center;
    border-bottom: 1px solid #c9c8c8;
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;

    h3 {
      color: #1e1919;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }
  }

  main {
    display: flex;
    gap: 31px;
    justify-content: center;
    height: 100%;
    min-width: fit-content;
    padding: 51px;
  }

  .news-updates-container {
    background: ${({ token }) =>
      token.advisor_page_dashboard_right_side_section_background};
    border-radius: 12px;
    display: ${({ token }) =>
      token.advisor_page_dashboard_right_side_section_hidden ? "none" : "flex"};
    height: 100%;
    max-height: 839px;
    min-width: 396px;
    padding: 24px;
    width: 396px;

    iframe {
      border-radius: 12px;
    }

    .title {
      font-size: 21px;
      font-weight: 400;
      color: ${({ token }) =>
        token.advisor_page_dashboard_right_side_section_title_color};
      margin: 0 0 14px;
    }

    .unlock-features-section {
      background: #dbe247;
      padding: 14px;
      border-radius: 12px;
      margin-top: 28px;

      .title {
        color: #1e1919;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }

      .description {
        line-height: 19px;
        color: #1e1919;
        margin: 0;
      }
    }

    .news-section {
      background: #ffffff;
      border-radius: 12px;
      overflow: auto;
      margin-top: 25px;

      .header {
        font-size: 18px;
        font-weight: 500;
        color: #a5a3a3;
        padding: 16px 23px 11px;
        border-bottom: 1px solid #e6f4ef;
        margin: 0;
      }

      .body {
        padding: 21px 26px;
        overflow: auto;
      }

      .description {
        color: #a5a3a3;
      }
    }
  }
`;

export default StylesContainer;
