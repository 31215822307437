import { useEffect, useState } from "react";
import { Button, Col, Flex, Row, Space, Typography } from "antd";
import FiltersContainer from "./components/FiltersContainer";
import CardProduct from "../../../../../../molecule/CardProduct";

import {
  AllProductsContainer,
  ProductListContainer,
  ProductListHeading,
} from "./styles";

import { DEFAULT_FILTER_ASSET_MANAGERS_LEVEL } from "../../../../../level/constants";

import { ReactComponent as Gear } from "../../../../../../icon/gear.svg";

const { Text } = Typography;

const LevelExplore = ({
  comparedProducts,
  openModal,
  productActions,
  productsList,
  handleCardClick,
  setCompareMode,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [assetManagerFilters, setAssetManagerFilters] = useState(
    DEFAULT_FILTER_ASSET_MANAGERS_LEVEL
  );
  const [broadCategoryFilters, setBroadCategoryFilters] = useState([]);
  const [globalCategoryFilters, setGlobalCategoryFilters] = useState([]);
  const [productCategoryFilters, setProductCategoryFilters] = useState([]);
  const [productFiltersApplied, setProductFiltersApplied] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Set filtersApplied to true if any filters are applied
  useEffect(() => {
    if (
      searchTerm === "" &&
      assetManagerFilters.length === 0 &&
      broadCategoryFilters.length === 0 &&
      globalCategoryFilters.length === 0 &&
      productCategoryFilters.length === 0
    ) {
      setProductFiltersApplied(false);
    } else {
      setProductFiltersApplied(true);
    }
    setFilteredProducts(getFilteredProducts());
  }, [
    productsList,
    searchTerm,
    assetManagerFilters,
    broadCategoryFilters,
    globalCategoryFilters,
    productCategoryFilters,
  ]);

  const handleAssetManagerFilterChange = checkedValues => {
    setAssetManagerFilters(checkedValues);
  };

  const handleBroadCategoryFilterChange = checkedValues => {
    setBroadCategoryFilters(checkedValues);
  };

  const handleGlobalCategoryFilterChange = checkedValues => {
    setGlobalCategoryFilters(checkedValues);
  };

  const handleProductCategoryFilterChange = checkedValues => {
    setProductCategoryFilters(checkedValues);
  };

  const applySearchFilter = products => {
    if (searchTerm === "") return products;
    return products?.filter(
      product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) || ""
    );
  };

  const applyFilter = (products, key, filters) => {
    if (filters.length === 0) return products;
    return products?.filter(product => filters.includes(product[key]));
  };

  const getFilteredProducts = () =>
    applyFilter(
      applyFilter(
        applyFilter(
          applyFilter(
            applySearchFilter(productsList),
            "assetManager",
            assetManagerFilters
          ),
          "broadCategory",
          broadCategoryFilters
        ),
        "globalCategory",
        globalCategoryFilters
      ),
      "productCategory",
      productCategoryFilters
    );

  return (
    !!productsList?.length && (
      <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <Row justify="space-between" gutter={20}>
          <Col xs={24} sm={24} md={24} lg={6}>
            <Row justify="space-between">
              <FiltersContainer
                productFiltersApplied={productFiltersApplied}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                assetManagerFilters={assetManagerFilters}
                broadCategoryFilters={broadCategoryFilters}
                globalCategoryFilters={globalCategoryFilters}
                productCategoryFilters={productCategoryFilters}
                handleAssetManagerFilterChange={handleAssetManagerFilterChange}
                handleBroadCategoryFilterChange={
                  handleBroadCategoryFilterChange
                }
                handleGlobalCategoryFilterChange={
                  handleGlobalCategoryFilterChange
                }
                handleProductCategoryFilterChange={
                  handleProductCategoryFilterChange
                }
                productsList={productsList}
                filteredProducts={filteredProducts}
              />
            </Row>
          </Col>
          {productFiltersApplied ? (
            <Col xs={24} sm={24} md={24} lg={18}>
              <Space direction="vertical">
                <Row
                  align="bottom"
                  justify="space-between"
                  style={{
                    borderBottom: "1px solid #7ebaba",
                    marginBottom: 10,
                    paddingBottom: 8,
                  }}
                >
                  <Text
                    style={{
                      color: "#3e5377",
                      fontSize: 18,
                    }}
                  >
                    Filtered Products ({filteredProducts?.length})
                  </Text>
                  <Flex align="center" gap={8}>
                    <Flex
                      className="pointer"
                      gap={8}
                      onClick={() =>
                        openModal("defaultProductAssumptionsModal")
                      }
                    >
                      <Gear />
                      <Typography.Text>
                        Default Product Assumptions
                      </Typography.Text>
                    </Flex>
                    <Button
                      disabled={!comparedProducts?.length}
                      size="small"
                      shape="round"
                      type="primary"
                      onClick={() => setCompareMode(true)}
                    >
                      Compare Mode ({comparedProducts?.length} of 3)
                    </Button>
                  </Flex>
                </Row>
                <Row>
                  <AllProductsContainer>
                    {filteredProducts?.map(product => (
                      <CardProduct
                        key={`filtered-list-${product._id}`}
                        product={product}
                        actions={productActions(product)}
                        handleCardClick={handleCardClick}
                        labels={[
                          product.broadCategory,
                          product.globalCategory,
                          product.productCategory,
                        ]}
                      />
                    ))}
                  </AllProductsContainer>
                </Row>
              </Space>
            </Col>
          ) : (
            <Col xs={24} sm={24} md={24} lg={18}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <ProductListHeading>Top Picks</ProductListHeading>
                <Row>
                  <ProductListContainer>
                    {productsList
                      .filter(p => p.promoted)
                      .slice(0, 3)
                      .map(product => (
                        <CardProduct
                          key={`top-picks-list-${product._id}`}
                          product={product}
                          actions={productActions(product)}
                          handleCardClick={handleCardClick}
                          labels={[
                            product.broadCategory,
                            product.globalCategory,
                            product.productCategory,
                          ]}
                        />
                      ))}
                  </ProductListContainer>
                </Row>
                <ProductListHeading>Just Added</ProductListHeading>
                <Row>
                  <ProductListContainer>
                    {productsList
                      .sort(
                        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
                      )
                      .slice(0, 3)
                      .map(product => (
                        <CardProduct
                          key={`recent-list-${product._id}`}
                          product={product}
                          actions={productActions(product)}
                          handleCardClick={handleCardClick}
                          labels={[
                            product.broadCategory,
                            product.globalCategory,
                            product.productCategory,
                          ]}
                        />
                      ))}
                  </ProductListContainer>
                </Row>
              </Space>
            </Col>
          )}
        </Row>
      </Space>
    )
  );
};

export default LevelExplore;
