import logo_src from "../images/logo_clevercx.svg";

export default {
  color_black: "#1E1919",
  logo_src,
  sidebar_background: "#03494E",
  sidebar_icon_color_active: "#ffffff",
  sidebar_menu_item_hover_background: "#ffffff60",
  sidebar_width: 190,
};
