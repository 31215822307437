import { useEffect, useState } from "react";
import { Button, Col, Flex, Row, Space, Typography } from "antd";
import FiltersContainer from "./components/FiltersContainer";
import CardProduct from "../../../../molecule/CardProduct";

import { ReactComponent as Gear } from "../../../../icon/gear.svg";

const { Text } = Typography;

const OneAscentExplore = ({
  comparedProducts,
  handleCardClick,
  openModal,
  productActions,
  productsList,
  setCompareMode,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [accountSizeFilter, setAccountSizeFilter] = useState([]);
  const [allocationTypeFilter, setAllocationTypeFilter] = useState([]);
  const [riskToleranceFilter, setRiskToleranceFilter] = useState([]);

  const [productFiltersApplied, setProductFiltersApplied] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Set filtersApplied to true if any filters are applied
  useEffect(() => {
    if (
      searchTerm === "" &&
      accountSizeFilter.length === 0 &&
      allocationTypeFilter.length === 0 &&
      riskToleranceFilter.length === 0
    ) {
      setProductFiltersApplied(false);
    } else {
      setProductFiltersApplied(true);
    }
    setFilteredProducts(getFilteredProducts());
  }, [
    productsList,
    searchTerm,
    accountSizeFilter,
    riskToleranceFilter,
    allocationTypeFilter,
  ]);

  const handleAccountSizeFilterChange = checkedValues => {
    setAccountSizeFilter(checkedValues);
  };

  const handleAllocationTypeFilterChange = checkedValues => {
    setAllocationTypeFilter(checkedValues);
  };

  const handleRiskToleranceFilterChange = checkedValues => {
    setRiskToleranceFilter(checkedValues);
  };

  const applySearchFilter = products => {
    if (searchTerm === "") return products;
    return products?.filter(
      product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) || ""
    );
  };

  const applyFilter = (products, key, filters) => {
    if (filters.length === 0) return products;
    return products?.filter(product => filters.includes(product[key]));
  };

  const getFilteredProducts = () =>
    applyFilter(
      applyFilter(
        applyFilter(
          applySearchFilter(productsList),
          "allocationType",
          allocationTypeFilter
        ),
        "accountSize",
        accountSizeFilter
      ),
      "riskTolerance",
      riskToleranceFilter
    );

  return (
    !!productsList?.length && (
      <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <Row justify="space-between" gutter={20}>
          <Col xs={24} sm={24} md={24} lg={6}>
            <Row justify="space-between">
              <FiltersContainer
                allocationTypeFilter={allocationTypeFilter}
                riskToleranceFilter={riskToleranceFilter}
                filteredProducts={filteredProducts}
                accountSizeFilter={accountSizeFilter}
                productFiltersApplied={productFiltersApplied}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleAllocationTypeFilterChange={
                  handleAllocationTypeFilterChange
                }
                handleRiskToleranceFilterChange={
                  handleRiskToleranceFilterChange
                }
                handleAccountSizeFilterChange={handleAccountSizeFilterChange}
                productsList={productsList}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={18}>
            <Space direction="vertical">
              <Row
                align="bottom"
                justify="space-between"
                style={{
                  borderBottom: "1px solid #7ebaba",
                  marginBottom: 10,
                  paddingBottom: 8,
                }}
              >
                <Text
                  style={{
                    color: "#3e5377",
                    fontSize: 18,
                  }}
                >
                  Filtered Products ({filteredProducts?.length})
                </Text>
                <Flex align="center" gap={8}>
                  <Flex
                    className="pointer"
                    gap={8}
                    onClick={() => openModal("defaultProductAssumptionsModal")}
                  >
                    <Gear />
                    <Typography.Text>
                      Default Product Assumptions
                    </Typography.Text>
                  </Flex>
                  <Button
                    disabled={!comparedProducts?.length}
                    size="small"
                    shape="round"
                    type="primary"
                    onClick={() => setCompareMode(true)}
                  >
                    Compare Mode ({comparedProducts?.length} of 3)
                  </Button>
                </Flex>
              </Row>
              <Row>
                <Flex gap={20} wrap="wrap">
                  {filteredProducts?.map(product => (
                    <CardProduct
                      key={`filtered-list-${product._id}`}
                      product={product}
                      actions={productActions(product)}
                      handleCardClick={handleCardClick}
                      labels={[product.riskTolerance, product.allocationType]}
                    />
                  ))}
                </Flex>
              </Row>
            </Space>
          </Col>
        </Row>
      </Space>
    )
  );
};

export default OneAscentExplore;
