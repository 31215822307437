import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import State from "../../context";
import { Flex, theme } from "antd";
import MenuActions from "../../atom/MenuActions";

import { logout } from "../../utils/request/regularApp";
import {
  isPortalOneascentAdvisor,
  openProposalWithConfig,
} from "../../utils/helper/specialized";

import { faUser } from "@fortawesome/free-solid-svg-icons";

const MenuProfile = ({ logoutLink = "/login" }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [state] = useContext(State);

  const getItems = () =>
    [
      {
        key: "settings",
        label: "Settings",
        onClick: () => state.setKeyValue("openModalManagerSettings", true),
      },
      {
        hidden: !isPortalOneascentAdvisor(),
        key: "demo_mode",
        label: "Demo Mode",
        onClick: () =>
          openProposalWithConfig({
            organization: state.organization?.name ?? state.orgName,
          }),
      },
      {
        key: "back_to_launch",
        label: "Back to Launch",
        onClick: () => state.openModal("artBoardModal"),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        type: "divider",
      },
      {
        key: "log_out",
        label: "Log Out",
        onClick: () =>
          logout()
            .then(() => navigate(logoutLink))
            .catch(console.log),
      },
    ].filter(it => !it.hidden);

  return (
    state.personalInfo && (
      <MenuActions items={getItems()}>
        <Flex
          align="center"
          gap={12}
          style={{
            color: token.sidebar_profile_color,
            cursor: "pointer",
            padding: token.sidebar_menu_item_padding,
            textTransform: "capitalize",
            width: "100%",
          }}
        >
          <FontAwesomeIcon icon={faUser} style={{ fontSize: 15 }} />
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {`${state.personalInfo?.firstName} ${state.personalInfo?.lastName}`}
          </span>
        </Flex>
      </MenuActions>
    )
  );
};

export default MenuProfile;
