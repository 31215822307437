import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    height: 1590px;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  h3 {
    color: #2a2a2a;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.46px;
    margin: 0;
  }

  p {
    color: #2a2a2a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: -0.32px;
    margin: 0;
  }

  .selections-container {
    background: #e6f4ef;
    border-radius: 12px;
    padding: 24px 32px;

    .value-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label {
        color: #2a2a2a;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.36px;
      }

      .value {
        color: #2a2a2a;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: -0.4px;
        margin: 0;
      }
    }
  }

  main {
    margin: 43px 0 77px;

    .section-about-risk-tolerance {
      display: flex;
      gap: 68px;
      padding: 60px 20px;

      p {
        color: #2a2a2a;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.32px;
        margin: 0;
      }

      .risk-label {
        color: #234744;
        font-size: 13px;
        opacity: 0.4;

        &.active {
          font-size: 18px;
          opacity: 1;
        }
      }
    }

    .section-planning-corner {
      background: #e6f4ef;
      border-radius: 12px;
      color: #2a2a2a;
      padding: 24px;

      h5 {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.36px;
        margin: 0;
      }

      h3 {
        font-size: 23px;
        font-style: normal;
        line-height: 140%;
        letter-spacing: -0.46px;
        margin: 0 0 9px;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.32px;
        margin: 0;
      }
    }
  }
`;

export default StylesContainer;
