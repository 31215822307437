import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  theme,
  TreeSelect,
  Upload,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import Close from "../../../../../../icon/Close";

const fileDataDefaultState = {
  isVisibleToAdvisor: true,
  fileName: "",
};

const ModalUploadFile = ({
  marketingMaterialFolders,
  onCancel,
  onSuccess,
  open,
}) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [fileAdded, setFileAdded] = useState(false);

  useEffect(() => {
    if (open) {
      form.setFieldsValue(fileDataDefaultState);
    }
  }, [open]);

  const getTreeData = () =>
    marketingMaterialFolders
      ?.filter(folder => !folder.parentFolderId)
      .map(folder => ({
        value: folder.id,
        title: folder.name,
        children: marketingMaterialFolders
          .filter(subfolder => subfolder.parentFolderId === folder.id)
          .map(subfolder => ({
            value: subfolder.id,
            title: subfolder.name,
          })),
      }));

  const handleFileAdd = event => {
    if (event.fileList?.length) {
      setFileAdded(true);
      form.setFieldValue("fileName", event.fileList[0].name);
    }
  };

  const handleFileRemove = () => {
    setFileAdded(false);
    form.setFieldValue("fileName", "");
  };

  return (
    <Modal
      className="upload-marketing-materials-modal"
      closeIcon={<Close />}
      destroyOnClose={true}
      footer={
        <Flex justify="space-between">
          <Button onClick={onCancel} shape="round" type="text">
            Cancel
          </Button>
          <Button
            form="formUploadFile"
            htmlType="submit"
            shape="round"
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
            type="primary"
          >
            Create
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="Upload Marketing Materials"
    >
      <Form
        form={form}
        id="formUploadFile"
        layout="vertical"
        onFinish={onSuccess}
      >
        <Form.Item name="file">
          <Upload.Dragger
            name="file"
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleFileAdd}
            onRemove={handleFileRemove}
            style={{ paddingLeft: 30 }}
          >
            <Flex gap={30}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <Flex vertical>
                <p className="ant-upload-text">Drag here or</p>
                <p className="ant-upload-text">
                  <b>Browse to upload</b>
                </p>
              </Flex>
            </Flex>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          label="Display Name"
          name="fileName"
          rules={[
            {
              required: true,
              message: "File name is required",
            },
          ]}
        >
          <Input disabled={!fileAdded} placeholder="Display Name" />
        </Form.Item>
        <Form.Item
          label="Folder"
          name="folderId"
          rules={[
            {
              required: true,
              message: "Folder name is required",
            },
          ]}
        >
          <TreeSelect placeholder="Select folder" treeData={getTreeData()} />
        </Form.Item>
        <Form.Item label="Advisor Visibility" name="isVisibleToAdvisor">
          <Radio.Group>
            <Radio value={true}>Show</Radio>
            <Radio value={false}>Hide</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalUploadFile;
