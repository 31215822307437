import { Button, Flex } from "antd";

import StyledModal from "./styles";

import Close from "../../icon/Close";

const ModalArchiveManagedUser = ({
  instanceName = "client",
  loading,
  onCancel,
  onConfirm,
  open,
}) => (
  <StyledModal
    closeIcon={<Close />}
    footer={
      <Flex justify="space-between">
        <Button onClick={onCancel} shape="round" type="text">
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          shape="round"
          style={{ background: "#03494E", color: "#fff" }}
        >
          Yes, archive this {instanceName}
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
    title="Confirm archive"
    width={561}
  >
    <h3 className="title">Do you wish to archive this {instanceName}?</h3>
    <p className="description">
      They will not show up in your {instanceName}s section moving forward.
    </p>
  </StyledModal>
);

export default ModalArchiveManagedUser;
