import { Flex } from "antd";
import { listsPlugin, MDXEditor } from "@mdxeditor/editor";
import ReportHeader from "../../component/ReportHeader";
import TableMilestones from "../../../page/PageProposalDashboard/components/SectionMilestonesQuestionnaire/components/TableMilestones";

import StylesContainer from "./styles";

const ReportPageMilestones = ({
  milestonesQuestions,
  milestonesNote,
  personaName,
}) => (
  <StylesContainer id="reportPageMilestones">
    <div className="page-container">
      <ReportHeader personaName={personaName} />

      <main>
        <div>
          <h2>Milestones</h2>
          <p className="description">
            Context is key. Select the life events that apply to your current
            situation. You and your advisor will keep these Milestones in mind
            as you form a financial plan.
          </p>
        </div>

        <Flex gap={32}>
          <Flex gap={28} style={{ width: "100%" }} vertical>
            {milestonesQuestions?.filter(
              it => (it.type === "marriage" || it.type === "career") && it.value
            )?.length ? (
              <Flex vertical>
                <h4>Marriage & Career</h4>
                <TableMilestones
                  data={milestonesQuestions.filter(
                    it =>
                      (it.type === "marriage" || it.type === "career") &&
                      it.value
                  )}
                  readonly
                />
              </Flex>
            ) : (
              ""
            )}
            {milestonesQuestions?.filter(
              it => (it.type === "health" || it.type === "family") && it.value
            )?.length ? (
              <Flex vertical>
                <h4>Health & Family</h4>
                <TableMilestones
                  data={milestonesQuestions.filter(
                    it =>
                      (it.type === "health" || it.type === "family") && it.value
                  )}
                  hideTitle
                  readonly
                />
              </Flex>
            ) : (
              ""
            )}
            {milestonesQuestions?.filter(
              it =>
                (it.type === "assets" || it.type === "generosity") && it.value
            )?.length ? (
              <Flex vertical>
                <h4>Assets, Liabilities & Generosity</h4>
                <TableMilestones
                  data={milestonesQuestions.filter(
                    it =>
                      (it.type === "assets" || it.type === "generosity") &&
                      it.value
                  )}
                  hideTitle
                  readonly
                />
              </Flex>
            ) : (
              ""
            )}
          </Flex>
          <Flex vertical>
            <div className="sub-title">Advisor Notes</div>
            <MDXEditor
              className="markdown-container"
              markdown={milestonesNote ?? ""}
              plugins={[listsPlugin()]}
            />
          </Flex>
        </Flex>
      </main>
    </div>
  </StylesContainer>
);

export default ReportPageMilestones;
