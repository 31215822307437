import TabCompanies from "./components/TabCompanies";
import TabEvents from "./components/TabEvents";
import TabSponsors from "./components/TabSponsors";
import TabUsers from "./components/TabUsers";

import StyledTabs from "./styles";

const TabsAdmin = () => {
  const tabsItems = [
    {
      key: "companies",
      label: "Companies",
      children: <TabCompanies />,
    },
    {
      key: "users",
      label: "Users",
      children: <TabUsers />,
    },
    {
      key: "sponsors",
      label: "Sponsors",
      children: <TabSponsors />,
    },
    {
      key: "events",
      label: "Events",
      children: <TabEvents />,
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey="companies"
      items={tabsItems}
      tabPosition="left"
    />
  );
};

export default TabsAdmin;
