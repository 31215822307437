import { Flex, Image } from "antd";
import ChartPersonality from "../../../page/PageProposalDashboard/components/ChartPersonality";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { getListUseOfMoney } from "../../../constant";
import { EMPTY_BLIND_SPOTS_TEXT } from "../../../page/PageProposalDashboard/components/SectionPerspectiveCoupleResults/components/TabBlindSpots";
import { EMPTY_COMMON_GROUND_TEXT } from "../../../page/PageProposalDashboard/components/SectionPerspectiveCoupleResults/components/TabCommonGround";

import demo from "./image/demo.png";

const ReportPagePersonalityCommonBlind = ({
  coupleCalculations,
  personaName,
}) => {
  const commonTendencyTypes = coupleCalculations
    .filter(it => it.total > 110 && it.difference < 50)
    .map(it => it.type);

  const blindSpotsTendencyTypes = coupleCalculations
    .filter(it => it.total < 50)
    .map(it => it.type);

  return (
    <StylesContainer id="reportPagePersonalityCommonBlind">
      <div className="page-container">
        <ReportHeader personaName={personaName} />

        <main>
          <Flex gap={40} vertical>
            <Flex align="center" className="chart-container" justify="center">
              <ChartPersonality height={444} hideLegend width={537} />
            </Flex>

            <div>
              <h3>Common Ground</h3>
              <h4>What tendencies are natural for you both</h4>
              {commonTendencyTypes.length ? (
                <Flex gap={20} vertical>
                  {commonTendencyTypes.map((tendencyType, index) => {
                    const useOfMoneyData = getListUseOfMoney().find(
                      it => it.type === tendencyType
                    );

                    return (
                      <Flex key={index} vertical>
                        <h4 style={{ textTransform: "capitalize" }}>
                          <i>{tendencyType}</i>
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: useOfMoneyData?.descriptionCommonGround,
                          }}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: EMPTY_COMMON_GROUND_TEXT,
                  }}
                />
              )}
            </div>
          </Flex>

          <Flex gap={40} vertical>
            <Image preview={false} src={demo} />

            <div>
              <h3>Blind Spots</h3>
              <h4>What tendencies are natural for you both</h4>
              {blindSpotsTendencyTypes.length ? (
                <Flex gap={20} vertical>
                  {blindSpotsTendencyTypes.map((tendencyType, index) => {
                    const useOfMoneyData = getListUseOfMoney().find(
                      it => it.type === tendencyType
                    );

                    return (
                      <Flex key={index} vertical>
                        <h4 style={{ textTransform: "capitalize" }}>
                          <i>{tendencyType}</i>
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: useOfMoneyData?.descriptionBlindSpots,
                          }}
                        />
                      </Flex>
                    );
                  })}
                </Flex>
              ) : (
                <p
                  dangerouslySetInnerHTML={{ __html: EMPTY_BLIND_SPOTS_TEXT }}
                />
              )}
            </div>
          </Flex>
        </main>
      </div>
    </StylesContainer>
  );
};

export default ReportPagePersonalityCommonBlind;
