import dayjs from "dayjs";
import { Flex, Image } from "antd";

import { NewsCard } from "./styles";

const CarouselHorizontal = ({ news }) => (
  <Flex gap={21} style={{ overflow: "auto" }}>
    {news?.map(it => (
      <NewsCard gap={16} key={it.id}>
        {it.image_url && (
          <div className="image-container">
            <Image
              height="100%"
              preview={false}
              src={it.image_url}
              width="max-content"
            />
          </div>
        )}
        <Flex style={{ width: it.title.length > 60 ? 212 : 137 }} vertical>
          <div className="date">
            {dayjs(it.updated).format("MMM D, YYYY h:mma")}
          </div>
          <div className="description-container">
            <p className="description" onClick={() => window.open(it.url)}>
              {it.title}
            </p>
          </div>
          <p className="source">Source: {it.sourceName}</p>
        </Flex>
      </NewsCard>
    ))}
  </Flex>
);

export default CarouselHorizontal;
