import html2pdf from "html2pdf.js";

export const PDF_CONVERTER_OPTIONS = {
  margin: 0,
  filename: "report.pdf",
  image: { type: "jpeg", quality: 0.95 },
  enableLinks: false,
  html2canvas: {
    onclone: element => {
      const svgElements = Array.from(element.querySelectorAll("svg"));
      svgElements.forEach(s => {
        const bBox = s.getBBox();
        s.setAttribute("x", bBox.x);
        s.setAttribute("y", bBox.y);
        s.setAttribute("width", bBox.width);
        s.setAttribute("height", bBox.height);
      });
    },
    width: 1230,
  },
  jsPDF: {
    format: "letter",
  },
};

export const getClonedNode = element => {
  if (element) {
    const clonedElement = element.cloneNode(true);
    clonedElement.style.display = "block";
    return clonedElement;
  }
};

export const getHtml2pdfPromiseList = pageList => {
  let pdf = html2pdf();

  pageList.map((pageData, index) => {
    if (pageData.nodeIdCluster?.length) {
      pageData.nodeIdCluster.map((nodeId, nodeClusterIndex) => {
        const pageNode = getClonedNode(document.getElementById(nodeId));

        if (!pageNode) {
          console.log(`Page '${nodeId}' do not added`);
          return;
        }

        if (index === 0 && nodeClusterIndex === 0) {
          pdf = pdf
            .set(PDF_CONVERTER_OPTIONS)
            .from(pageNode)
            .toContainer()
            .toCanvas()
            .toPdf();
        } else {
          pdf = pdf
            .get("pdf")
            .then(pdf => pdf.addPage())
            .set(PDF_CONVERTER_OPTIONS)
            .from(pageNode)
            .toContainer()
            .toCanvas()
            .toPdf();
        }
      });
    } else {
      const pageNode = getClonedNode(document.getElementById(pageData.nodeId));

      if (!pageNode) {
        console.log(`Page '${pageData.nodeId}' do not added`);
        return;
      }

      if (index === 0) {
        pdf = pdf
          .set(PDF_CONVERTER_OPTIONS)
          .from(pageNode)
          .toContainer()
          .toCanvas()
          .toPdf();
      } else {
        pdf = pdf
          .get("pdf")
          .then(pdf => pdf.addPage())
          .set(PDF_CONVERTER_OPTIONS)
          .from(pageNode)
          .toContainer()
          .toCanvas()
          .toPdf();
      }
    }
  });

  return pdf;
};
