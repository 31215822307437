import ot_logo_dark from "../image/ot_logo_dark.svg";
import ot_logo_light from "../image/ot_logo_light.svg";

export default {
  // required camelCase to overwrite Ant Design global token
  colorPrimary: "#1B5568",

  advisor_page_dashboard_active_tab_color: "transparent",
  advisor_dashboard_page_video_link: "https://vimeo.com/947148074?share=copy",
  button_add_user_background: "#428917",
  button_ask_penny_background: "#25556F",
  button_primary_text_color: "#FFFFFF",
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#FB703A", "#296469"],
  color_black: "#2A2828",
  color_error: "#EF0000",
  color_green: "#47951d",
  color_grey_1: "#8396A6",
  color_grey_2: "#BEC8CE",
  color_grey_3: "#E7EBEE",
  color_navy: "#354A5F",
  colorPrimaryText: "#354A5F",
  color_success: "#47951D",
  color_text: "#516474",
  color_textHeading: "#354A5F",
  color_ui_text: "#516474",
  comparison_products_colors: ["#4997D1", "#004E88", "#D8D800"],
  font_family: "Inter, sans-serif",
  goals_onboarding_background: "#e3ebec",
  goals_onboarding_step_divider_line_color: "#dbe248",
  goals_onboarding_button_continue_background: "#03494E",
  goals_onboarding_button_continue_color: "#DBE247",
  goals_onboarding_button_continue_border_radius: 8,
  goals_onboarding_section_basic_info_list_marker_background: "#DBE247",
  goals_onboarding_section_risk_tolerance_button_icon_color: "#FFFFFF",
  goals_onboarding_section_risk_tolerance_button_icon_background: "#D6CEF4",
  goals_onboarding_section_risk_tolerance_button_color: "#FFFFFF",
  goals_onboarding_section_risk_tolerance_button_conservative_background:
    "#749B9E",
  goals_onboarding_section_risk_tolerance_button_moderate_background: "#4F8083",
  goals_onboarding_section_risk_tolerance_button_aggressive_background:
    "#296469",
  icon_goal_type_background: "#FFFFFF",
  icon_goal_type_color: "#1b5568",
  income_chart_with_types_contributions_color: "#1B5568",
  income_chart_with_types_navbar_button_background: "#2b41a9",
  income_chart_with_types_navbar_button_color: "#FFFFFF",
  income_chart_with_types_navbar_container_background: "#efefef",
  layout_background: "#E3EBEC",
  logo_src: ot_logo_dark,
  modal_financial_product_details_header_background: "#E6F4EF",
  overlay_background: "#FFFFFF75",
  page_login_button_submit_background: "#296469",
  page_login_logo: ot_logo_light,
  penny_send_message_button_background: "#296469",
  product_card_background: "#e6f4ef",
  proposal_copy_investment_amount: "Investment Amount",
  proposal_copy_length_of_investment: "Length of Goal",
  proposal_header_background: "#fff",
  proposal_header_padding: "33px 65px 26px 46px",
  proposal_page_dash_show_section_video: true,
  quarterly_goal_aum_color: "#6DC1C7",
  quarterly_goal_client_color: "#66AEDC",
  quarterly_goal_proposal_color: "#354A5F",
  radio_button_color: "#296469",
  section_goal_preview_button_view_goal_background: "#296469",
  sidebar_avatar_background: "#6DC1C7",
  sidebar_avatar_color: "#FFFFFF",
  sidebar_icon_color: "#BEC8CE",
  sidebar_icon_color_active: "#296469",
  sidebar_menu_item_color: "#fff",
  sidebar_menu_item_font_size: "14px",
  sidebar_menu_item_hover_background: "#60646c",
  sidebar_menu_item_padding: "9px 9px 9px 18px",
  sidebar_profile_color: "#fff",
  sidebar_width: 184,
  spin_color: "#1677ff",
  tenant_color_primary: "#428A16",
  top_products_colors: ["#296469", "#6DC1C7", "#354A5F"],
  top_products_pie_chart_pallet: ["#6CB7E0", "#BEC8CE", "#364A5F", "#72C0C7"],
};
