import { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Button,
  Col,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import InputContainer from "../../../../atom/InputContainer";
import ChartSkeleton from "../../../../atom/ChartSkeleton";

import {
  DEFAULT_BIRTH_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../utils/constant";
import { CAPITAL_MARKET_ASSUMPTIONS_LINK_RIVERSHARES } from "../../../../ecosystem/rivershares/constants";

import { getPercentValue } from "../../../../utils/helper/general";
import { getLiveStressor } from "../../../../utils/request/regularApp";
import { getGrowthChartConfig } from "../../../../utils/chart";

import { FilePdfOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Marker = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: ${props => props.color};
  margin-top: 2px;
`;

const StyledSelect = styled(Select)`
  width: 105px;

  &.ant-select .ant-select-arrow {
    color: #354a5f;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

const StyledTable = styled(Table)`
  width: 100%;

  &.ant-table-wrapper table {
    border-spacing: 0 8px;
  }

  &.ant-table-wrapper .ant-table-thead > tr > th {
    background: #fff;
    border-color: transparent;
  }

  &.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding-bottom: 0;
    padding-top: 0;
  }

  &.ant-table-wrapper .ant-table-tbody > tr {
    pointer-events: none;
  }

  & .ant-table-tbody tr {
    color: #fff;
  }

  & .ant-table-tbody tr td:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  & .ant-table-tbody tr td:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  & .ant-table-tbody tr:nth-child(1) td {
    background: #6e97b8;
  }

  & .ant-table-tbody tr:nth-child(2) td {
    background: #c6aa4e;
  }

  & .ant-table-tbody tr:nth-child(3) td {
    background: #1c3744;
  }

  & .ant-table-tbody tr:nth-child(4) td {
    background: #69b5c9;
  }

  & .ant-table-tbody tr:nth-child(5) td {
    background: #b2b36a;
  }
`;

const defaultValues = {
  optimisticDuration: 5,
  optimisticStartYear: +dayjs().format("YYYY"),
  optimisticIsOn: false,
  pessimisticDuration: 5,
  pessimisticStartYear: +dayjs().format("YYYY"),
  pessimisticIsOn: false,
};

const dataSource = [
  {
    key: "1",
    title: "US Large-Cap Stocks",
    bear: "2.6",
    bull: "11.8",
    base: "8.2",
  },
  {
    key: "2",
    title: "Developed International Stocks",
    bear: "1.0",
    bull: "14.3",
    base: "8.0",
  },
  {
    key: "3",
    title: "Emerging Market Stocks",
    bear: "0.0",
    bull: "13.5",
    base: "6.8",
  },
  {
    key: "4",
    title: "US Aggregate Bonds",
    bear: "4.4",
    bull: "3.4",
    base: "4.0",
  },
  {
    key: "5",
    title: "US High Yield Bonds",
    bear: "1.6",
    bull: "6.8",
    base: "5.6",
  },
];

const columns = [
  {
    title: "Annualized, Nominal, in USD",
    dataIndex: "title",
    key: "title",
  },
  {
    title: (
      <Space align="center">
        <Marker color="#AD5748" />
        Bear
      </Space>
    ),
    dataIndex: "bear",
    key: "bear",
    render: value => <b>{getPercentValue(value)}</b>,
  },
  {
    title: (
      <Space align="center">
        <Marker color="#888E48" />
        Bull
      </Space>
    ),
    dataIndex: "bull",
    key: "bull",
    render: value => <b>{getPercentValue(value)}</b>,
  },
  {
    title: (
      <Space align="center">
        <Marker color="#243A46" />
        Base
      </Space>
    ),

    dataIndex: "base",
    key: "base",
    render: value => <b>{getPercentValue(value)}</b>,
  },
];

const SectionCapitalMarketAssumptions = ({
  investmentAssumptions,
  liveAssessment,
  organization,
  product,
}) => {
  const [loading, setLoading] = useState(false);
  const [stressorCalculations, setStressorCalculations] = useState();
  const [forceFetchCalculations, setForceFetchCalculations] = useState(false);
  const [customValues, setCustomValues] = useState(defaultValues);

  useEffect(() => {
    fetchStressorCalculations();
  }, [product]);

  useEffect(() => {
    forceFetchCalculations &&
      setTimeout(() => fetchStressorCalculations(), 500);
  }, [forceFetchCalculations]);

  const handleValueChange = (key, value, forceFetchCalculations) => {
    setCustomValues(lastState => ({
      ...lastState,
      [key]: value,
    }));
    setForceFetchCalculations(forceFetchCalculations);
  };

  const getSeries = (series = []) => {
    liveAssessment?.grandTotalColumn &&
      series.push({
        name: "Calculated progress",
        lineWidth: 5,
        color: "#243A46",
        zoneAxis: "x",
        marker: {
          enabled: false,
        },
        data: liveAssessment.grandTotalColumn,
      });

    if (stressorCalculations?.stressor?.length) {
      if (stressorCalculations.stressor.find(it => it.name === "Bear Market")) {
        series.push({
          name: "Bear Market",
          lineWidth: 5,
          color: "#AD5748",
          zoneAxis: "x",
          marker: {
            enabled: false,
          },
          data: stressorCalculations.stressor.find(
            it => it.name === "Bear Market"
          ).data,
        });
      }

      if (stressorCalculations.stressor.find(it => it.name === "Bull Market")) {
        series.push({
          name: "Bull Market",
          lineWidth: 5,
          color: "#888E48",
          zoneAxis: "x",
          marker: {
            enabled: false,
          },
          data: stressorCalculations.stressor.find(
            it => it.name === "Bull Market"
          ).data,
        });
      }
    }

    return series;
  };

  const fetchStressorCalculations = () => {
    setLoading(true);

    if (customValues.optimisticIsOn || customValues.pessimisticIsOn) {
      const requestBody = {
        marketEvent: [
          {
            name: "Bear Market",
            percent: product.properties.growthRateBearCase,
            durationYears: customValues.pessimisticDuration,
            startYear: customValues.pessimisticStartYear,
            active: customValues.pessimisticIsOn,
          },
          {
            name: "Bull Market",
            percent: product.properties.growthRateBullCase,
            durationYears: customValues.optimisticDuration,
            startYear: customValues.optimisticStartYear,
            active: customValues.optimisticIsOn,
          },
        ],
        objective: {
          ...investmentAssumptions,
          ...product,
          birthYear: product.currentAge
            ? undefined
            : (product.birthYear ?? DEFAULT_BIRTH_YEAR),
          investmentDuration:
            product.investmentDuration ??
            +investmentAssumptions.investmentDuration,
          withdrawalLevel: product.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL,
        },
        organization: organization?.toLowerCase(),
        productId: product._id,
      };

      getLiveStressor(requestBody).then(data => {
        setStressorCalculations(data);
        setLoading(false);
        setForceFetchCalculations(false);
      });
    } else {
      setStressorCalculations(null);
      setLoading(false);
      setForceFetchCalculations(false);
    }
  };

  return (
    <Row gutter={[58, 32]}>
      <Col span={24}>
        <ChartSkeleton loading={loading} height={332}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...getGrowthChartConfig({
                birthYear: product.currentAge
                  ? dayjs().format("YYYY") - product.currentAge
                  : product.birthYear,
              }),
              series: getSeries(),
            }}
          />
        </ChartSkeleton>
      </Col>
      <Col xs={24} sm={12} md={9} lg={9}>
        <Space direction="vertical" size={16}>
          <Row gutter={16} align="middle" justify="space-between">
            <Space size={9} align="start">
              <Marker color="#AD5748" />
              <Space direction="vertical" size={0}>
                <Text
                  style={{
                    lineHeight: 1,
                    fontSize: 16,
                    color: "#2A2828",
                  }}
                >
                  Pessimistic Bear Case
                </Text>
                <Text style={{ color: "#516474" }}>
                  Capital Market Assumptions
                </Text>
              </Space>
            </Space>
            <Switch
              checked={customValues.pessimisticIsOn}
              disabled={forceFetchCalculations}
              onChange={checked =>
                handleValueChange("pessimisticIsOn", checked, true)
              }
            />
          </Row>
          <Space direction="vertical" size={16} style={{ marginLeft: 16 }}>
            <Space size={19}>
              <InputContainer labelText="Start Year">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("pessimisticStartYear", value)
                  }
                  options={new Array(50).fill(null).map((it, index) => ({
                    value: +dayjs().format("YYYY") + index,
                    label: +dayjs().format("YYYY") + index,
                  }))}
                  value={customValues.pessimisticStartYear}
                />
              </InputContainer>
              <InputContainer labelText="Duration">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("pessimisticDuration", value)
                  }
                  options={new Array(60).fill(null).map((it, index) => ({
                    value: index + 1,
                    label: `${index + 1} Years`,
                  }))}
                  value={customValues.pessimisticDuration}
                />
              </InputContainer>
            </Space>
            <Button
              type="primary"
              size="small"
              shape="round"
              style={{ width: 77 }}
              onClick={fetchStressorCalculations}
            >
              Save
            </Button>
          </Space>
        </Space>
      </Col>
      <Col xs={24} sm={12} md={9} lg={9}>
        <Space direction="vertical" size={16}>
          <Row gutter={16} align="middle" justify="space-between">
            <Space size={9} align="start">
              <Marker color="#888E48" />
              <Space direction="vertical" size={0}>
                <Text
                  style={{
                    lineHeight: 1,
                    fontSize: 16,
                    color: "#2A2828",
                  }}
                >
                  Optimistic Bull Case
                </Text>
                <Text style={{ color: "#516474" }}>
                  Capital Market Assumptions
                </Text>
              </Space>
            </Space>
            <Switch
              checked={customValues.optimisticIsOn}
              disabled={forceFetchCalculations}
              onChange={checked =>
                handleValueChange("optimisticIsOn", checked, true)
              }
            />
          </Row>
          <Space direction="vertical" size={16} style={{ marginLeft: 16 }}>
            <Space size={19}>
              <InputContainer labelText="Start Year">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("optimisticStartYear", value)
                  }
                  options={new Array(50).fill(null).map((it, index) => ({
                    value: +dayjs().format("YYYY") + index,
                    label: +dayjs().format("YYYY") + index,
                  }))}
                  value={customValues.optimisticStartYear}
                />
              </InputContainer>
              <InputContainer labelText="Duration">
                <StyledSelect
                  onChange={value =>
                    handleValueChange("optimisticDuration", value)
                  }
                  options={new Array(60).fill(null).map((it, index) => ({
                    value: index + 1,
                    label: `${index + 1} Years`,
                  }))}
                  value={customValues.optimisticDuration}
                />
              </InputContainer>
            </Space>
            <Button
              type="primary"
              size="small"
              shape="round"
              style={{ width: 77 }}
              onClick={fetchStressorCalculations}
            >
              Save
            </Button>
          </Space>
        </Space>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6}>
        <Space size={9}>
          <Marker color="#243A46" />
          <Text
            style={{
              color: "#2A2828",
              fontSize: 16,
            }}
          >
            Base Case
          </Text>
        </Space>
      </Col>
      <Row style={{ gap: 40, width: "100%" }}>
        <Col flex="auto">
          <StyledTable
            bordered={false}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
          />
        </Col>
        <Col flex="218px">
          <div
            style={{
              width: 218,
              background: "#6E97B820",
              borderRadius: 12,
              padding: "23px 19px",
              boxSizing: "border-box",
              marginTop: 42,
            }}
          >
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
              <Text style={{ color: "#214B5D" }}>
                Want to read more about RiverShare’s Capital Market assumptions?
              </Text>
              <Button
                icon={<FilePdfOutlined />}
                shape="round"
                size="small"
                type="primary"
                onClick={() =>
                  window.open(
                    CAPITAL_MARKET_ASSUMPTIONS_LINK_RIVERSHARES,
                    "_blank"
                  )
                }
              >
                Download Now
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default SectionCapitalMarketAssumptions;
