import logo from "../image/logo_legacy.svg";
import slogan from "../image/slogan_dark.svg";

export default {
  admin_pie_chart_color_primary: "#F36835",
  admin_pie_chart_color_secondary: "#BEC8CE",
  admin_pie_chart_color_tertiary: "#354A5F",
  admin_pie_chart_color_rest: "#72C0C7",
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  color_admin_analytic_value: "#131523",
  colorPrimary: "#4897D1",
  button_primary_background: "#428A16",
  radio_button_color: "#5DCA83",
  color_secondary_button: "#4897D1",
  color_secondary_button_text: "#FFFFFF",
  color_tertiary_button: "#E7EBEE",
  color_tertiary_button_text: "#516474",
  financial_product_etf_color: "#1f4789",
  logo_src: logo,
  modal_financial_product_details_header_background: "#E7EBEE",
  proposal_copy_investment_amount: "Initial Investment",
  proposal_guide_font_primary: "Museo Sans, sans-serif",
  proposal_guide_checkbox: {
    colorBorder: "#5F6060",
    colorText: "#234744",
  },
  proposal_guide_radio: {
    colorBorder: "#8396A6",
    colorPrimary: "#5DCA83",
    colorText: "#15284B",
    fontSize: 16,
    lineWidth: 2,
  },
  sidebar_background:
    "transparent linear-gradient(121deg, #15284B 0%, #337CA5 100%) left center ",
  sidebar_icon_color: "#BEC8CE",
  sidebar_icon_color_active: "#FFFFFF",
  sidebar_menu_item_hover_background: "#152A4E",
  slogan_src: slogan,
  tenant_color_primary: "#4897D1",
};
