import styled from "styled-components";
import { Layout } from "antd";

const StyledLayout = styled(Layout)`
  flex-direction: column;
  height: 100vh;

  & .ant-layout-header {
    height: fit-content;
    background: #ffffff;
    padding: 24px 46px;
  }

  & .ant-layout-content {
    padding: 0 85px 40px 40px;
  }

  &.ant-layout .ant-layout-sider {
    background: ${props => props.sidebar_background};
    background-position: left;
  }

  &.ant-layout.ant-layout-has-sider > .ant-layout,
  &.ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: auto;
    background-color: #fff;
    overflow-x: auto;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    font-weight: 500;
    color: #8093a3;
    border-bottom-color: #bec8ce;
    white-space: pre;
    background: #fff;

    &::before {
      display: none;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    color: #8093a3;
    border-bottom-color: #bec8ce;
  }
`;

export default StyledLayout;
