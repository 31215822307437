import PageProposalDashboard from "../page/PageProposalDashboard";
import PageProposalGoals from "../../proposal/page/PageProposalGoals";

export default [
  {
    path: "/oneascent-proposal/dashboard",
    Component: PageProposalDashboard,
    text: "Dash",
  },
  {
    path: "/oneascent-proposal/goals",
    Component: PageProposalGoals,
    text: "Investments",
  },
];
