import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  .value-container {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 0 16px #0000000d;
    color: #296469;
    display: flex;
    flex-direction: column;
    padding: 22px;
    width: 223px;

    b {
      font-size: 32px;
    }

    span {
      font-size: 15px;
    }
  }
`;

export default StylesContainer;
