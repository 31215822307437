import { Flex } from "antd";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { ReactComponent as Demo } from "./image/demo.svg";
import { ReactComponent as Quote } from "./image/quote.svg";
import { ReactComponent as Slogan } from "./image/slogan.svg";

const ReportPageIntroduction = ({
  managerEmail,
  managerName,
  managerPhone,
  personaName,
}) => (
  <StylesContainer id="reportPageIntroduction">
    <div className="page-container">
      <ReportHeader personaName={personaName} />

      <h2>
        Have you ever wondered how to connect your <b>faith</b> to your{" "}
        <b>wealth?</b>
      </h2>

      <Flex gap={47}>
        <Flex gap={93} vertical>
          <div className="container-description">
            <p>
              <b>We have!</b> In fact, OneAscent’s founding was born out of
              finding an answer to this crucial question.
            </p>
            <p>
              We know that when you invest you become a shareholder, or owner,
              and therefore a participant in all that the company does and
              represents.
            </p>
            <p></p>
            <p>
              Historically, pooled investment options like mutual funds make it
              difficult to know which companies you own – let alone whether
              those are businesses that you would want to be involved in. As a
              result, many faith-based investors are unknowingly investing in
              companies whose products or services do not align with their
              deepest core values.
            </p>
            <div className="container-image-quote">
              <Quote />
            </div>
            <p>
              Today’s advancements in technology finally allow investors to
              align their personal faith-based convictions with their
              investments. OneAscent’s investment committee, rigorous due
              diligence and values-based screening process provides transparency
              into every investment we make.
            </p>
            <p>
              We are pleased to introduce the OneAscent Peak Allocation Suite –
              where our investment management team, partnerships and screening
              process deliver a high conviction portfolio that we believe
              positively impacts the world.
            </p>
            <p>
              This is our values-based approach to aligning your faith with your
              wealth: <b>Investing that Elevates.</b>
            </p>
          </div>

          <Flex className="container-advisor-info" gap={20} vertical>
            <h4>Questions?</h4>
            <p>Reach out to your advisor any time.</p>
            <div>
              <h3>{managerName}</h3>
              <p>Email: {managerEmail}</p>
              {managerPhone && <p>Phone: {managerPhone}</p>}
            </div>
            <Slogan />
          </Flex>
        </Flex>

        <div>
          <Demo />
        </div>
      </Flex>
    </div>
  </StylesContainer>
);

export default ReportPageIntroduction;
