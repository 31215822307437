import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Input, Select, theme, Upload } from "antd";

import {
  AddNewTagInput,
  ResponseContainer,
  StyledModal,
  StyledTag,
} from "./styles";

import {
  CONVERSATION_COMPLEXITY_LIST,
  CONVERSATION_RELEVANCE_LIST,
  DEFAULT_TAGS_LIST,
} from "../../constants";
import {
  deleteConversation,
  getAllConversations,
  getConversationById,
  updateConversation,
} from "../../../../utils/request/penny";

import Close from "../../../../icon/Close";
import ThumbUp from "../../images/ThumbUp";
import ThumbDown from "../../images/ThumbDown";
import UploadIcon from "../../../../icon/Upload";

const getMarker = active =>
  active ? (
    <div className="status-marker active">Active</div>
  ) : (
    <div className="status-marker inactive">Inactive</div>
  );

const ModalConversationDetails = ({ open, onCancel }) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMD });
  const [state] = useContext(State);
  const [updatedConversationData, setUpdatedConversationData] = useState();
  const [newTagName, setNewTagName] = useState("");
  const [conversationDataDirty, setConversationDataDirty] = useState(false);

  useEffect(() => {
    if (open && state.activeConversationId) {
      getConversationById(state.activeConversationId).then(data => {
        setUpdatedConversationData(data);
        setConversationDataDirty(false);
      });
    }
  }, [open]);

  const updateConversationData = (key, value) => {
    setUpdatedConversationData(lastState => ({
      ...lastState,
      [key]: value,
    }));

    setConversationDataDirty(true);
  };

  const updateConversationObjectData = (object, key, value) => {
    setUpdatedConversationData(lastState => ({
      ...lastState,
      [object]: {
        ...lastState[object],
        [key]: value,
      },
    }));

    setConversationDataDirty(true);
  };

  const handleAddTag = () => {
    if (newTagName) {
      if (updatedConversationData?.tags?.includes(newTagName)) {
        state.showError("Tag already exist.");
      } else {
        setUpdatedConversationData(lastState => ({
          ...lastState,
          tags: Array.from(new Set([...(lastState.tags ?? []), newTagName])),
        }));
        setNewTagName("");
        setConversationDataDirty(true);
      }
    }
  };

  const handleDelete = () => {
    state.setKeyValue("loading", true);

    deleteConversation(updatedConversationData._id).then(() => {
      getAllConversations()
        .then(data => {
          state.setKeyValue("conversations", data);
          state.showSuccess("Conversation deleted");
          setConversationDataDirty(false);
          onCancel();
        })
        .catch(console.log)
        .finally(() => state.setKeyValue("loading", false));
    });
  };

  const handleSave = () => {
    let payload = updatedConversationData;

    if (
      updatedConversationData?.replacedResponse?.humanResponse &&
      !updatedConversationData?.replacedResponse?.replacedByEmail
    ) {
      payload.replacedResponse.replacedByEmail = state._id;
      payload.replacedResponse.timestamp = +dayjs().format("X");
    }

    if (
      payload?.responseActive === "human" &&
      !updatedConversationData?.replacedResponse?.humanResponse
    ) {
      state.showError(
        "You could not Replace Penny’s response with an empty message"
      );
      return;
    }

    state.setKeyValue("loading", true);

    updateConversation({
      id: updatedConversationData._id,
      payload,
    })
      .then(() => {
        getAllConversations()
          .then(data => {
            state.setKeyValue("conversations", data);
            state.showSuccess("Conversation data updated");
            setConversationDataDirty(false);
            onCancel();
          })
          .catch(console.log)
          .finally(() => state.setKeyValue("loading", false));
      })
      .catch(console.log);
  };

  const handleTagChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...(updatedConversationData?.tags ?? []), tag]
      : updatedConversationData?.tags.filter(it => it !== tag);
    updateConversationData("tags", nextSelectedTags);
  };

  const handleReplacedResponseChange = e => {
    updateConversationObjectData(
      "replacedResponse",
      "humanResponse",
      e.target.value
    );
  };

  const mapRates = ({ dataList, dislikeReason, isLike }) =>
    dataList?.map((it, index) => (
      <Flex gap={28} key={index}>
        <Flex gap={13}>
          <ThumbUp active={isLike} />
          <ThumbDown active={!isLike} />
        </Flex>
        <Flex vertical>
          {dislikeReason && (
            <p style={{ margin: 0 }}>Dislike reason: "{dislikeReason}".</p>
          )}
          <p style={{ margin: 0, color: token.color_black }}>
            <i>
              Rated by {it?.email},{" "}
              {dayjs(it?.timestamp).format("MM/DD/YYYY h:mma")}
            </i>
          </p>
        </Flex>
      </Flex>
    ));

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      destroyOnClose
      footer={
        <Flex justify="space-between">
          <Flex gap={8}>
            <Button
              disabled={!conversationDataDirty}
              onClick={handleSave}
              shape="round"
              style={{ width: 75 }}
              type="primary"
            >
              Save
            </Button>
            <Button onClick={onCancel} shape="round" type="text">
              Cancel
            </Button>
          </Flex>
          <Button onClick={handleDelete} shape="round" type="text">
            Delete
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={
        <Flex gap={44} wrap="wrap">
          <span className="header-title">Conversation Details</span>
          <Flex gap={10} className="header-description">
            <span>
              User:{" "}
              <span style={{ fontWeight: 500 }}>
                {updatedConversationData?.userName}
              </span>
            </span>
            <span>
              Email:{" "}
              <span style={{ fontWeight: 500 }}>
                <u>{updatedConversationData?.userEmail}</u>
              </span>
            </span>
            <span>
              Initiated:{" "}
              <span style={{ fontWeight: 500 }}>
                {dayjs(updatedConversationData?.createdAt).format(
                  "MM/DD/YYYY h:mma"
                )}
              </span>
            </span>
          </Flex>
        </Flex>
      }
      width={1167}
      zIndex={1001}
    >
      <div className="section-prompt">
        <Flex vertical>
          <p
            style={{
              fontSize: 16,
              margin: "0 0 4px",
              color: token.color_ui_text,
            }}
          >
            <b>Prompt</b>
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: updatedConversationData?.userQuery,
            }}
            style={{ margin: "0 0 14px", color: token.color_black }}
          />
          <Flex gap={8} style={{ marginBottom: 24 }} wrap="wrap">
            {Array.from(
              new Set([
                ...DEFAULT_TAGS_LIST,
                ...(updatedConversationData?.tags ?? []),
              ])
            ).map((tag, index) => (
              <StyledTag
                checked={updatedConversationData?.tags?.includes(tag)}
                key={index}
                onChange={checked => handleTagChange(tag, checked)}
              >
                {tag}
              </StyledTag>
            ))}
            <AddNewTagInput
              placeholder="+ Type a new tag…"
              onChange={e => setNewTagName(e.target.value?.trim())}
              value={newTagName}
              onBlur={handleAddTag}
            />
          </Flex>
          <Flex align="end" gap={11} wrap="wrap">
            <Flex gap={2} vertical>
              <span>Complexity</span>
              <Select
                onChange={value => updateConversationData("complexity", value)}
                options={CONVERSATION_COMPLEXITY_LIST.map(it => ({
                  value: it,
                  label: it,
                }))}
                style={{ width: 134 }}
                value={updatedConversationData?.complexity}
              />
            </Flex>
            <Flex gap={2} vertical>
              <span>Relevance</span>
              <Select
                onChange={value => updateConversationData("relevance", value)}
                options={CONVERSATION_RELEVANCE_LIST.map(it => ({
                  value: it,
                  label: it,
                }))}
                style={{ width: 134 }}
                value={updatedConversationData?.relevance}
              />
            </Flex>
            <span style={{ color: token.color_black, marginLeft: 10 }}>
              <i>
                Last updated by{" "}
                {updatedConversationData?.promptUpdatedBy?.email},{" "}
                {dayjs(
                  updatedConversationData?.promptUpdatedBy?.timestamp
                ).format("MM/DD/YYYY h:mma")}
              </i>
            </span>
          </Flex>
        </Flex>
      </div>
      <Flex align="start" gap={37} wrap={isSmallScreen ? "wrap" : "nowrap"}>
        <ResponseContainer>
          <Flex justify="space-between" style={{ marginBottom: 12 }}>
            <p
              style={{
                fontSize: 16,
                color: token.color_ui_text,
                margin: 0,
              }}
            >
              <b>Penny’s response</b> (provided by{" "}
              <span style={{ textTransform: "capitalize" }}>
                {updatedConversationData?.source === "llm"
                  ? "LLM"
                  : updatedConversationData?.source}
                )
              </span>
            </p>
            <div
              onClick={() => {
                updateConversationData("responseActive", "kendra");
              }}
            >
              {getMarker(updatedConversationData?.responseActive === "kendra")}
            </div>
          </Flex>
          <p
            style={{
              lineHeight: "19px",
              color: token.color_black,
              margin: "0 0 19px",
            }}
          >
            {updatedConversationData?.pennyResponse}
          </p>
          <p style={{ margin: "0 0 7px", color: token.color_black }}>
            Rated Answer
          </p>
          <Flex gap={8} vertical>
            {mapRates({
              dataList: updatedConversationData?.likes,
              isLike: true,
            })}
            {mapRates({
              dataList: updatedConversationData?.dislikes,
              dislikeReason: updatedConversationData?.dislikeReason,
            })}
          </Flex>
        </ResponseContainer>
        <ResponseContainer className="active">
          <Flex justify="space-between" style={{ marginBottom: 14 }}>
            <p style={{ fontSize: 16, color: token.color_ui_text, margin: 0 }}>
              <b>Replace Penny’s response with…</b>
            </p>
            <div
              onClick={() => {
                updateConversationData("responseActive", "human");
              }}
            >
              {getMarker(updatedConversationData?.responseActive === "human")}
            </div>
          </Flex>
          <Input.TextArea
            onChange={handleReplacedResponseChange}
            style={{ marginBottom: 18 }}
            value={updatedConversationData?.replacedResponse?.humanResponse}
          />
          <Flex align="center" gap={16} style={{ marginBottom: 16 }}>
            <Upload
              beforeUpload={() => false}
              maxCount={1}
              name="file"
              onChange={file => updateConversationData("file", file)}
              onRemove={() => updateConversationData("file", null)}
            >
              <Button
                icon={<UploadIcon />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 30,
                }}
              >
                Upload image...
              </Button>
            </Upload>
            <span>or</span>
            <Input
              onChange={e =>
                updateConversationObjectData(
                  "replacedResponse",
                  "fileUrl",
                  e.target.value
                )
              }
              placeholder="Image url…"
              size="large"
              style={{ fontSize: 14 }}
              value={updatedConversationData?.replacedResponse?.fileUrl}
            />
          </Flex>
          {updatedConversationData?.replacedResponse && (
            <p style={{ color: token.color_black, margin: 0 }}>
              <i>
                Updated by{" "}
                {updatedConversationData.replacedResponse?.replacedByEmail},{" "}
                {dayjs(
                  updatedConversationData.replacedResponse?.timestamp * 1000
                ).format("MM/DD/YYYY h:mma")}
              </i>
            </p>
          )}
        </ResponseContainer>
      </Flex>
    </StyledModal>
  );
};

export default ModalConversationDetails;
