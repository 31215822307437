import { Button, Modal, Row } from "antd";

import Close from "../../../../../../../../icon/Close";

const ArchiveGoalDialog = ({ open, onCancel, onConfirm }) => (
  <Modal
    closeIcon={<Close />}
    footer={
      <Row justify="space-between">
        <Button onClick={onConfirm} size="small" shape="round" type="primary">
          Confirm
        </Button>
        <Button onClick={onCancel} shape="round" size="small" type="text">
          Cancel
        </Button>
      </Row>
    }
    onCancel={onCancel}
    open={open}
    title="Archive goal"
    width={450}
  >
    <p style={{ margin: "50px 0" }}>
      Please confirm you would like to archive this goal
    </p>
  </Modal>
);

export default ArchiveGoalDialog;
