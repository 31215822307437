import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import State from "../../../../context";
import { Button, Col, Flex, Row, theme, Typography } from "antd";
import CompareCardPlaceholder from "../../../../organism/DashboardCompare/components/CompareCardPlaceholder";
import CardProduct from "../../../../molecule/CardProduct";
import ChartComparisonGrowth from "../../../../molecule/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../../../molecule/ChartIncomeWithTypes";

import { CompareCardMarker } from "./styles";

import { MAX_COMPARE_PRODUCTS } from "../../../../utils/constant";

import { getLiveAssessment } from "../../../../utils/request/regularApp";

const WorkspaceGrowthTypeCompare = ({
  exitCompareMode,
  handleCardClick,
  isCompareChartDirty,
  goalData,
  setIsCompareChartDirty,
  updateGoalProduct,
}) => {
  const [state, setState] = useContext(State);
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [productsLiveAssessment, setProductsLiveAssessment] = useState([]);

  useEffect(() => {
    if (state.compareProducts?.length) {
      if (isCompareChartDirty || !productsLiveAssessment.length) {
        setLoading(true);
        debouncedLiveAssessmentFetch();
      }
    }
  }, [isCompareChartDirty]);

  const debouncedLiveAssessmentFetch = useDebouncedCallback(async () => {
    const responses = [];

    const requestPromises = state.compareProducts.map((productId, index) => {
      const requestBody = {
        organization: "goals",
        productId: productId,
        objective: {
          ...goalData,
          investmentDuration:
            goalData.investmentDuration ?? goalData.lengthOfInvestment,
        },
      };

      return getLiveAssessment(requestBody)
        .then(data => {
          responses.push({
            productId,
            ...data,
            color:
              state.compareProducts?.length > 1 &&
              token.comparison_products_colors[index],
            shape: getComparisonLineShape(index),
          });
        })
        .catch(({ response }) => {
          state.showError(response?.data?.error ?? response?.data?.message);
        });
    });

    await Promise.all(requestPromises);

    setProductsLiveAssessment(responses);
    setLoading(false);
    setIsCompareChartDirty(false);
  }, 2000);

  const getCompareCardsPlaceholders = () => {
    const freeSlotsAmount =
      MAX_COMPARE_PRODUCTS - state.compareProducts?.length;

    return Array.apply(null, Array(freeSlotsAmount)).map((ignore, index) => (
      <CompareCardPlaceholder
        key={index}
        handleClick={() => state.openModal("otherGrowthTypesModal")}
      />
    ));
  };

  const handleCardCloseButton = productId => {
    setState(lastState => ({
      ...lastState,
      compareProducts: lastState.compareProducts.filter(it => it !== productId),
    }));
    setIsCompareChartDirty(true);
  };

  const getComparisonCardMarkerShape = index => {
    switch (index) {
      case 0:
        return "solid";
      case 1:
        return "dashed";
      case 2:
        return "dotted";
    }
  };

  const getComparisonLineShape = index => {
    switch (index) {
      case 0:
        return "Line";
      case 1:
        return "Dash";
      case 2:
        return "Dot";
    }
  };

  return (
    <Flex justify="center" style={{ paddingTop: 30 }}>
      <Flex style={{ width: "100%", maxWidth: 958 }} vertical>
        <Flex
          justify="space-between"
          style={{
            paddingBottom: 15,
            borderBottom: `1px solid ${token.tenant_color_primary}`,
          }}
        >
          <Flex vertical>
            <span style={{ color: token.color_grey_1 }}>
              Investment Objective
            </span>
            <h3
              style={{
                fontSize: 24,
                textTransform: "capitalize",
                color: token.color_navy,
                margin: 0,
              }}
            >
              Drive {goalData.investmentObjective}
            </h3>
          </Flex>
          <Button
            shape="round"
            style={{
              background: token.tenant_color_primary,
              borderColor: "transparent",
              color: "#FFFFFF",
              width: 178,
            }}
            onClick={exitCompareMode}
          >
            Exit Compare Mode
          </Button>
        </Flex>
        <Row gutter={[22, 22]} style={{ width: "100%" }}>
          <Col span={24}>
            <ChartComparisonGrowth
              height={270}
              loading={loading}
              productsList={state.productsList}
              productsLiveAssessment={productsLiveAssessment}
              proposalData={goalData}
            />
          </Col>
          <Col span={24}>
            <ChartIncomeWithTypes
              loading={loading}
              productsList={state.productsList}
              productsLiveAssessment={productsLiveAssessment}
              productData={goalData}
            />
          </Col>
        </Row>
        <Typography.Paragraph
          style={{ fontSize: 18, color: "#405377", margin: "24px 0 0" }}
        >
          Product Selections
        </Typography.Paragraph>
        <Row style={{ width: "100%", overflowX: "auto", gap: 20 }} wrap={false}>
          {state.compareProducts
            ?.map(id => state.productsList?.find(it => it._id === id))
            ?.map((product, index) => (
              <Col key={product._id}>
                <CompareCardMarker
                  color={token.comparison_products_colors[index]}
                  shape={getComparisonCardMarkerShape(index)}
                />
                <CardProduct
                  actions={[
                    {
                      buttonCopy: "Details",
                      key: "details",
                      onSelect: product => handleCardClick(product?._id),
                    },
                  ]}
                  product={product}
                  handleCardClick={handleCardClick}
                  labels={[product?.investmentPhilosophy]}
                  showCloseButton={index !== 0}
                  handleCloseButtonClick={handleCardCloseButton}
                  showSecondaryButton={true}
                  isSecondaryButtonActive={
                    state.selectedProduct === product?._id
                  }
                  handleSecondaryButtonActivate={updateGoalProduct}
                  minHeight="238px"
                />
              </Col>
            ))}
          {!state.loading && getCompareCardsPlaceholders()}
        </Row>
      </Flex>
    </Flex>
  );
};

export default WorkspaceGrowthTypeCompare;
