import logo from "../image/logo_dark.svg";
import logo_short from "../image/logo_short.svg";
import want_to_talk from "../image/want_to_talk.png";

export default {
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#233F6F",
  comparison_products_colors: ["#7908A3", "#0076FF", "#001A45"],
  goals_onboarding_background: "#EDF0F2",
  goals_onboarding_step_divider_line_color: "#233F6F",
  goals_onboarding_button_continue_background: "#233F6F",
  goals_onboarding_button_continue_color: "#ffffff",
  goals_onboarding_button_continue_border_radius: 30,
  goals_onboarding_section_basic_info_list_marker_background: "#516474",
  goals_onboarding_section_risk_tolerance_button_icon_color: "#FFFFFF",
  goals_onboarding_section_risk_tolerance_button_icon_background: "#596e93",
  goals_onboarding_section_risk_tolerance_button_color: "#FFFFFF",
  goals_onboarding_section_risk_tolerance_button_conservative_background:
    "#233F6F",
  goals_onboarding_section_risk_tolerance_button_moderate_background: "#233F6F",
  goals_onboarding_section_risk_tolerance_button_aggressive_background:
    "#233F6F",
  goals_onboarding_form_registration_button_submit_background: "#233F6F",
  goals_onboarding_form_registration_button_submit_border_radius: 30,
  goals_page_dash_all_episodes_button_background: "#233f6f",
  goals_page_dash_create_new_goals_button_background: "#233f6f",
  goals_page_dash_create_new_goals_button_color: "#FFFFFF",
  goals_page_dash_section_want_talk_button_background: "#233f6f",
  goals_page_dash_section_want_talk_button_color: "#FFFFFF",
  goals_page_dash_section_want_talk_button_copy: "Ask a Pro",
  goals_page_dash_section_want_talk_copy: `You can click the “Ask a Pro” button any time to get in touch with a financial planner.`,
  goals_page_dash_show_section_social: true,
  goals_page_goal_update_progress_button_background: "#233f6f",
  goals_page_goal_update_progress_button_color: "#FFFFFF",
  icon_goal_type_background: "#8D28CE",
  icon_goal_type_color: "#FFFFFF",
  income_chart_with_types_contributions_color: "#4997D1",
  income_chart_with_types_navbar_button_background: "#FFFFFF",
  income_chart_with_types_navbar_button_color: "#2A2828",
  income_chart_with_types_navbar_container_background: "#D9F3F7",
  logo_src: logo,
  overlay_background: "rgba(39, 90, 144, 0.8)",
  proposal_page_dash_show_section_video: false,
  proposal_page_dash_want_to_talk_image_link: want_to_talk,
  proposal_page_dash_want_to_talk_limited_content: "",
  proposal_page_dash_want_to_talk_title_content:
    "Want to talk to a<br/>Newland Planner?",
  page_login_button_submit_background: "#233F6F",
  page_login_logo: logo,
  penny_send_message_button_background: "#233f6f",
  section_goal_preview_button_view_goal_background: "#E1F1F4",
  sidebar_avatar_background: "#FFFFFF",
  sidebar_avatar_color: "#1056AA",
  sidebar_background: "#0D1238",
  sidebar_icon_color: "#9EA0AF",
  sidebar_icon_color_active: "#FFFFFF",
  sidebar_logo: logo_short,
  spin_color: "#fff",
  tenant_color_primary: "#233F6F",
};
