import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import State from "../../../../context";
import { Avatar, theme } from "antd";
import MenuActions from "../../../../atom/MenuActions";

import { logout } from "../../../../utils/request/regularApp";
import { isProposalUser } from "../../../../utils/helper/specialized";

import { UserOutlined } from "@ant-design/icons";

const MenuGoalsUser = () => {
  const { token } = theme.useToken();
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });
  const navigate = useNavigate();
  const [state] = useContext(State);

  const getItems = () =>
    [
      {
        hidden: isProposalUser(state),
        key: "profile",
        label: "Profile",
        onClick: () => state.openModal("profileSettingsModal"),
      },
      {
        hidden: isProposalUser(state),
        key: "contact",
        label: "Contact Us",
        onClick: () => state.openModal("contactUsModal"),
      },
      {
        hidden: isProposalUser(state),
        type: "divider",
      },
      {
        key: "logout",
        label: "Log Out",
        onClick: () =>
          logout()
            .then(() => navigate("/login"))
            .catch(console.log),
      },
    ].filter(it => !it.hidden);

  return (
    <>
      {state.personalInfo ? (
        <MenuActions items={isTablet ? [] : getItems()}>
          <Avatar
            onClick={() => isTablet && state.openModal("profileSettingsModal")}
            size={41}
            style={{
              backgroundColor: token.sidebar_avatar_background,
              color: token.sidebar_avatar_color,
            }}
          >
            {
              (state.personalInfo.firstName ??
                state.personalInfo.lastName ??
                "")[0]
            }
          </Avatar>
        </MenuActions>
      ) : (
        <Avatar
          size={35}
          style={{
            backgroundColor: "#ADB7C5",
            color: "#3A5078",
            cursor: "waiting",
          }}
          icon={<UserOutlined />}
        />
      )}
    </>
  );
};

export default MenuGoalsUser;
