import styled from "styled-components";
import { Modal } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-close {
      top: 28px;
      right: 43px;
    }

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-header {
      margin-bottom: 29px;
    }

    .ant-modal-content {
      padding: 33px 45px 27px 33px;
    }

    .ant-modal-footer {
      margin-top: 0;
    }
  }

  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }

  textarea.ant-input {
    padding: 20px;
  }

  .ant-switch.ant-switch-checked,
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #6073e2;
  }

  .ant-form-item .ant-form-item-label {
    padding: 0;

    > label {
      color: ${({ token }) => token.color_grey_1};
    }
  }
`;

export default StyledModal;
