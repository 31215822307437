import EmergencySavingsIcon from "../../../icon/goal_type/EmergencySavingsIcon";
import GrowRetirementSavingsIcon from "../../../icon/goal_type/GrowRetirementSavingsIcon";
import OtherInvestmentIcon from "../../../icon/goal_type/OtherInvestmentIcon";
import RetirementIncomeIcon from "../../../icon/goal_type/RetirementIncomeIcon";
import {
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../utils/helper/specialized";

export const getGoalTypeIcon = ({ goalName, token }) => {
  switch (goalName) {
    case "Retirement income":
      return (
        <RetirementIncomeIcon
          background={token.icon_goal_type_background}
          color={token.icon_goal_type_color}
        />
      );
    case "Emergency Savings":
      return (
        <EmergencySavingsIcon
          background={token.icon_goal_type_background}
          color={token.icon_goal_type_color}
        />
      );
    case "Grow Retirement Savings":
      return (
        <GrowRetirementSavingsIcon
          background={token.icon_goal_type_background}
          color={token.icon_goal_type_color}
        />
      );
    default:
      return (
        <OtherInvestmentIcon
          background={token.icon_goal_type_background}
          color={token.icon_goal_type_color}
        />
      );
  }
};

export const getGoalsAppOrganization = organization => {
  if (isOrganizationOneascent(organization)) return organization;
  else if (isOrganizationRivershares(organization)) return organization;
  else if (isOrganizationBluepoint(organization)) return organization;
  else if (isOrganizationAstor(organization)) return organization;
  else return "goals";
};

export const getSavedProgressValuesByGoalId = (goalId, productScenariosMap) =>
  productScenariosMap[goalId]
    ? productScenariosMap[goalId][
        productScenariosMap[goalId].length - 1
      ].progress?.map((it, index) => ({
        ...it,
        id: index,
      }))
    : [];
