import { Button, Flex, Modal, theme, Typography } from "antd";

import Close from "../../../../../../icon/Close";

const ModalDeleteFile = ({ activeFileData, open, onCancel, onSuccess }) => {
  const { token } = theme.useToken();

  return (
    <Modal
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between">
          <Button type="text" shape="round" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            onClick={onSuccess}
            shape="round"
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
            type="primary"
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="Confirm"
    >
      <div style={{ textAlign: "center", padding: 40 }}>
        <Typography.Paragraph>
          Are you sure you want to delete the file{" "}
          <b>“{activeFileData?.name}”</b>?
        </Typography.Paragraph>
        <Typography.Paragraph>
          This action cannot be undone.
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ModalDeleteFile;
