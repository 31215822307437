import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button, Col, Flex, Form, InputNumber, Radio, Row, theme } from "antd";
import CardProduct from "../../../../../../molecule/CardProduct";
import Header from "../Header";
import SliderGoalFunded from "../../../../components/SliderGoalFunded";

import {
  cleanCurrencyValue,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
  selectValue,
} from "../../../../../../utils/helper/general";
import { getEmergencySavingsCalculations } from "./calculations";
import { isLockedByAdminView } from "../../../../../../utils/helper/specialized";

const Container = styled.div`
  min-width: 790px;

  .ant-form-item {
    margin: unset;

    .ant-form-item-label {
      padding-bottom: 4px;

      > label {
        line-height: 16px;
        font-size: 14px;
        color: #516474;

        &::after {
          display: none;
        }
      }
    }
  }

  .ant-input-number {
    width: 100%;
    background: #ebf1f3;
    border-color: transparent;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .ant-radio-button-wrapper::before {
      display: none;
    }

    .ant-radio-button-wrapper {
      width: 100%;
      background: #ebf1f3;
      color: #2a2828;
      border-radius: 25px;
      border-color: transparent;
      text-align: center;

      &.ant-radio-button-wrapper-checked {
        background: #292828;
        color: #ffffff;
        z-index: 0;
      }
    }
  }

  .section-header {
    display: flex;
    align-items: end;
    justify-content: space-between;
    line-height: 32px;
    padding-bottom: 11px;
    border-bottom: 3px solid #e7ebee;
    margin-bottom: 18px;

    .title {
      font-size: 18px;
      color: #4b4747;
    }
  }

  .chart-type-buttons-container {
    width: fit-content;
    background: #d9f3f7;
    color: #2a2828;
    border-radius: 25px;
    padding: 4px 5px;

    button {
      height: 24px;
      background: transparent;
      border-color: transparent;
      border-radius: 25px;
      cursor: pointer;

      &.active {
        background: #ffffff;
      }
    }
  }

  .analytics-section {
    background-color: #ebf1f3;
    padding: 24px;
    border-radius: 12px;

    .label {
      max-width: 70px;
      color: #516474;
    }

    .value {
      font-size: 22px;
      font-weight: bold;
      color: #03494e;
    }
  }
`;

const WorkspaceEmergencySavings = ({
  goalData,
  handleCardClick,
  saveGoalSettings,
  setIsDeleteModalOpen,
  state,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [chartView, setChartView] = useState("progress");
  const {
    goalCreationTimestamp,
    goalFundedValue,
    progressChartConfig,
    projectionChartConfig,
    shortfallValue,
    targetAmount,
  } = getEmergencySavingsCalculations({ goalData, state });

  useEffect(() => {
    form.setFieldsValue({
      ...goalData,
      contributions: goalData.contributions / 12,
    });
  }, []);

  const handleGoalDataChange = useDebouncedCallback(values => {
    if (values.contributions) {
      saveGoalSettings({
        ...goalData,
        ...values,
        contributions: values.contributions * 12,
      });
    } else {
      saveGoalSettings({
        ...goalData,
        ...values,
      });
    }
  }, 800);

  return (
    <Container token={token}>
      <Header
        goalData={goalData}
        progressLabel={shortfallValue > 0 ? "Off track!" : "On track!"}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        state={state}
      />
      <Row gutter={48} style={{ marginTop: 38 }} wrap={false}>
        <Col flex="225px">
          <div className="section-header">
            <span className="title">Goal Settings</span>
          </div>
          <Form
            form={form}
            id="emergencySavingsAssumptionsForm"
            layout="vertical"
            onValuesChange={handleGoalDataChange}
          >
            <Form.Item
              label="Your current monthly expenses"
              name="monthlyExpenses"
            >
              <InputNumber
                step={1000}
                min={0}
                max={10000000}
                formatter={getCurrencyValue}
                parser={cleanCurrencyValue}
                onClick={selectValue}
              />
            </Form.Item>
            <div
              style={{
                marginBottom: 25,
              }}
            >
              <u
                className="pointer"
                onClick={() => state.openModal("monthlyExpensesModal")}
                style={{
                  fontWeight: 500,
                  color: token.color_black,
                }}
              >
                Help me calculate
              </u>
            </div>

            <Form.Item
              label="How long do you want the savings to last?"
              name="investmentDuration"
              style={{ marginBottom: 25 }}
            >
              <Radio.Group>
                <Radio.Button value={0.25}>3 month</Radio.Button>
                <Radio.Button value={0.5}>6 month</Radio.Button>
                <Radio.Button value={1}>12 month</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Initial savings"
              name="investmentAmount"
              style={{ marginBottom: 20 }}
            >
              <InputNumber
                step={1000}
                min={0}
                max={10000000}
                formatter={getCurrencyValue}
                parser={cleanCurrencyValue}
                onClick={selectValue}
              />
            </Form.Item>
            <Form.Item
              label="Savings each month until goal is reached"
              name="contributions"
            >
              <InputNumber
                step={1000}
                min={0}
                max={10000000}
                formatter={getCurrencyValue}
                parser={cleanCurrencyValue}
                onClick={selectValue}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col flex="auto">
          <div className="section-header">
            <Flex gap={12}>
              <span className="title">Goal Details</span>
              <span style={{ fontSize: 12 }}>
                (Goal created:{" "}
                {dayjs(goalCreationTimestamp).format("MM/DD/YYYY")})
              </span>
            </Flex>
            <Button
              disabled={
                state.loading ||
                isLockedByAdminView({ managerAccess: state.managerAccess })
              }
              id="openUpdateProgressModalButton"
              shape="round"
              size="small"
              style={{
                width: 149,
                background:
                  token.goals_page_goal_update_progress_button_background,
                color: token.goals_page_goal_update_progress_button_color,
              }}
              onClick={() => state.openModal("updateProgressModal")}
            >
              Update Progress
            </Button>
          </div>
          <Row gutter={12} wrap={false}>
            <Col flex="auto">
              <div className="chart-type-buttons-container">
                <button
                  className={chartView === "projection" ? "active" : ""}
                  onClick={() => setChartView("projection")}
                >
                  Goal Projection
                </button>
                <button
                  className={chartView === "progress" ? "active" : ""}
                  onClick={() => setChartView("progress")}
                >
                  Goal Progress
                </button>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                options={
                  chartView === "progress"
                    ? progressChartConfig
                    : projectionChartConfig
                }
              />
            </Col>
            <Col flex="234px">
              <div className="analytics-section">
                <SliderGoalFunded
                  customGoalFundedValue={goalFundedValue}
                  goalData={goalData}
                  style={{ marginBottom: 26 }}
                />
                <Flex justify="space-between" style={{ marginBottom: 30 }}>
                  <span className="label">Target Amount</span>
                  <span className="value">
                    {getCurrencyValueWithRoundSign(targetAmount)}
                  </span>
                </Flex>
                <Flex justify="space-between">
                  <span
                    className="label"
                    style={{ color: token.color_ui_text }}
                  >
                    To reach your goal
                  </span>
                  <span className="value">
                    {getCurrencyValueWithRoundSign(shortfallValue)}
                  </span>
                </Flex>
              </div>
            </Col>
          </Row>
          <p style={{ fontSize: 18, color: "#4B4747", margin: "0 0 10px" }}>
            Account Options
          </p>
          <CardProduct
            actions={[
              {
                buttonCopy: "Details",
                buttonWidth: 165,
                key: "viewDetails",
                onSelect: product => handleCardClick(product?._id),
              },
            ]}
            handleCardClick={handleCardClick}
            labels={[
              state.productsList?.find(it => it._id === state.selectedProduct)
                ?.investmentPhilosophy,
            ]}
            product={state.productsList?.find(
              it => it._id === state.selectedProduct
            )}
            showChart
            showObjective
            width="567px"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default WorkspaceEmergencySavings;
