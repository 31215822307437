import { useContext, useEffect, useState } from "react";
import State from "../../../../context/admin/State";
import {
  Button,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputNumber,
  Switch,
} from "antd";
import ModalPrimary from "../ModalPrimary";

import { addCompany, updateCompany } from "../../../../requests/global";
import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../../../utils/helper/general";

const defaultValues = {
  aumAnnually: "",
  aumQuarterly: "",
  name: "",
  url: "",
  isMmiMember: false,
};

const ModalCompany = ({ open }) => {
  const [form] = Form.useForm();
  const [state] = useContext(State);
  const [loading, setLoading] = useState(false);
  const isEditCompanyView = !!state.activeCompanyId;

  useEffect(() => {
    if (open) {
      if (state.activeCompanyId) {
        form.setFieldsValue({
          ...defaultValues,
          ...state.companies.find(it => it._id === state.activeCompanyId),
        });
      } else {
        form.setFieldsValue(defaultValues);
      }
    }
  }, [open]);

  const handleClose = () => {
    state.closeModal("companyModal");
    state.setKeyValue("activeCompanyId", null);
  };

  const onFinish = values => {
    setLoading(true);

    if (isEditCompanyView) {
      updateCompany({
        companyId: state.activeCompanyId,
        payloadData: values,
      })
        .then(() => {
          state.reFetch.companies().then(() => {
            state.showSuccess("Company data updated.");
            handleClose();
          });
        })
        .catch(error => {
          state.showWarning((error?.response?.data?.error ?? [])[0].message);
        })
        .finally(() => setLoading(false));
    } else {
      addCompany(values)
        .then(() => {
          state.showSuccess("Company added.");
          handleClose();
          state.reFetch.companies();
        })
        .catch(error => {
          state.showWarning((error?.response?.data?.error ?? [])[0].message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <ModalPrimary
      destroyOnClose={true}
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round" size="large" type="text">
            Cancel
          </Button>
          <Button
            disabled={loading}
            form="mmiCompanySettings"
            htmlType="submit"
            shape="round"
            size="large"
            style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
          >
            Save Company
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={isEditCompanyView ? "Edit company" : "Add company"}
      width={492}
    >
      <ConfigProvider
        theme={{
          components: {
            Switch: {
              colorPrimary: "#00AEEF",
              colorPrimaryHover: "#00AEEF",
            },
          },
        }}
      >
        <Form
          id="mmiCompanySettings"
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item label="Company Name" name="name">
            <Input onClick={selectValue} />
          </Form.Item>
          <Form.Item label="Company URL" name="url">
            <Input onClick={selectValue} />
          </Form.Item>
          <Flex gap={16} style={{ width: "100%" }}>
            <Form.Item
              label="AUM - Quarterly"
              name="aumQuarterly"
              style={{ width: "100%" }}
            >
              <InputNumber
                formatter={getCurrencyValue}
                onClick={selectValue}
                parser={cleanCurrencyValue}
                step={1000}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label="AUM - Annually"
              name="aumAnnually"
              style={{ width: "100%" }}
            >
              <InputNumber
                formatter={getCurrencyValue}
                onClick={selectValue}
                parser={cleanCurrencyValue}
                step={1000}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Flex>
          <Form.Item
            label="MMI Member"
            name="isMmiMember"
            valuePropName="checked"
          >
            <Switch size="small" />
          </Form.Item>
        </Form>
      </ConfigProvider>
    </ModalPrimary>
  );
};

export default ModalCompany;
