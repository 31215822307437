import { Space } from "antd";

import { Checkbox, GoalContainer, GoalDescription, GoalName } from "./styles";

import GoldIncrease from "./images/gold_increase.png";
import GoldDecrease from "./images/gold_decrease.png";

const WhatIsYourGoalSection = ({ proposalData, updateProposalData }) => (
  <>
    <h3 className="title">What Is Your Goal?</h3>
    <p className="description">
      Financial goals provide structure and purpose to the resources and money
      we allocate to investment products in order to achieve financial freedom
      in retirement, pay for a child’s college tuition, or support a local
      charity.
    </p>
    <GoalContainer size={30} style={{ marginBottom: 30 }}>
      <Checkbox
        className={
          proposalData.investmentObjective === "Accumulate" && "active"
        }
        onClick={() => updateProposalData("investmentObjective", "Accumulate")}
      />
      <Space direction="vertical" size={0}>
        <img src={GoldIncrease} alt="Gold increase" />
        <GoalName>Accumulate</GoalName>
      </Space>
      <GoalDescription>
        You must accumulate wealth over time in order to achieve your financial
        goals and balance your future obligations. A successful strategy will
        outline the mandatory commitments, future contributions and investment
        plan required to meet your accumulation goals.
      </GoalDescription>
    </GoalContainer>
    <GoalContainer size={30}>
      <Checkbox
        className={
          proposalData.investmentObjective === "Distribute" && "active"
        }
        onClick={() => updateProposalData("investmentObjective", "Distribute")}
      />
      <Space direction="vertical" size={0}>
        <img src={GoldDecrease} alt="Gold decrease" />
        <GoalName>Distribute</GoalName>
      </Space>
      <GoalDescription>
        Investors must balance current resources and future obligations to meet
        their distribution goals. The risk of running out of money is often
        prioritized over other goals during retirement. Successful distribution
        plans help match income generated from stocks, bonds, and other sources
        of income to meet your future needs.
      </GoalDescription>
    </GoalContainer>
  </>
);

export default WhatIsYourGoalSection;
