import { Space, theme, Typography } from "antd";

const AnalyticValue = ({ label, value }) => {
  const { token } = theme.useToken();

  return (
    <Space direction="vertical" size={0}>
      {label && (
        <Typography.Text style={{ textTransform: "capitalize" }}>
          {label}
        </Typography.Text>
      )}
      <Typography.Text
        strong
        style={{
          lineHeight: "29px",
          fontSize: 22,
          color: token.color_admin_analytic_value,
          textTransform: "capitalize",
        }}
      >
        {value}
      </Typography.Text>
    </Space>
  );
};

export default AnalyticValue;
