import { useNavigate } from "react-router-dom";
import { Button, Flex, theme } from "antd";

import { getGoalTypeIcon } from "../../../../helpers";
import { isLockedByAdminView } from "../../../../../../utils/helper/specialized";

import Garbage from "../../../../../../icon/Garbage";
import Close from "../../../../../../icon/Close";

const Header = ({ goalData, progressLabel, setIsDeleteModalOpen, state }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const handleDeleteClick = () => {
    if (Object.keys(state.getPreferenceValue("productMap")).length === 1) {
      state.showError("You cannot delete your only goal!");
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  return (
    <Flex align="end" className="header" justify="space-between">
      <Flex gap={13}>
        {getGoalTypeIcon({ goalName: goalData.goalName, token })}
        <Flex vertical>
          <div style={{ fontSize: 22, color: token.color_navy }}>
            {goalData.goalName ?? goalData.productName}
          </div>
          <div style={{ fontSize: 15, color: token.color_navy }}>
            {progressLabel}
          </div>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Button
          disabled={isLockedByAdminView({
            managerAccess: state?.managerAccess,
          })}
          icon={<Garbage />}
          id="deleteGoalButton"
          onClick={handleDeleteClick}
          shape="circle"
          size="small"
          style={{ background: "#E6F4EF", width: 32 }}
          type="text"
        />
        <Button
          icon={<Close />}
          id="closeGoalButton"
          onClick={() => navigate(-1)}
          shape="circle"
          size="small"
          style={{ background: "#E6F4EF", width: 32 }}
          type="text"
        />
      </Flex>
    </Flex>
  );
};

export default Header;
