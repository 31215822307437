import styled from "styled-components";
import { Row } from "antd";

import mask from "../../images/mask.svg";

const StylesContainer = styled(Row)`
  box-sizing: border-box;
  height: 100%;
  overflow: auto;

  .title {
    color: #0e2a47;
    font-family: Palatino, sans-serif;
    font-size: 65px;
    font-weight: bold;
    line-height: 70px;
    margin: 0 0 10px;
  }

  .description {
    color: #111111;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin: 0 0 50px;
  }

  .steps-container {
    min-width: 850px;
    overflow: auto;
    padding: 32px;
  }

  & * {
    font-family: ${props => props.font_family};
  }

  .left-side-container {
    background: url(${mask}) #4b9cd3;
    height: 100%;
  }

  .step-marker {
    border: 1px solid #fff;
    border-radius: 3px;
    box-sizing: border-box;
    height: 11px;
    width: 64px;
  }
`;

export default StylesContainer;
