import { Button, Flex, Modal } from "antd";

const ModalResetUserPassword = ({
  handleResetUserPassword,
  onCancel,
  open,
}) => (
  <Modal
    footer={
      <Flex justify="space-between">
        <Button onClick={onCancel} shape="round" size="large" type="text">
          Cancel
        </Button>
        <Button
          onClick={handleResetUserPassword}
          shape="round"
          size="large"
          style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
        >
          Reset
        </Button>
      </Flex>
    }
    open={open}
    onCancel={onCancel}
    title="Reset Password"
  >
    <div style={{ margin: "40px 0" }}>
      Are you sure you want to reset this password?
    </div>
  </Modal>
);

export default ModalResetUserPassword;
