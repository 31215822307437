import { useContext } from "react";
import State from "../../../../context";
import { Flex, Slider } from "antd";

import {
  getGoalFundedPercent,
  getGoalIdByGoalName,
} from "../../../../utils/helper/specialized";

const SliderGoalFunded = ({
  customGoalFundedValue,
  goalData,
  calculationsData,
  style = {},
}) => {
  const [state] = useContext(State);

  const goalId = getGoalIdByGoalName(
    state.getPreferenceValue("productMap"),
    goalData.goalName ?? goalData.productName
  );

  const goalFundedPercent =
    customGoalFundedValue ??
    getGoalFundedPercent({
      goalData,
      calculationsData,
      scenarioData:
        state.productScenariosMap[goalId] &&
        state.productScenariosMap[goalId][
          state.productScenariosMap[goalId].length - 1
        ],
    });

  return (
    <div style={style}>
      <Flex
        justify="space-between"
        style={{ color: "#25556F", marginBottom: 6 }}
      >
        <span>Goal funded</span>
        <span>
          <b>{goalFundedPercent}%</b>
        </span>
      </Flex>
      <Slider
        min={0}
        max={100}
        value={goalFundedPercent}
        trackStyle={{
          height: 15,
          background: "#749B9E",
          borderRadius: 35,
          cursor: "default",
        }}
        railStyle={{
          height: 15,
          background: "#E7EBEE",
          borderRadius: 10,
          boxShadow: "inset 0 2px 3px #00000029",
          cursor: "default",
        }}
        style={{ margin: 0 }}
        handleStyle={{ display: "none" }}
      />
    </div>
  );
};

export default SliderGoalFunded;
