import { Button, Flex, Modal } from "antd";

const ModalDeleteUser = ({ open, onCancel, handleDeleteUser }) => (
  <Modal
    footer={
      <Flex justify="space-between">
        <Button onClick={onCancel} shape="round" size="large" type="text">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteUser}
          shape="round"
          size="large"
          style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
        >
          Delete
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
    title="Delete User"
    width={492}
  >
    <div style={{ margin: "40px 0" }}>
      Are you sure you want to delete this user?
    </div>
  </Modal>
);

export default ModalDeleteUser;
