import { useEffect } from "react";
import {
  Button,
  ConfigProvider,
  Flex,
  Form,
  InputNumber,
  Radio,
  Select,
  theme,
} from "antd";

import StyledForm from "./styles";

import {
  DEFAULT_ADVISOR_FEE,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../../../utils/constant";
import { PROPOSAL_GOAL_TYPE_LEVEL } from "../../../../../level/constants";
import { PROPOSAL_GOAL_TYPE_RIVERSHARES } from "../../../../../rivershares/constants";
import { PROPOSAL_GOAL_TYPE_ONEASCENT } from "../../../../../oneascent/constant";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../../../../../utils/helper/general";
import {
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationAstor,
  isOrganizationGoals,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../../../../utils/helper/specialized";

import { ReactComponent as TargetAmount } from "../../../../../../icon/target_marker.svg";

const InvestmentAssumptions = ({
  handleProposalDataChange,
  managerAccess,
  openGoalSettingsModal,
  organization,
  proposalData,
}) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  useEffect(() => {
    if (form && proposalData) {
      form.setFieldsValue({
        ...proposalData,
        withdrawalLevel: cleanPercentValue(
          proposalData.withdrawalLevel ?? DEFAULT_WITHDRAWAL_LEVEL
        ),
      });
    }
  }, [proposalData]);

  const getRadioOptions = () => {
    let optionsData = PROPOSAL_GOAL_TYPE_LEVEL;

    if (isOrganizationRivershares(organization)) {
      optionsData = PROPOSAL_GOAL_TYPE_RIVERSHARES;
    } else if (isOrganizationOneascent(organization)) {
      optionsData = PROPOSAL_GOAL_TYPE_ONEASCENT;
    }

    return optionsData.map(({ value, label }) => (
      <Radio key={value} value={value}>
        {label}
      </Radio>
    ));
  };

  const handleInitialInvestmentChange = e => {
    const cleanValue = cleanCurrencyValue(e.target?.value);
    const validatedValue =
      cleanValue > 10000000 ? 10000000 : cleanValue < 0 ? 0 : cleanValue;

    if (!isNaN(validatedValue)) {
      handleProposalDataChange({
        ...proposalData,
        investmentAmount: +validatedValue,
      });

      e.target.value = getCurrencyValue(validatedValue);
    }
  };

  const handleFormChange = (_, allValues) => {
    handleProposalDataChange(allValues);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: "#ebf1f3",
            colorBorder: "#ebf1f3",
            colorText: "#354a5f",
            optionSelectedBg: "#ebf1f3",
          },
        },
      }}
    >
      <StyledForm
        layout="vertical"
        form={form}
        onValuesChange={handleFormChange}
      >
        <Flex gap={16} vertical>
          <Form.Item
            hidden={isOrganizationGoals(organization)}
            name="investmentObjective"
          >
            <Radio.Group>
              <Flex gap={4} vertical>
                {getRadioOptions()}
              </Flex>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            hidden={!isOrganizationAstor(organization)}
            label="Portfolio Template"
            name="riskTolerance"
          >
            <Select
              options={[
                {
                  label: "20/80 Income",
                  value: "income",
                },
                {
                  label: "40/60 Conservative",
                  value: "conservative",
                },
                {
                  label: "60/40 Moderate",
                  value: "moderate",
                },
                {
                  label: "80/20 Aggressive",
                  value: "aggressive",
                },
                {
                  label: "100 Equity",
                  value: "equity",
                },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            hidden={!isOrganizationAstor(organization)}
            label="Product Allocation"
            name="allocation"
          >
            <Select
              options={[
                {
                  label: "100%",
                  value: 100,
                },
                {
                  label: "90%",
                  value: 90,
                },
                {
                  label: "80%",
                  value: 80,
                },
                {
                  label: "70%",
                  value: 70,
                },
                {
                  label: "60%",
                  value: 60,
                },
                {
                  label: "50%",
                  value: 50,
                },
                {
                  label: "40%",
                  value: 40,
                },
                {
                  label: "30%",
                  value: 30,
                },
                {
                  label: "20%",
                  value: 20,
                },
                {
                  label: "10%",
                  value: 10,
                },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            label={token.proposal_copy_investment_amount}
            name="investmentAmount"
          >
            <InputNumber
              step={1000}
              min={0}
              max={10000000}
              style={{ width: "100%" }}
              formatter={getCurrencyValue}
              parser={cleanCurrencyValue}
              onClick={selectValue}
              onKeyUp={handleInitialInvestmentChange}
            />
          </Form.Item>
          <Form.Item label="Length of Investment" name="investmentDuration">
            {isOrganizationOneascent(organization) ? (
              <InputNumber
                formatter={value => (value ? `${value} years` : "")}
                min={0}
                max={80}
                onClick={selectValue}
                parser={value => value?.replace(" years", "")}
                style={{ width: "100%" }}
              />
            ) : (
              <InputNumber
                addonAfter={
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Select
                      options={[
                        {
                          label: "Years",
                          value: "years",
                        },
                        {
                          disabled: true,
                          label: "Quarters",
                          value: "quarters",
                        },
                        {
                          disabled: true,
                          label: "Months",
                          value: "months",
                        },
                      ]}
                      style={{ width: 120 }}
                      value="years"
                    />
                  </Form.Item>
                }
                min={0}
                max={80}
                style={{ width: "100%" }}
                onClick={selectValue}
              />
            )}
          </Form.Item>
          <Form.Item
            hidden={isGrowthTypeRiskTolerance(
              proposalData?.investmentObjective
            )}
            label="Investment Withdrawal in Retirement"
            name="withdrawalLevel"
          >
            <InputNumber
              min={0}
              max={100}
              formatter={value => (value ? `${value}%` : "")}
              parser={value => value.replace("%", "")}
              style={{ width: "100%" }}
              onClick={selectValue}
            />
          </Form.Item>
          <Form.Item
            hidden={isGrowthTypeRiskTolerance(
              proposalData?.investmentObjective
            )}
            label="Begin Withdrawal after"
            name="yearToStartWithdrawals"
          >
            <InputNumber
              min={1}
              max={80}
              formatter={value => (value ? `${value} years` : "")}
              parser={value => value?.replace(" years", "")}
              style={{ width: "100%" }}
              onClick={selectValue}
            />
          </Form.Item>
          <Flex align="start" gap={4}>
            <Flex vertical>
              <span style={{ color: token.color_ui_text }}>
                {isGrowthTypeRiskTolerance(proposalData?.investmentObjective)
                  ? "Target Amount"
                  : "End Target Amount"}
              </span>
              <b style={{ color: token.color_black }}>
                {getCurrencyValue(proposalData?.targetAmount)}
              </b>
            </Flex>
            <TargetAmount style={{ marginTop: 4 }} />
          </Flex>
          {isGrowthTypeRiskTolerance(proposalData?.investmentObjective) && (
            <Flex vertical>
              <span style={{ color: token.color_ui_text }}>
                Monthly Contributions
              </span>
              <b style={{ color: token.color_black }}>
                {getCurrencyValue(Math.round(proposalData?.contributions / 12))}
              </b>
            </Flex>
          )}
          <Flex vertical>
            <span style={{ color: token.color_ui_text }}>Risk Tolerance</span>
            <b
              style={{ color: token.color_black, textTransform: "capitalize" }}
            >
              {proposalData?.riskTolerance}
            </b>
          </Flex>
          <Flex vertical>
            <span style={{ color: token.color_ui_text }}>Advisor Fee</span>
            <b style={{ color: token.color_black }}>
              {getPercentValue(proposalData?.advisorFee ?? DEFAULT_ADVISOR_FEE)}
            </b>
          </Flex>
          <Button
            block
            disabled={isLockedByAdminView({ managerAccess })}
            onClick={openGoalSettingsModal}
            shape="round"
            size="small"
            style={{ background: "#354A5F", color: "#FFFFFF" }}
            type="text"
          >
            Edit
          </Button>
        </Flex>
      </StyledForm>
    </ConfigProvider>
  );
};

export default InvestmentAssumptions;
