import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../context/user/State";
import { Button, Flex, Form, Input } from "antd";

import StylesContainer from "./styles";

import { DEFAULT_PASSWORD } from "../../../../constants";

import IdentityManager from "../../../../requests/aws/IdentityManager";
import {
  getUserDataByEmail,
  updateUser,
  updateUserEmail,
} from "../../../../requests/global";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";
import { ReactComponent as Eyeball } from "../../../../images/eyeball.svg";
import { ReactComponent as EyeballOff } from "../../../../images/eyeball_off.svg";

const PageSettingsSecurity = () => {
  const navigate = useNavigate();
  const [state] = useContext(State);
  const [form] = Form.useForm();
  const [loading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      firstName: state.user?.firstName,
      lastName: state.user?.lastName,
      email: state.user?.email,
    });
  }, []);

  const handleClose = () => navigate("/mmi/dashboard");

  const onFinish = ({
    email,
    firstName,
    lastName,
    newPassword,
    oldPassword,
    repeatNewPassword,
  }) => {
    if (newPassword !== repeatNewPassword) {
      state.showWarning("The new password that you entered do not match!");
      return;
    }

    if (oldPassword && !newPassword) {
      state.showWarning("Need to provide new password!");
      return;
    }

    if (state.user?.email !== email) {
      // Cognito do not allow email updating. We make deep copy of the old account with new email.
      updateUserEmail({
        userId: state.user._id,
        newEmail: email,
      })
        .then(() => {
          state.setKeyValue("showOverlay", true);
          state.showSuccess(
            "Email changed. Please, create the password and log in."
          );

          setTimeout(() => {
            navigate(
              `/login-mmi?email=${email}&oldPassword=${DEFAULT_PASSWORD}`
            );
          }, 3000);
        })
        .catch(console.log);

      // do not handle other "user" data change in the same time with "email" updating
      return;
    }

    if (
      state.user?.firstName !== firstName ||
      state.user?.lastName !== lastName
    ) {
      updateUser({
        userId: state.user._id,
        payloadData: {
          firstName,
          lastName,
        },
      })
        .then(() => {
          getUserDataByEmail(state.user?.email).then(data => {
            state.setKeyValue("user", data);
            state.showSuccess("User private data updated.");
            handleClose();
          });
        })
        .catch(({ message }) => state.showWarning(message));
    }

    if (oldPassword && newPassword) {
      IdentityManager.changePassword({
        oldPassword,
        newPassword,
        username: state.user?.email,
      })
        .then(() => {
          state.showSuccess("User password updated");
          handleClose();
        })
        .catch(({ message }) => state.showWarning(message));
    }
  };

  return (
    <StylesContainer gap={16} vertical>
      <Button
        onClick={handleClose}
        size="small"
        style={{ width: 150, color: "#435275" }}
        type="text"
      >
        <Flex align="center" gap={6}>
          <BlueArrow style={{ rotate: "90deg" }} />
          Back to home
        </Flex>
      </Button>
      <div style={{ fontSize: 20, fontWeight: 500, marginBottom: 6 }}>
        Settings & Security
      </div>
      <Form
        id="settingsSecurityForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Flex gap={16} vertical>
          <div>
            <Form.Item label="First Name" name="firstName">
              <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input />
            </Form.Item>
            <Form.Item label="Email (Username)" name="email">
              <Input />
            </Form.Item>
          </div>
          <Flex vertical>
            <Form.Item label="Old Password" name="oldPassword">
              <Input.Password
                iconRender={visible => (visible ? <Eyeball /> : <EyeballOff />)}
              />
            </Form.Item>
            <Form.Item label="New Password" name="newPassword">
              <Input.Password
                iconRender={visible => (visible ? <Eyeball /> : <EyeballOff />)}
              />
            </Form.Item>
            <Form.Item label="Re-type New Password" name="repeatNewPassword">
              <Input.Password
                iconRender={visible => (visible ? <Eyeball /> : <EyeballOff />)}
              />
            </Form.Item>
          </Flex>
        </Flex>
        <Button
          block
          disabled={loading}
          htmlType="submit"
          shape="round"
          type="text"
        >
          Save
        </Button>
      </Form>
    </StylesContainer>
  );
};

export default PageSettingsSecurity;
