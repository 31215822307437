import { useContext } from "react";
import State from "../../../../context/user/State";
import styled from "styled-components";
import { Flex } from "antd";

import StyledModal from "./styles";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const ActionItem = styled.div`
  font-size: 16px;
  color: #21409a;
  text-align: start;
  margin-bottom: 25px;
  cursor: pointer;
`;

const ModalCompetitiveInsight = ({
  activeAssetManagerId,
  handleClose,
  updateAssetManagerId,
  open,
  setActiveAssetManagerId,
}) => {
  const [state] = useContext(State);
  const advisorData = state.leagues?.find(
    it => it._id === updateAssetManagerId
  );

  const handleAdd = () => {
    setActiveAssetManagerId(updateAssetManagerId);
    handleClose();
  };

  const handleRemove = () => {
    setActiveAssetManagerId("");
    handleClose();
  };

  return (
    <StyledModal
      closeIcon={<CloseIcon />}
      footer={
        <Flex align="start" vertical>
          {activeAssetManagerId &&
          activeAssetManagerId === updateAssetManagerId ? (
            <ActionItem onClick={handleRemove}>
              Remove from
              <br />
              <b>Competitive Insight</b>
            </ActionItem>
          ) : (
            <ActionItem onClick={handleAdd}>
              Add <b>Competitive Insight</b> to
              <br />
              widget below
            </ActionItem>
          )}
          {advisorData?.url && (
            <ActionItem onClick={() => window.open(advisorData.url)}>
              Visit public website
            </ActionItem>
          )}
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={advisorData?.companyName}
      width={342}
    />
  );
};

export default ModalCompetitiveInsight;
