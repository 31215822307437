import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Button, Flex } from "antd";

import StyledModal from "./styles";

import IdentityManager from "../../../../requests/aws/IdentityManager";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";
import { ReactComponent as LogOutIcon } from "../../../../images/log_out_icon.svg";

const ModalProfile = ({ closeModal, open, openModal }) => {
  const navigate = useNavigate();

  const handleSeeAllSponsors = () => {
    closeModal("profileModal");
    openModal("sponsorsListModal");
  };

  const handleSettings = () => {
    closeModal("profileModal");
    navigate("/mmi/settings-security");
  };

  const handleLogOut = () => {
    Cookies.remove("mmi-token");
    IdentityManager.logOut();
    navigate("/login-mmi");
  };

  return (
    <StyledModal
      closeIcon={<CloseIcon />}
      footer={
        <Flex justify="start">
          <Button
            onClick={handleLogOut}
            shape="round"
            style={{ color: "#21409A" }}
            type="text"
          >
            <Flex align="center" gap={8} style={{ fontSize: 16 }}>
              Log out <LogOutIcon />
            </Flex>
          </Button>
        </Flex>
      }
      onCancel={() => closeModal("profileModal")}
      open={open}
      title="Menu"
      type="text"
      width={342}
    >
      <Flex vertical>
        <div
          className="action-item"
          onClick={() =>
            window.open("https://flxnetworks.com/become-a-member/", "_blank")
          }
        >
          Get full access to FLX Networks
        </div>
        <div className="action-item" onClick={handleSeeAllSponsors}>
          See list of all sponsors
        </div>
        <div className="action-item" onClick={handleSettings}>
          Settings & Security
        </div>
      </Flex>
    </StyledModal>
  );
};

export default ModalProfile;
