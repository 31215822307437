import WantToTalkGoalsImage from "../pages/PageDash/components/SectionIntroducingAdvice/images/demo.png";
import sidebar_logo from "../../../image/ot_logo_short.svg";

export default {
  colorPrimary: "#296469",
  comparison_products_colors: ["#7908A3", "#0076FF", "#001A45"],
  font_family: "Inter, sans-serif",
  income_chart_with_types_contributions_color: "#4997D1",
  income_chart_with_types_navbar_button_background: "#FFFFFF",
  income_chart_with_types_navbar_button_color: "#2A2828",
  income_chart_with_types_navbar_container_background: "#D9F3F7",
  goals_page_dash_all_episodes_button_background: "#296469",
  goals_page_dash_create_new_goals_button_background: "#296469",
  goals_page_dash_create_new_goals_button_color: "#FFFFFF",
  goals_page_dash_section_want_talk_button_background: "#296469",
  goals_page_dash_section_want_talk_button_color: "#FFFFFF",
  goals_page_dash_section_want_talk_button_copy: "Get Advice",
  goals_page_dash_section_want_talk_copy: `You can click the “Get Advice” button any time to get in touch with a financial professional.`,
  goals_page_goal_update_progress_button_background: "#03494E",
  goals_page_goal_update_progress_button_color: "#FFFFFF",
  layout_background: "#E3EBEC",
  proposal_page_dash_want_to_talk_image_link: WantToTalkGoalsImage,
  proposal_page_dash_want_to_talk_limited_content:
    "And, for a limited time, your first three questions are free.",
  proposal_page_dash_want_to_talk_title_content:
    "Want to talk to a Licensed Independent Financial<br/> Advisor",
  sidebar_avatar_background: "#749B9E",
  sidebar_avatar_color: "#FFFFFF",
  sidebar_background: "#052D2C",
  sidebar_icon_color: "#9BABAB",
  sidebar_icon_color_active: "#FFFFFF",
  sidebar_logo: sidebar_logo,
  tenant_color_primary: "#296469",
};
