import { Result } from "antd";

const SecurityGate = ({ children, state }) => {
  const pathname = location.pathname;
  let securityPassed = true;

  const failCheck = () => (securityPassed = false);

  const access = state.access;
  const { affiliate, next, level, proportal } = access ?? {};

  switch (pathname) {
    case "/next-advisor-portal/products":
      if (access) {
        if (affiliate && !next && !level && !proportal) failCheck();
        else if (affiliate && next && !level && !proportal) failCheck();
        else if (affiliate && next && !level && proportal) failCheck();
        else if (affiliate && !next && !level && proportal) failCheck();
        else if (!affiliate && next && !level && !proportal) failCheck();
        else if (!affiliate && next && !level && proportal) failCheck();
        else if (!affiliate && !next && !level && proportal) failCheck();
        else if (!affiliate && !next && !level && !proportal) failCheck();
      }
      break;
    case "/next-advisor-portal/marketing":
      if (access) {
        if (affiliate && !next && !level && !proportal) failCheck();
        else if (affiliate && next && !level && proportal) failCheck();
        else if (affiliate && !next && !level && proportal) failCheck();
        else if (!affiliate && next && !level && !proportal) failCheck();
        else if (!affiliate && next && !level && proportal) failCheck();
        else if (!affiliate && !next && !level && proportal) failCheck();
        else if (!affiliate && !next && !level && !proportal) failCheck();
      }
      break;
    case "/next-advisor-portal/proposals":
      if (access) {
        if (!affiliate && next && !level && !proportal) failCheck();
        else if (!affiliate && !next && !level && !proportal) failCheck();
      }
      break;
    case "/next-advisor-portal/clients":
      if (access) {
        if (!affiliate && !next && !level && !proportal) failCheck();
        else if (!affiliate && next && !level && !proportal) failCheck();
      }
      break;
    case "/next-advisor-portal/dashboard":
      if (access) {
        if (!affiliate && !next && !level && proportal) failCheck();
        else if (!affiliate && !next && !level && !proportal) failCheck();
      }
      break;
    case "/next-advisor-portal/leads":
      if (access) {
        if (!affiliate && !next && !level && !proportal) failCheck();
      }
  }

  return securityPassed ? (
    children
  ) : (
    <Result
      status="403"
      subTitle="Sorry, you do not have access this page."
      title="403"
    />
  );
};

export default SecurityGate;
