import { useContext } from "react";
import ReactPlayer from "react-player";
import State from "../../../../context";
import { Flex, Space, theme } from "antd";
import CardSkeleton from "../../../../atom/CardSkeleton";
import ModalQuarterlyGoals from "../../component/ModalQuarterlyGoals";
import SectionAdvisorDashboardAnalytics from "../../component/SectionAdvisorDashboardAnalytics";

import StylesContainer from "./styles";

import {
  openFile,
  updateQuarterlyGoals,
} from "../../../../utils/request/manager";

import { ReactComponent as File } from "../../../../icon/file.svg";

const PageAdvisorDashboard = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const handleUpdateQuarterlyGoals = requestBody => {
    state.setKeyValue("loading", true);

    updateQuarterlyGoals(requestBody)
      .then(() => {
        state.closeModal("openModalQuarterlyGoals");
        state.showSuccess("Quarterly Goals updated.");
        state.setAdminData();
      })
      .catch(console.log);
  };

  const getFeaturedMaterials = () =>
    state.marketingMaterialFolders
      ?.map(it => it.marketingMaterials)
      ?.flat()
      ?.filter(
        it => it?.featureContentOrder && it?.featureContentStatus === "Visible"
      )
      ?.sort((a, b) => a?.featureContentOrder - b?.featureContentOrder);

  return (
    <StylesContainer gap={40}>
      <SectionAdvisorDashboardAnalytics />
      <Flex
        gap={23}
        style={{
          width: 364,
          height: "fit-content",
          background: token.color_grey_3,
          borderRadius: 8,
          padding: 22,
        }}
        vertical
      >
        <Flex gap={13} vertical>
          <h3
            style={{
              fontSize: 21,
              fontWeight: 300,
              margin: 0,
            }}
          >
            From {state.organization?.name}
          </h3>
          <ReactPlayer
            controls={true}
            height={195}
            url={state.video ?? token.advisor_dashboard_page_video_link}
            width={320}
          />
        </Flex>
        <CardSkeleton loading={state.loading}>
          <Space direction="vertical" size={20} style={{ width: "100%" }}>
            {getFeaturedMaterials()?.map(
              ({ id, featureContentHeadline, featureContentDescription }) => (
                <Space
                  className="aside-card"
                  key={id}
                  size={15}
                  align="start"
                  onClick={() => openFile(id)}
                >
                  <File style={{ height: 30 }} />
                  <Space direction="vertical" size={2}>
                    <h4
                      style={{
                        fontSize: 18,
                        lineHeight: 1,
                        color: token.color_ui_text,
                        margin: 0,
                      }}
                    >
                      {featureContentHeadline}
                    </h4>
                    <p
                      style={{
                        lineHeight: 1.4,
                        color: token.color_ui_text,
                        margin: 0,
                      }}
                    >
                      {featureContentDescription}
                    </p>
                  </Space>
                </Space>
              )
            )}
          </Space>
        </CardSkeleton>
      </Flex>

      <ModalQuarterlyGoals
        open={state.openModalQuarterlyGoals}
        onClose={() => state.closeModal("openModalQuarterlyGoals")}
        handleUpdateQuarterlyGoals={handleUpdateQuarterlyGoals}
        initialState={state.quarterly}
        loading={state.loading}
      />
    </StylesContainer>
  );
};

export default PageAdvisorDashboard;
