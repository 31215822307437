import { useEffect } from "react";
import { Button, Flex, Form, Input, Modal, theme } from "antd";

import Close from "../../../../../../icon/Close";

const ModalRenameFile = ({ activeFileData, open, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  useEffect(() => {
    if (open) {
      form.setFieldValue("newFileName", activeFileData.name);
    }
  }, [open]);

  return (
    <Modal
      closeIcon={<Close />}
      className="rename-file-modal"
      footer={
        <Flex justify="space-between">
          <Button onClick={onCancel} shape="round" type="text">
            Cancel
          </Button>
          <Button
            form="formRenameFile"
            htmlType="submit"
            shape="round"
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
            type="primary"
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="Rename File"
    >
      <Flex align="center" justify="center" style={{ padding: 40 }}>
        <Form
          form={form}
          id="formRenameFile"
          layout="vertical"
          onFinish={onSuccess}
        >
          <Form.Item label="File Name" name="newFileName">
            <Input className="input-custom" placeholder="New File Name" />
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
};

export default ModalRenameFile;
