import { useContext } from "react";
import State from "../../../../context";
import SecurityGate from "../../../../provider/SecurityGate";

const PageNewNextLevelClients = () => {
  const [state] = useContext(State);

  return (
    <SecurityGate state={state}>
      <h3>TBD</h3>
    </SecurityGate>
  );
};

export default PageNewNextLevelClients;
