import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Flex, Image, theme } from "antd";
import TableMilestones from "./components/TableMilestones";

import StylesContainer from "./styles";

import { LIST_MILESTONES_QUESTION, STEPS_CONTENT } from "./constants";

import demo from "./images/demo.png";
import { ReactComponent as ArrowLeft } from "./images/icon_arrow_left.svg";
import { ReactComponent as ArrowRight } from "./images/icon_arrow_right.svg";
import { ReactComponent as IconNote } from "./images/icon_note_small.svg";
import { ReactComponent as Logo } from "../../image/logo_small_screen_view.svg";

const SectionMilestonesQuestionnaire = ({
  closeMilestonesView,
  handleAddNoteButton,
  publicView,
  saveMilestonesQuestions,
  state,
}) => {
  const { token } = theme.useToken();
  const [milestonesQuestions, setMilestonesQuestions] = useState(
    LIST_MILESTONES_QUESTION
  );
  const [currentStep, setCurrentStep] = useState("career_marriage");
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  useEffect(() => {
    if (state?.viewMilestonesQuestionnaire) {
      const milestonesQuestions = state.getPreferenceValue(
        "milestonesQuestions"
      );

      milestonesQuestions && setMilestonesQuestions(milestonesQuestions);
    }
  }, [state?.viewMilestonesQuestionnaire]);

  const handleBackButton = () => {
    switch (currentStep) {
      case "career_marriage":
        closeMilestonesView();
        break;
      case "family_health":
        setCurrentStep("career_marriage");
        break;
      case "assets_generosity":
        setCurrentStep("family_health");
    }
  };

  const handleContinueButton = () => {
    switch (currentStep) {
      case "career_marriage":
        setCurrentStep("family_health");
        break;
      case "family_health":
        setCurrentStep("assets_generosity");
        break;
      case "assets_generosity":
        saveMilestonesQuestions(milestonesQuestions);
    }
  };

  const handleCustomInputClick = eventId => {
    if (!eventId?.includes("__")) return;

    const [eventName, eventIndex] = eventId.split("__");

    if (!eventIndex) return;

    const nextIndexItem = milestonesQuestions.find(
      it => it.id === `${eventName}__${+eventIndex + 1}`
    );

    if (nextIndexItem?.hidden) {
      setMilestonesQuestions(lastState =>
        lastState.map(it =>
          it.id === nextIndexItem.id
            ? {
                ...it,
                hidden: false,
              }
            : it
        )
      );
    }
  };

  const handleCustomNameChange = ({ id, newLabel }) => {
    setMilestonesQuestions(lastState =>
      lastState.map(it => (it.id === id ? { ...it, label: newLabel } : it))
    );
  };

  const toggleCheckbox = ({ id, newValue }) => {
    setMilestonesQuestions(lastState =>
      lastState.map(it =>
        it.id === id
          ? { ...it, value: it.value === newValue ? null : newValue }
          : it
      )
    );
  };

  return (
    <StylesContainer gap={30} vertical>
      <Flex justify="center">
        <Logo />
      </Flex>
      <Flex className="header" justify="space-between">
        <Flex gap={8} vertical>
          <h2>Milestones</h2>
          <p>
            Context is key. Select the life events that apply to your current
            situation. You and your advisor will keep these Milestones in mind
            as you form a financial plan.
          </p>
        </Flex>
        <Image className="demo-image" preview={false} src={demo} />
      </Flex>
      <Flex className="body" gap={16} vertical>
        <h3>{STEPS_CONTENT[currentStep].title}</h3>
        {isSmallScreen ? (
          <TableMilestones
            data={milestonesQuestions.filter(it =>
              STEPS_CONTENT[currentStep].tableData.includes(it.type)
            )}
            handleCustomInputClick={handleCustomInputClick}
            handleCustomNameChange={handleCustomNameChange}
            toggleCheckbox={toggleCheckbox}
          />
        ) : (
          <Flex gap={40}>
            <TableMilestones
              data={milestonesQuestions.filter(
                it => it.type === STEPS_CONTENT[currentStep].tableData[0]
              )}
              handleCustomInputClick={handleCustomInputClick}
              handleCustomNameChange={handleCustomNameChange}
              toggleCheckbox={toggleCheckbox}
            />
            <TableMilestones
              data={milestonesQuestions.filter(
                it => it.type === STEPS_CONTENT[currentStep].tableData[1]
              )}
              handleCustomInputClick={handleCustomInputClick}
              handleCustomNameChange={handleCustomNameChange}
              toggleCheckbox={toggleCheckbox}
            />
          </Flex>
        )}
      </Flex>
      <Flex className="footer" justify="space-between">
        {
          <Button
            disabled={publicView && currentStep === "career_marriage"}
            icon={!isSmallScreen && <ArrowLeft />}
            onClick={handleBackButton}
            shape="round"
            size="large"
          >
            Back
          </Button>
        }
        <Flex gap={20}>
          <Button
            icon={<IconNote />}
            onClick={handleAddNoteButton}
            shape="round"
            size="large"
            style={{ fontSize: 14 }}
          >
            Add note
          </Button>
          <Button
            disabled={state?.loading}
            onClick={handleContinueButton}
            shape="round"
            size="large"
            style={{ background: "#428916" }}
            type="primary"
          >
            {currentStep === "assets_generosity" ? (
              <Flex align="center" gap={8}>
                Submit
                <ArrowRight />
              </Flex>
            ) : (
              "Continue"
            )}
          </Button>
        </Flex>
      </Flex>
    </StylesContainer>
  );
};

export default SectionMilestonesQuestionnaire;
