import LogoBluepoint from "../images/logo.png";

export default {
  admin_pie_chart_color_primary: "#F36835",
  admin_pie_chart_color_secondary: "#BEC8CE",
  admin_pie_chart_color_tertiary: "#354A5F",
  admin_pie_chart_color_rest: "#72C0C7",
  advisor_page_dashboard_right_side_section_background: "#E7EBEE",
  advisor_page_dashboard_right_side_section_title_color: "#5A607F",
  advisor_page_dashboard_right_side_section_title_copy: "From Bluepoint",
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  color_admin_analytic_value: "#131523",
  colorPrimary: "#0551A7",
  button_primary_background: "#428A16",
  color_secondary_button: "#4897D1",
  color_secondary_button_text: "#FFFFFF",
  color_tertiary_button: "#E7EBEE",
  color_tertiary_button_text: "#516474",
  comparison_products_colors: ["#E48361", "#83C390", "#679BCE"],
  logo_src: LogoBluepoint,
  proposal_guide_font_primary: "Barlow, sans-serif",
  sidebar_background: "#EDF0F2",
  sidebar_icon_color: "#6291C5",
  sidebar_icon_color_active: "#0551A7",
  sidebar_menu_item_color: "#4897D1",
  sidebar_menu_item_hover_background: "#fff",
  sidebar_profile_color: "#4897D1",
  tenant_color_primary: "#4897D1",
};
