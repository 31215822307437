import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context";
import { Button, Flex, Input, Table, Tag, theme } from "antd";

import { StyledSelect, StyledTable } from "./styles";

import { getAllConversations } from "../../../../utils/request/penny";

import { SearchOutlined } from "@ant-design/icons";

const { Column } = Table;

const PageConversations = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [searchedTags, setSearchedTags] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      state.conversations?.map((it, index) => ({
        ...it,
        key: index,
      }))
    );
  }, [state.conversations]);

  useEffect(() => {
    setLoading(true);

    getAllConversations()
      .then(data => {
        state.setKeyValue("conversations", data);
        setTableData(
          data?.map((it, index) => ({
            ...it,
            key: index,
          }))
        );
      })
      .catch(error => state.showError(error.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const getOptions = () =>
    Array.from(new Set(state.conversations?.map(it => it.tags)?.flat()))?.map(
      it => ({
        label: it,
        value: it,
      })
    );

  return (
    <>
      <div
        style={{
          fontSize: 20,
          fontWeight: 800,
          color: token.colorPrimary,
          padding: "31px 46px",
          borderBottom: "1px solid #C9C8C8",
        }}
      >
        All Conversations
      </div>
      <div style={{ padding: "30px 109px 30px 46px" }}>
        <Flex justify="space-between" style={{ marginBottom: 30 }}>
          <Flex gap={27}>
            <Input
              className="pointer"
              onChange={event => setSearchedText(event.target.value)}
              placeholder="Search..."
              size="large"
              style={{ width: 228, borderRadius: 23 }}
              suffix={
                <SearchOutlined style={{ fontSize: 20, color: "#B1B1B1" }} />
              }
            />
            <StyledSelect
              defaultValue={searchedTags}
              mode="multiple"
              placeholder="Filter by tag"
              onChange={setSearchedTags}
              options={getOptions()}
              maxTagCount="responsive"
            />
          </Flex>
          <Button
            onClick={() => state.setKeyValue("openModalAskAdvice", true)}
            shape="round"
            style={{ background: token.button_ask_penny_background }}
            type="primary"
          >
            Ask<b>Penny</b>
          </Button>
        </Flex>
        <StyledTable
          dataSource={tableData}
          loading={loading}
          onRow={conversationData => {
            return {
              onClick: () => {
                state.setKeyValue("conversationModal", true);
                state.setKeyValue("activeConversationId", conversationData._id);
              },
            };
          }}
          scroll={{
            x: 1100,
            y: "calc(100vh - 330px)",
          }}
          size="small"
        >
          <Column
            dataIndex="userQuery"
            filteredValue={[searchedText]}
            key="userQuery"
            onFilter={(value, record) =>
              String(record.userQuery)
                .toLowerCase()
                .includes(value.toLowerCase())
            }
            render={value => (
              <div
                style={{ fontWeight: 600, maxWidth: 500 }}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            )}
            sorter={{
              compare: (a, b) => a.userQuery?.localeCompare(b.userQuery),
            }}
            title="Question / Conversation"
            width={300}
          />
          <Column
            dataIndex="tags"
            filteredValue={searchedTags}
            key="tags"
            onFilter={(value, record) => record.tags?.includes(value)}
            render={tags => (
              <Flex gap={6} wrap="wrap">
                {tags?.map((tag, index) => (
                  <Tag
                    key={index}
                    style={{
                      fontSize: 14,
                      background: "#FFF",
                      color: "#1E1919",
                      borderColor: "#585858",
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
              </Flex>
            )}
            title="Tags"
          />
          <Column
            dataIndex="userEmail"
            key="userEmail"
            sorter={{
              compare: (a, b) => a.userEmail?.localeCompare(b.userEmail),
            }}
            title="User"
          />
          <Column
            dataIndex="environment"
            key="environment"
            sorter={{
              compare: (a, b) => a.environment?.localeCompare(b.environment),
            }}
            title="Environment"
            width={140}
          />
          <Column
            dataIndex="source"
            key="source"
            render={value => (
              <span style={{ textTransform: "capitalize" }}>
                {value === "db" || value === "llm" ? "LLM" : value}
              </span>
            )}
            sorter={{
              compare: (a, b) => a.source?.localeCompare(b.source),
            }}
            title="Answer"
            width={100}
          />
          <Column
            align="center"
            dataIndex="counterReplies"
            key="counterReplies"
            sorter={{
              compare: (a, b) => a.counterReplies - b.counterReplies,
            }}
            title="Replies"
            width={100}
          />
          <Column
            align="right"
            dataIndex="createdAt"
            key="createdAt"
            render={value => dayjs(value).format("MM/DD/YYYY h:mma")}
            sorter={{
              compare: (a, b) => dayjs(a.createdAt) - dayjs(b.createdAt),
            }}
            title="Initiated"
            width={150}
          />
        </StyledTable>
      </div>
    </>
  );
};

export default PageConversations;
