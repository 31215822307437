import logo_dark from "../image/logo_dark.svg";
import logo_light from "../image/logo_light.svg";

export default {
  advisor_page_dashboard_right_side_section_hidden: true,
  advisor_page_dashboard_right_side_section_background: "#E7EBEE",
  advisor_page_dashboard_right_side_section_title_color: "#5A607F",
  advisor_page_dashboard_right_side_section_title_copy: "From Newland",
  admin_pie_chart_color_primary: "#F36835",
  admin_pie_chart_color_secondary: "#BEC8CE",
  admin_pie_chart_color_tertiary: "#354A5F",
  admin_pie_chart_color_rest: "#72C0C7",
  button_add_user_background: "#184073",
  button_ask_penny_background: "#9A18D6",
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  color_admin_analytic_value: "#131523",
  colorPrimary: "#184073",
  button_primary_background: "#428A16",
  color_secondary_button: "#4897D1",
  color_secondary_button_text: "#FFFFFF",
  color_tertiary_button: "#E7EBEE",
  color_tertiary_button_text: "#516474",
  comparison_products_colors: ["#E48361", "#83C390", "#679BCE"],
  logo_src: logo_dark,
  overlay_background: "rgba(39, 90, 144, 0.8)",
  proposal_guide_font_primary: "Barlow, sans-serif",
  quarterly_goal_aum_color: "#3B92B7",
  quarterly_goal_client_color: "#4F8083",
  quarterly_goal_proposal_color: "#215054",
  sidebar_background: "#184073",
  sidebar_icon_color: "#FFFFFF60",
  sidebar_icon_color_active: "#FFFFFF",
  sidebar_logo: logo_light,
  spin_color: "#fff",
  tenant_color_primary: "#4897D1",
};
