import { Button, Flex, Modal, theme, Typography } from "antd";

import Close from "../../../../icon/Close";

const ModalDeleteAdvisor = ({ email, handleOk, loading, onCancel, open }) => {
  const { token } = theme.useToken();

  return (
    <Modal
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between">
          <Button onClick={onCancel} shape="round" type="text">
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleOk}
            shape="round"
            style={{
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
            type="primary"
          >
            Yes, Delete
          </Button>
        </Flex>
      }
      open={open}
      onCancel={onCancel}
      title="Confirm"
    >
      <div style={{ textAlign: "center", padding: 40 }}>
        <Typography.Paragraph style={{ fontSize: 16 }}>
          Are you sure you want to delete the advisor <b>“{email}”</b> and the
          associated clients?
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontSize: 16 }}>
          This action cannot be undone.
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ModalDeleteAdvisor;
