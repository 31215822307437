import Dashboard from "../../../icon/Dashboard";
import Lead from "../../../icon/Lead";
import Client from "../../../icon/Client";
import AskPenny from "../../../icon/AskPenny";
import Lock from "../../../icon/Lock";
import { showTabNewClients } from "../../../ecosystem/next/helpers";
import Product from "../../../icon/Product";
import Marketing from "../../../icon/Marketing";
import User from "../../../icon/User";
import Proposal from "../../../icon/Proposal";
import Users from "../../../icon/Users";
import IconGoalsNew from "../../../icon/GoalsNew";
import EnterprisesIcon from "../../images/EnterprisesIcon";
import UserActivityIcon from "../../images/UserActivityIcon";
import RolesPermissionsIcon from "../../images/RolesPermissionsIcon";
import EnvironmentsIcon from "../../images/EnvironmentsIcon";
import GoalsDashboard from "../../../icon/GoalsDashboard";
import Goals from "../../../icon/Goals";

export const SIDEBAR_TABS = {
  access: {
    affiliate: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNext: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNextProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    level: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        hidden: !showTabNewClients(),
        Icon: Client,
        path: "/next-advisor-portal/proposals-new",
        text: "Clients New",
      },
      {
        Icon: Product,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: Marketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    levelNext: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        hidden: !showTabNewClients(),
        Icon: Client,
        path: "/next-advisor-portal/proposals-new",
        text: "Clients New",
      },
      {
        Icon: Product,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: Marketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    next: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    nextProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    proportal: [
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
  },
  astor: {
    admin: [
      {
        path: "/astor-admin-portal/advisors",
        Icon: User,
        text: "Advisors",
      },
      {
        path: "/astor-admin-portal/products",
        Icon: Product,
        text: "Products",
      },
      {
        path: "/astor-admin-portal/proposal",
        Icon: Proposal,
        text: "Proposal",
      },
      {
        path: "/astor-admin-portal/marketing",
        Icon: Marketing,
        text: "Marketing",
      },
      {
        path: "/astor-admin-portal/clients",
        Icon: Client,
        text: "Clients",
      },
    ],
    advisor: [
      {
        path: "/astor-advisor-portal/dashboard",
        Icon: User,
        text: "Dashboard",
      },
      {
        path: "/astor-advisor-portal/leads",
        Icon: Users,
        text: "Leads",
      },
      {
        path: "/astor-advisor-portal/clients",
        Icon: Client,
        text: "Clients",
      },
      {
        path: "/astor-advisor-portal/products",
        Icon: Product,
        text: "Products",
      },
      {
        path: "/astor-advisor-portal/marketing",
        Icon: Marketing,
        text: "Marketing",
      },
    ],
    proposal: [
      {
        Icon: Goals,
        path: "/astor-proposal",
        text: "Goals",
      },
    ],
  },
  disabled: [
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/dashboard",
      text: "Dashboard",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/leads",
      text: "Leads",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/proposals",
      text: "Clients",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/products",
      text: "Products",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/marketing",
      text: "Marketing",
    },
  ],
  level: {
    proposal: [
      {
        Icon: IconGoalsNew,
        path: "/level-proposal/dashboard",
      },
    ],
    superadmin: [
      {
        Icon: EnterprisesIcon,
        path: "/super-admin/enterprises",
        text: "Enterprises",
      },
      {
        children: [
          {
            key: "/super-admin/next-advisors",
            path: "/super-admin/next-advisors",
            label: "Next Advisors",
          },
          {
            key: "/super-admin/next-leads",
            path: "/super-admin/next-leads",
            label: "Next Leads",
          },
        ],
        text: "All Users",
      },
      {
        Icon: UserActivityIcon,
        path: "/super-admin/user-activity",
        text: "User Activity",
      },
      {
        Icon: RolesPermissionsIcon,
        path: "/super-admin/roles-permissions",
        text: "Roles & Permissions",
      },
      {
        children: [
          {
            key: "/super-admin/conversations",
            path: "/super-admin/conversations",
            label: "Conversations",
          },
          {
            key: "/super-admin/training-lab",
            path: "/super-admin/training-lab",
            label: "Training Lab",
          },
        ],
        text: "Penny",
      },
      {
        Icon: EnvironmentsIcon,
        path: "/super-admin/environments",
        text: "Environments",
      },
    ],
  },
  oneascent: {
    proposal: [
      {
        Icon: GoalsDashboard,
        path: "/oneascent-proposal/dashboard",
        text: "Dash",
      },
      {
        Icon: Goals,
        path: "/oneascent-proposal/goals",
        text: "Investments",
      },
    ],
  },
  rivershares: {
    proposal: [
      {
        Icon: Goals,
        path: "/rivershares-proposal",
        text: "Goals",
      },
    ],
  },
};
