import { Flex } from "antd";
import ChartPersonality from "../../../ChartPersonality";

import { getListUseOfMoney } from "../../../../../../constant";

export const EMPTY_UNIQUE_TENDENCIES_TEXT =
  "Generally, the couple does not appear to exhibit extreme polarization or differences concerning the use of money. The couple may even feel pretty well aligned day to day, though each person maintains their own preferences. A financial advisor may be particularly helpful in providing a unique, third-party perspective to help you see the whole field.";

const TabUniqueTendencies = ({ coupleCalculations, partnerA, partnerB }) => {
  const relatedTendencyTypes = coupleCalculations
    .filter(it => it.difference > 50)
    .map(it => it.type);

  return (
    <Flex gap={10}>
      {relatedTendencyTypes.length ? (
        <Flex gap={20} vertical>
          {relatedTendencyTypes.map((tendencyType, index) => {
            const useOfMoneyData = getListUseOfMoney(partnerA, partnerB).find(
              it => it.type === tendencyType
            );

            return (
              <Flex className="copy-container" key={index} vertical>
                <h4 className="title">{tendencyType}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: useOfMoneyData?.descriptionUniqueTendencies,
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <Flex className="copy-container" vertical>
          <p
            dangerouslySetInnerHTML={{ __html: EMPTY_UNIQUE_TENDENCIES_TEXT }}
          />
        </Flex>
      )}

      <ChartPersonality labelAccentValues={relatedTendencyTypes} />
    </Flex>
  );
};

export default TabUniqueTendencies;
