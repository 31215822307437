import { Flex, Image } from "antd";
import dayjs from "dayjs";
import CarouselPrimary from "../../../../../../atom/CarouselPrimary";

import { StyledCard } from "./styles";

const CarouselEvents = ({ events }) => (
  <CarouselPrimary>
    {events?.map((it, index) => (
      <StyledCard
        className={it.linkUrl ? "pointer" : ""}
        key={index}
        onClick={() => it.linkUrl && window.open(it.linkUrl)}
      >
        <Flex gap={10} justify="space-between" style={{ height: "100%" }}>
          <Image
            alt={it.graphicFile?.filename}
            height="100%"
            preview={false}
            src={it.graphicFile?.url}
          />
          <Flex justify="center" style={{ width: 211 }} vertical>
            <span
              style={{
                fontSize: 16,
                color: "#9D9D9D",
                textTransform: "uppercase",
              }}
            >
              Event
            </span>
            <b style={{ lineHeight: "20px", fontSize: 18, color: "#17191C" }}>
              {it.name}
            </b>
            <span style={{ fontSize: 13, color: "#4EABE9" }}>
              <b>{dayjs(it.date).format("MMM DD, YYYY")}</b>
              {" • "}
              {it.location}
            </span>
          </Flex>
        </Flex>
      </StyledCard>
    ))}
  </CarouselPrimary>
);

export default CarouselEvents;
