import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    padding: 71px 80px 20px 78px;
    position: relative;
    width: 1230px;
  }

  main {
    color: #000;
    display: flex;
    gap: 20px;
    flex-direction: column;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    margin-top: 50px;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }

    .subtitle {
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .analytic-container {
      background: #f0f9f9;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 27px 27px 30px 54px;
      width: 586px;
    }
  }
`;

export default StylesContainer;
