import dayjs from "dayjs";
import { Button, Flex } from "antd";

import StylesContainer from "./styles";

import Lamp from "../../../../images/lamp.png";

const SectionCompetitiveInsight = ({
  articles,
  clearInsights,
  companyName,
}) => (
  <StylesContainer vertical>
    <Flex align="center" className="header" justify="space-between">
      <span className="title">Competitive Insight</span>
      {articles?.length && (
        <Button
          className="clear-button"
          onClick={clearInsights}
          shape="round"
          size="small"
          type="text"
        >
          CLEAR
        </Button>
      )}
    </Flex>
    <div className={`body ${articles?.length ? "active" : ""}`}>
      {articles?.length ? (
        <Flex gap={23} style={{ paddingBottom: 20 }} vertical>
          {articles.map(it => (
            <Flex key={it.id} vertical>
              <Flex gap={4} style={{ fontSize: 16 }}>
                <span style={{ fontWeight: 500 }}>{companyName}</span>
                <span style={{ color: "#AAAAAA" }}>•</span>
                <span className="date">
                  {dayjs(it.updated).format("MMM D, YYYY h:mma")}
                </span>
              </Flex>
              <p style={{ color: "#464646", margin: "5px 0" }}>{it.title}</p>
              <p style={{ fontSize: 11, color: "#546170", margin: "5px 0" }}>
                Source: {it.sourceName}
              </p>
              <Button
                onClick={() => window.open(it.url)}
                size="small"
                style={{ width: 77, padding: 2, color: "#21409A" }}
                type="text"
              >
                View More
              </Button>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex gap={20}>
          <img src={Lamp} alt="Lamp" style={{ height: 57 }} />
          <span style={{ fontSize: 15, color: "#17191C70" }}>
            To view competitive insights click an asset manager from the table
            above to add it here.
          </span>
        </Flex>
      )}
    </div>
  </StylesContainer>
);

export default SectionCompetitiveInsight;
