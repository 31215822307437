import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Flex } from "antd";

import { getChartConfigPlanning } from "../../../../../../chart";

import { LIST_PERSPECTIVES_PROFILE } from "../../../../../../constant";

const TabPersonalityDetails = ({ activePersonality, calculation }) => {
  const personalityAdditionalData = LIST_PERSPECTIVES_PROFILE.find(
    it => it.name === activePersonality
  );

  return (
    <Flex className="content-container" gap={32}>
      <Flex className="description-container" vertical>
        <span className="label">
          {
            calculation?.personalityList?.find(
              it => it.type === activePersonality
            )?.value
          }
          % Match{" "}
          {calculation.personalityList[0].type === activePersonality &&
            " - Best"}
        </span>
        <h1 className="title">{activePersonality}</h1>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html: LIST_PERSPECTIVES_PROFILE.find(
              it => it.name === activePersonality
            )?.description,
          }}
        />
      </Flex>
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartConfigPlanning({
          series: [
            {
              color: "#3D9ED5",
              dashStyle: "Dash",
              data: [
                personalityAdditionalData?.spending,
                personalityAdditionalData?.giving,
                personalityAdditionalData?.saving,
                personalityAdditionalData?.investing,
              ],
              marker: {
                enabled: false,
              },
              name: "Perspectives Profile",
              pointPlacement: "on",
              zIndex: 2,
            },
            {
              color: "#42C77D",
              data: [
                Math.round((calculation?.spendingPoints / 25) * 100),
                Math.round((calculation?.givingPoints / 25) * 100),
                Math.round((calculation?.savingPoints / 25) * 100),
                Math.round((calculation?.investingPoints / 25) * 100),
              ],
              lineColor: "transparent",
              marker: {
                enabled: false,
              },
              name: "Your score",
              pointPlacement: "on",
              type: "area",
              zIndex: 1,
            },
          ],
        })}
      />
    </Flex>
  );
};

export default TabPersonalityDetails;
