import styled from "styled-components";

const StylesContainer = styled.div`
  height: 100%;
  padding: 80px 40px;
  border-bottom: unset;
  background: transparent linear-gradient(360deg, #4897d100 0%, #4897d110 100%);
  background-size: contain;
  box-sizing: border-box;
  overflow: auto;

  .content-body {
    max-width: 1100px;
    margin: 0 auto;
  }

  .title {
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    color: #15284b;
  }

  .description {
    line-height: 28px;
    font-size: 22px;
    font-weight: 300;
    color: #15284b;
  }

  & * {
    font-family: ${props => props.font_family};
  }

  @media screen and (max-width: 720px) {
    padding: 40px;
  }
`;

export default StylesContainer;
