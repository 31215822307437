import styled from "styled-components";

const StylesContainer = styled.div`
  min-width: 1024px;

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #ffffff;

    &::before {
      display: none;
    }
  }
`;

export default StylesContainer;
