import { useContext } from "react";
import State from "../../../../../../../../context";
import { Button, Flex } from "antd";
import ModalMilestones from "../../../ModalMilestones";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";

const CardMilestones = () => {
  const [state] = useContext(State);

  const getMilestonesTotalByType = type =>
    state
      .getPreferenceValue("milestonesQuestions")
      ?.reduce((acc, it) => (it.value === type ? ++acc : acc), 0);

  const handleMilestonesEdit = () => {
    state.setKeyValue("openModalMilestones", false);
    state.setKeyValue("viewMilestonesQuestionnaire", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleLaunchMilestones = () => {
    state.setKeyValue("surveyLinkFor", "milestones");
    state.setKeyValue("openModalSurveyLink", true);
  };

  const ButtonMilestones = () =>
    state.getPreferenceValue("milestonesQuestions") ? (
      <Button
        onClick={() => state.setKeyValue("openModalMilestones", true)}
        shape="round"
        style={{ background: "#209F00" }}
        type="primary"
      >
        View Milestones
      </Button>
    ) : (
      <Button
        disabled={isAdminView()}
        onClick={handleLaunchMilestones}
        shape="round"
        type="primary"
      >
        Launch Milestones
      </Button>
    );

  return (
    <Flex className="card-next-up">
      <Flex gap={16} vertical>
        <h5 className="title">Milestones</h5>
        {state.getPreferenceValue("milestonesQuestions") ? (
          <Flex gap={12} vertical>
            <Flex gap={16}>
              <Button
                shape="round"
                size="small"
                style={{
                  background: "#071A4D",
                  minWidth: "fit-content",
                }}
                type="primary"
              >
                {getMilestonesTotalByType("recent")} Recent
              </Button>
              <Button
                shape="round"
                size="small"
                style={{
                  background: "#071A4D",
                  minWidth: "fit-content",
                }}
                type="primary"
              >
                {getMilestonesTotalByType("upcoming")} Upcoming
              </Button>
            </Flex>
            <p className="description">
              Clarity brings confidence. What’s going on in your financial life
              today?
            </p>
          </Flex>
        ) : (
          <p className="description">
            Clarity brings confidence. What’s going on in your financial life
            today?
          </p>
        )}
      </Flex>
      <Flex align="center">
        <ButtonMilestones />
      </Flex>

      <ModalMilestones
        handleMilestonesEdit={handleMilestonesEdit}
        milestonesQuestions={state.getPreferenceValue("milestonesQuestions")}
        milestonesNote={state.getPreferenceValue("milestonesNote")}
        onCancel={() => state.setKeyValue("openModalMilestones", false)}
        open={state.openModalMilestones}
      />
    </Flex>
  );
};

export default CardMilestones;
