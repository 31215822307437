import styled from "styled-components";
import PageAdvisorProspects from "../../manager/page/PageAdvisorProspects";
import PageAskPenny from "../pages/PageAskPenny";
import PageFinancialProducts from "../../manager/page/PageFinancialProducts";
import PageLeads from "../pages/PageLeads";
import PageMarketing from "../../manager/page/PageMarketing";
import PageNewNextLevelClients from "../pages/PageNewNextLevelClients";
import PageNextClients from "../pages/PageNextClients";
import PageNextDashboard from "../pages/PageNextDashboard";
import PageNextLevelClients from "../pages/PageNextLevelClients";

const Container = styled.div`
  padding: 42px 78px 27px 35px;
`;

export default [
  {
    path: "/next-advisor-portal/no-access",
    Component: () => {},
  },
  {
    path: "/next-advisor-portal/dashboard",
    Component: PageNextDashboard,
  },
  {
    path: "/next-advisor-portal/leads",
    Component: PageLeads,
  },
  {
    path: "/next-advisor-portal/prospects",
    Component: () => (
      <Container>
        <PageAdvisorProspects />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/clients",
    Component: PageNextClients,
  },
  {
    path: "/next-advisor-portal/ask-penny",
    Component: PageAskPenny,
  },
  {
    path: "/next-advisor-portal/proposals",
    Component: () => (
      <Container>
        <PageNextLevelClients />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/proposals-new",
    Component: () => (
      <Container>
        <PageNewNextLevelClients />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/products",
    Component: () => (
      <Container>
        <PageFinancialProducts />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/marketing",
    Component: () => (
      <Container>
        <PageMarketing />
      </Container>
    ),
  },
];
