import styled from "styled-components";

import background from "./image/background.svg";
import top_image from "./image/top_image.svg";

const StylesContainer = styled.div`
  background: url(${background}) bottom no-repeat;
  font-family: Inter, sans-serif;
  height: 1590px;
  position: relative;
  text-align: center;
  width: 1230px;

  &::before {
    content: "";
    background: url(${top_image}) no-repeat;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  header {
    padding-top: 265px;
  }

  main {
    margin: 411px 0 347px;

    h2 {
      color: #234744;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0 0 98px;
    }

    h3 {
      color: #234744;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0 0 15px;
    }

    .date {
      color: #234744;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
    }
  }

  .label {
    color: #234744;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
  }
`;

export default StylesContainer;
