import { useContext } from "react";
import State from "../../../../../../context";
import { Row } from "antd";
import FileCard from "./components/FileCard";
import FolderCard from "./components/FolderCard";

const MaterialsTilesView = ({ searchText }) => {
  const [state] = useContext(State);

  const FilteredList = () => {
    if (!state.marketingMaterialFolders?.length) return;

    if (searchText) {
      // file from any level folder by searched text
      return state.marketingMaterialFolders
        .map(folderData => folderData.marketingMaterials)
        .flat()
        .filter(fileData =>
          fileData?.name?.toLowerCase()?.includes(searchText.toLowerCase())
        )
        .map((fileData, index) => <FileCard key={index} fileData={fileData} />);
    } else if (state.activeFolderData) {
      if (state.activeFolderData.parentFolderId) {
        // files of selected child folder
        return state.marketingMaterialFolders
          .find(it => it.name === state.activeFolderData?.name)
          .marketingMaterials?.map((fileData, index) => (
            <FileCard key={index} fileData={fileData} />
          ));
      } else {
        // folders and files of selected parent folder
        return state.marketingMaterialFolders
          .filter(folder => folder.parentFolderId === state.activeFolderData.id)
          .map((folder, index) => (
            <FolderCard
              date={folder.createDateTime.$date}
              key={index}
              name={folder.name}
              onSelect={() => state.setKeyValue("activeFolderData", folder)}
            />
          ));
      }
    } else {
      // show all parent folders
      return state.marketingMaterialFolders
        .filter(folder => !folder.parentFolderId)
        .map((folder, index) => (
          <FolderCard
            date={folder.createDateTime.$date}
            key={index}
            name={folder.name}
            onSelect={() => state.setKeyValue("activeFolderData", folder)}
          />
        ));
    }
  };

  return (
    <Row style={{ padding: 20, gap: "20px 70px" }} wrap>
      <FilteredList />
    </Row>
  );
};

export default MaterialsTilesView;
