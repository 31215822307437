import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Flex, theme } from "antd";
import ChartComparisonGrowth from "../../../../../molecule/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../../../../molecule/ChartIncomeWithTypes";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { getLiveAssessment } from "../../../../../utils/request/regularApp";
import {
  getCurrencyValue,
  getPercentValue,
} from "../../../../../utils/helper/general";

import { ReactComponent as IncomeChartLegend } from "./image/income_chart_legend.svg";
import { ReactComponent as CheckmarkIncome } from "./image/checkmark_income.svg";

const ReportPageProposalIncome = ({
  personaName,
  productsList,
  proposalData,
  setLoading,
}) => {
  const { token } = theme.useToken();
  const [productsLiveAssessment, setProductsLiveAssessment] = useState();

  useEffect(() => {
    if (!proposalData) return;

    setLoading(true);

    getLiveAssessment({
      organization: proposalData.organization,
      objective: {
        ...proposalData,
        advisorFee: getPercentValue(proposalData.advisorFee),
        birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
        withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
      },
      productId: proposalData.productId,
    }).then(liveAssessment => {
      setProductsLiveAssessment([liveAssessment]);

      // leave time for charts rendering
      setTimeout(() => setLoading(false), 4000);
    });
  }, [proposalData]);

  return (
    <StylesContainer id="reportPageProposalIncome">
      <Flex className="page-container" vertical>
        <ReportHeader personaName={personaName} />

        <main>
          <h3>What we talked about</h3>
          <p style={{ marginBottom: 50, width: 783 }}>
            Setting clear financial goals acts as a roadmap for your financial
            journey, ensuring that every decision you make is purposeful and
            directed toward your desired outcomes. By aligning investment
            products with your personal aspirations, it becomes possible to
            regularly assess whether the chosen strategy is effectively guiding
            you toward success and remains advantageous for achieving your
            financial objectives.
          </p>

          <Flex justify="space-between">
            <Flex flex="0 658px" gap={24} vertical>
              <Flex justify="space-between">
                <h3>{proposalData?.productName}</h3>
                <CheckmarkIncome />
              </Flex>
              {productsLiveAssessment && (
                <ChartComparisonGrowth
                  height={300}
                  productsList={productsList}
                  productsLiveAssessment={productsLiveAssessment}
                  proposalData={proposalData}
                />
              )}
            </Flex>

            <Flex align="center" gap={24} vertical>
              <h3>Portfolio Mix</h3>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    height: 300,
                    spacingTop: 0,
                    type: "pie",
                    width: 300,
                  },
                  title: {
                    text: null,
                  },
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    pie: {
                      size: 158,
                      innerSize: "60%",
                      dataLabels: {
                        enabled: false,
                      },
                      showInLegend: true,
                      center: ["50%", "50%"],
                      borderColor: "#ffffff",
                      borderWidth: 2,
                    },
                    series: {
                      animation: false,
                    },
                  },
                  legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    labelFormatter: function () {
                      return this.y + "% " + this.name;
                    },
                    itemStyle: {
                      fontSize: 14,
                      textOverflow: null,
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        enabled: false,
                      },
                    },
                  },
                  colors: token.chart_colors,
                  series: [
                    {
                      colorByPoint: true,
                      data: productsList
                        .find(
                          product => product.name === proposalData?.productName
                        )
                        ?.assets?.map(it => ({
                          name: it.assetName,
                          y: it.investmentPercent,
                        })),
                      name: "Selected",
                      size: "100%",
                    },
                  ],
                }}
              />
            </Flex>
          </Flex>

          <h3 style={{ marginBottom: 10 }}>Your Selections</h3>
          <Flex
            className="selections-container"
            gap={126}
            style={{ marginBottom: 50 }}
          >
            <div className="value-container">
              <span className="label">Investment Objective</span>
              <p className="value" style={{ textTransform: "capitalize" }}>
                {proposalData?.investmentObjective}
              </p>
            </div>
            <div className="value-container">
              <span className="label">Length of Investment</span>
              <p className="value">
                {proposalData?.investmentDuration} year(s)
              </p>
            </div>
            <div className="value-container">
              <span className="label">Risk Tolerance</span>
              <p className="value" style={{ textTransform: "capitalize" }}>
                {proposalData?.riskTolerance}
              </p>
            </div>
            <div className="value-container">
              <span className="label">
                {token.proposal_copy_investment_amount}
              </span>
              <p className="value">
                {getCurrencyValue(proposalData?.investmentAmount)}
              </p>
            </div>
          </Flex>

          <Flex gap={90} justify="space-between" style={{ marginBottom: 30 }}>
            <Flex flex="706px" justify="space-between" vertical>
              {productsLiveAssessment && (
                <ChartIncomeWithTypes
                  height={273}
                  hideChartTypeButtons
                  loading={false}
                  productData={proposalData}
                  productsList={productsList}
                  productsLiveAssessment={productsLiveAssessment}
                  showLegend={false}
                />
              )}
            </Flex>

            <IncomeChartLegend />
          </Flex>

          <Flex gap={62}>
            <section className="section-planning-corner">
              <h5>Planning Corner</h5>
              <h3>Balancing Objectives</h3>
              <p>
                Growth investments can increase wealth over time, while
                income-generating assets provide stability and cash flow,
                especially during market downturns. This approach offers
                potential for capital appreciation while providing a safety net
                through consistent income, enhancing overall financial security
                and flexibility.
              </p>
            </section>

            <Flex
              className="selections-container"
              justify="space-between"
              style={{ width: 247 }}
              vertical
            >
              <div className="value-container">
                <span className="label">Annual Income</span>
                <p className="value">
                  {productsLiveAssessment?.length
                    ? getCurrencyValue(
                        productsLiveAssessment[0].incomeBarchart?.withdrawalSize
                      )
                    : ""}
                </p>
              </div>
              <div className="value-container">
                <span className="label">Withdrawal Rate</span>
                <p className="value">
                  {getPercentValue(proposalData?.withdrawalLevel)}
                </p>
              </div>
            </Flex>
          </Flex>
        </main>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalIncome;
