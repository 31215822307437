import { Flex } from "antd";

import StylesContainer from "./styles";

import { ReactComponent as Logo } from "./image/logo.svg";

const Header = ({ personaName }) => (
  <StylesContainer>
    <Logo />

    <Flex className="description" vertical>
      <span>Prepared for</span>
      <b>{personaName}</b>
    </Flex>
  </StylesContainer>
);

export default Header;
