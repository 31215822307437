import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    position: relative;
    width: 1230px;
    padding: 71px 80px 0 78px;
  }

  h2 {
    color: #0a3b66;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 48px 0;
  }

  .container-description {
    margin-right: 30px;

    p {
      color: #2a2828;
      font-size: 18px;
      font-style: normal;
      line-height: 29px;
    }

    .container-image-quote {
      margin: 32px 0;
      text-align: center;
    }
  }

  .container-advisor-info {
    color: #2a2828;
    font-size: 18px;
    font-style: normal;
    line-height: 29px;

    h3 {
      font-size: 22px;
      margin: 0;
      text-transform: capitalize;
    }

    h4 {
      border-bottom: 7px solid #15284b;
      color: #0a3b66;
      font-size: 25px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
      padding-bottom: 6px;
    }

    p {
      margin: 0;
    }
  }
`;

export default StylesContainer;
