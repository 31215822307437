import { useEffect } from "react";
import { Button, Flex, Form, Input } from "antd";

import StyledModal from "./styles";

import { isAdminView } from "../../../../../../utils/helper/specialized";

import { ReactComponent as Copy } from "./images/icon_copy.svg";
import Close from "../../../../../../icon/Close";

const ModalSendSurveyLink = ({
  email,
  handleCopyLink,
  loading,
  onCancel,
  onContinue,
  onSend,
  open,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!open) return;

    form.setFieldValue("email", email);
  }, [open]);

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between">
          <Button onClick={onCancel} shape="round" size="large">
            Cancel
          </Button>
          <Flex gap={24}>
            <Button
              disabled={loading}
              form="formSendSurveyLink"
              htmlType="submit"
              shape="round"
              size="large"
              style={{ background: "#428916" }}
              type="primary"
            >
              Send link
            </Button>
            <Button
              disabled={isAdminView()}
              onClick={onContinue}
              shape="round"
              size="large"
              style={{ background: "#428916" }}
              type="primary"
            >
              Continue on this device
            </Button>
          </Flex>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="Send survey link"
      width={609}
    >
      <Form
        form={form}
        id="formSendSurveyLink"
        layout="vertical"
        onFinish={onSend}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              message: "Enter the valid email",
              type: "email",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
      <Button
        disabled={loading}
        icon={<Copy />}
        onClick={handleCopyLink}
        shape="round"
        type="text"
      >
        <u className="label-copy-survey-link">Copy survey link</u>
      </Button>
    </StyledModal>
  );
};

export default ModalSendSurveyLink;
